import React, { useReducer, useRef, useState } from "react";
import "./orderlist.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Add, AlignVerticalCenterOutlined } from "@mui/icons-material";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import { requestType } from "../../types/GlobalType";
import { order_api } from "../../_service/order.Caller";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_MESSAGE } from "../../_service/_const";

import {
  addAlimentOrder,
  addAllOrder,
  addBiogazOrder,
  addOeufOrder,
  addPouleOrder,
  addPouletOrder,
  addPoussinOrder,
} from "../../redux/orderSlice/orderSlice";
import { RootState } from "../../redux/store";
import NewCommande from "./NewCommande";
import { RotatingLines } from "react-loader-spinner";
import ConnexionError from "../../components/errorComp/Connexion";

const names = ["Tous"];
// const names = [
//   "Semaine en cours",
//   "30 Derniers jours",
//   "Tous",
//   "Année spécifique",
// ];
const years = ["2024", "2022", "2021"];
const options = ["commande Client", "commande fournisseur"];

const ITEM_HEIGHT = 30;

const OrderList = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const buttonRef = useRef<HTMLButtonElement>(null);

  let urlRoute = location.pathname.split("/");
  urlRoute.push("orders");

  const [successCreate, setSuccessCreate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [openAddNew, setOpenAddNew] = React.useState<boolean>(false);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const orders = useSelector((state: RootState) => state.order);

  const [triPeriod, setTriePeriod] = React.useState<string>("Semaine en cours");
  // const [yearPeriod, setYearPeriod] = React.useState<string | null>()
  const fetchAll = async () => {
    // console.log("FFF");
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let [
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
        ] = await order_api.clientOrder.getAllByService(token);

        // console.log(dataAliment);

        // let ps = await order_api.clientOrder.getPoussinnnn(token);

        let axios_Fake_Objet = {
          config: null,
          data: { data: [], message: "Situation Commande is Empty" },
          status: 205,
          statusText: "OK",
        };
        if (dataAliment.data.data === undefined)
          dataAliment = Object.create(axios_Fake_Objet);

        if (dataBio.data.data === undefined)
          dataBio = Object.create(axios_Fake_Objet);

        if (dataOeuf.data.data === undefined)
          dataOeuf = Object.create(axios_Fake_Objet);

        if (dataPoussin.data.data === undefined)
          dataPoussin = Object.create(axios_Fake_Objet);

        if (dataPoule.data.data === undefined)
          dataPoule = Object.create(axios_Fake_Objet);

        if (dataPoulet.data.data === undefined)
          dataPoulet = Object.create(axios_Fake_Objet);

        const mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
          ...dataPoule.data.data,
          ...dataPoulet.data.data,
        ];

        dispatch(addAllOrder(mergedData));
        dispatch(addPoussinOrder(dataPoussin.data.data));
        dispatch(addOeufOrder(dataOeuf.data.data));
        dispatch(addAlimentOrder(dataAliment.data.data));
        dispatch(addBiogazOrder(dataBio.data.data));
        dispatch(addPouleOrder(dataPoule.data.data));
        dispatch(addPouletOrder(dataPoulet.data.data));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent-------");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        <span>Chargement...</span>
      </div>
    );
  }

  return (
    <div className="order_list_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff", cursor: "inherit" }}
            // onClick={() => navigate(-1)}
          >
            <AlignVerticalCenterOutlined className="ico" />
          </IconButton>
          <h4>
            Commandes clientes
            {/* {urlRoute[3] === "cmd-pss" && " poussin"}
            {urlRoute[3] === "cmd-bgz" && " biogaz"}
            {urlRoute[3] === "cmd-alm" && " aliment bétail"}
            {urlRoute[3] === "cmd-plt" && " poulet"} */}
          </h4>
        </div>
        <div className="right">
          <div className="btn_commande" onClick={() => setOpenAddNew(true)}>
            <Add className="ico" />
            <span>Nouvelle commande</span>
          </div>
          <span className="ico_sm" onClick={() => setOpenAddNew(true)}>
            <Add className="ico_sm_ico" />
          </span>
          <button ref={buttonRef}>
            <VerticalAlignBottomOutlinedIcon className="ico" />
            <span>Exporter XLSX</span>
          </button>
          <button ref={buttonRef} className="ico_sm">
            <VerticalAlignBottomOutlinedIcon className="ico_sm_ico" />
          </button>
          {/* <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertOutlined sx={{ fontSize: 17 }} />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => {
                    option === "commande Client"
                      ? navigate("/orders/list")
                      : navigate("/orders-suppliers/list");
                    handleClose();
                  }}
                  sx={{ fontSize: 13 }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div> */}
        </div>
      </div>

      <div className="options">
        <div className="left">
          <Link to="/orders/list">
            <div
              className={urlRoute[3] === "orders" ? "option_active" : "option"}
            >
              <span>Tous</span>
              <span className="count">{orders.allOrder.length}</span>
              <div className="underlineAll"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-oef" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-oef" ? "option_active" : "option"}
            >
              <span>Oeufs</span>
              <span className="count">{orders.oeufOrder.length}</span>
              <div className="underline6"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-pss" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-pss" ? "option_active" : "option"}
            >
              <span>Poussins</span>
              <span className="count">{orders.poussinOrder.length}</span>
              <div className="underlinePoussin"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-pls" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-pls" ? "option_active" : "option"}
            >
              <span>Poules</span>
              <span className="count">{orders.pouleOrder.length}</span>
              <div className="underlinePoule"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-plt" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-plt" ? "option_active" : "option"}
            >
              <span>Poulets</span>
              <span className="count">{orders.pouletOrder.length}</span>
              <div className="underlinePoulet"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-bgz" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-bgz" ? "option_active" : "option"}
            >
              <span>Biogaz</span>
              <span className="count">{orders.biogazOrder.length}</span>
              <div className="underline3"></div>
            </div>
          </Link>
          <Link to="/orders/list/cmd-alm" className="link_sm">
            <div
              className={urlRoute[3] === "cmd-alm" ? "option_active" : "option"}
            >
              <span>Aliments</span>
              <span className="count">{orders.alimentOrder.length}</span>
              <div className="underline4"></div>
            </div>
          </Link>
        </div>
        <div className="right">
          {/* {triPeriod === names[names.length - 1].toLocaleLowerCase() && (
            <div>
              <select>
                {years.map((value, index) => (
                  <option key={index} value={value.toLowerCase()}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )} */}

          <div>
            <select
              onChange={(e) => setTriePeriod(e.target.value)}
              defaultValue={names[0]}
            >
              {names.map((value, index) => (
                <option key={index} value={value.toLowerCase()}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {openAddNew && (
        <NewCommande
          setOpenAdd={setOpenAddNew}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessCreate={setSuccessCreate}
        />
      )}
      <div className="container">
        <Outlet context={buttonRef} />
      </div>
    </div>
  );
};

export default OrderList;
