import * as React from "react";
import "./admintable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UserType } from "../../types/UserTypes";
import Delete from "../dialog/Delete";
import SuccessAlert from "../alert/AlerteBar";
import { RotatingLines } from "react-loader-spinner";
import { requestType } from "../../types/GlobalType";
import { ERROR_MESSAGE } from "../../_service/_const";
import NoData from "../errorComp/NoData";
import ConnexionError from "../errorComp/Connexion";
import { capitalizeFirstLetter } from "../../_utils/string_utils";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { HttpsOutlined } from "@mui/icons-material";
import UpdatePswd from "../configuration/admin/dialog/UpdatePswd";
import { capitalize, lowerCase } from "lodash";
import EditAdminDialog from "../configuration/admin/EditAdminDialog";
import admin_api from "../../_service/admin.Caller";

type Props = {
  reducerValueOnInsert: number;
  seachValue: string;
};

export default function AdminTable(props: Props) {
  const [data, setData] = React.useState<UserType[]>([]);
  const [singleUserData, setsingleUserData] = React.useState<UserType | null>();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const [successUpdateUser, setSuccessUpdateUser] =
    React.useState<Boolean>(false);
  const [successUpdatePsw, setSuccessUpdatePsw] =
    React.useState<Boolean>(false);

  const [adminId, setAdminId] = React.useState<string | number | undefined>("");
  const [adminEmail, setAdminEmail] = React.useState<string | undefined>("");
  const [successDelete, setsuccessDelete] = React.useState<Boolean>(false);
  const [openEdit, setopenEdit] = React.useState<Boolean>(false);
  const [openUpdatePsw, setOpenUpdatePsw] = React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);

  const [openDelete, setOpenDelete] = React.useState<Boolean>(false);

  const [reducerValueOnUpdate, forceReloadOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );
  const navigate = useNavigate();

  const handleDelete = (id: string | number | undefined) => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      admin_api
        .delete(id, token)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setLoadingDeleteBtn(false);
            setOpenDelete(false);
            setsuccessDelete(true);
            forceReloadOnUpdate();
          }
        })
        .catch((error: any) => {
          setLoadingDeleteBtn(false);
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const fetchAdminInfo = () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      admin_api
        .getAll(token)
        .then((response) => {
          setState((prev) => ({ ...prev, isLoading: false }));
          setData(response.data.data);
        })
        .catch((error) => {
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAdminInfo();
  }, [props.reducerValueOnInsert, reducerValueOnUpdate]);

  const LoadingComp = (
    <div className="loader">
      <RotatingLines
        visible={true}
        width="40"
        // color="#777"
        strokeWidth="3"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        // wrapperClass=""
        // wrapperStyle={{}}
      />
      <span>Chargement...</span>
    </div>
  );

  const tableData = (
    <TableContainer component={Paper} className="user_table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="rowHeader">
            <TableCell>Id</TableCell>
            <TableCell align="left">Noms</TableCell>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">Télephone</TableCell>
            <TableCell align="right">Service</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            .filter(
              (items) =>
                items.nom
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase()) ||
                items.postnom
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase()) ||
                items.prenom
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase()) ||
                items.telephone
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase()) ||
                items.email
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase()) ||
                items.service_designation
                  ?.toLowerCase()
                  .includes(props.seachValue.toLowerCase())
            )
            .map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {`0${index + 1}`}
                  </TableCell>
                  <TableCell align="left">
                    {`${capitalizeFirstLetter(row.nom)} ${capitalize(
                      row.prenom
                    )}`}
                  </TableCell>
                  <TableCell align="left">{row.email?.toLowerCase()}</TableCell>
                  <TableCell align="left">{row.telephone}</TableCell>
                  <TableCell align="right">{row.service_designation}</TableCell>
                  <TableCell align="right" className="btn">
                    <span
                      className="editer"
                      onClick={() => {
                        setsingleUserData(row);
                        setopenEdit(true);
                      }}
                    >
                      Edit
                    </span>
                    <span
                      className="supprimer"
                      onClick={() => {
                        setAdminId(row?.admin_id);
                        setOpenDelete(true);
                      }}
                    >
                      Supp
                    </span>
                    <Tooltip title="Modifier le mot de passe">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        onClick={() => {
                          setOpenUpdatePsw(true);
                          setAdminId(row.admin_id);
                          setAdminEmail(row.email);
                        }}
                      >
                        <HttpsOutlined sx={{ fontSize: 18 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      {successDelete && (
        <SuccessAlert
          setOpen={setsuccessDelete}
          label={"Supression effectuée avec succès"}
          theme={"success"}
        />
      )}
      {openDelete && (
        <Delete
          setOpen={setOpenDelete}
          id={adminId}
          label={"Voulez-vous vraiment supprimer cet admin?"}
          handleDelete={handleDelete}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}

      {successUpdateUser && (
        <SuccessAlert
          setOpen={setSuccessUpdateUser}
          label={"Modification effectuée avec succès"}
          theme={"success"}
        />
      )}

      {successUpdatePsw && (
        <SuccessAlert
          setOpen={setSuccessUpdatePsw}
          label={"Mot de passe réinitialisé avec success"}
          theme={"success"}
        />
      )}

      {openEdit && (
        <EditAdminDialog
          setOpen={setopenEdit}
          data={singleUserData}
          setSuccessUpdate={setSuccessUpdateUser}
          forceReloadOnUpdate={forceReloadOnUpdate}
        />
      )}

      {openUpdatePsw && (
        <UpdatePswd
          setOpen={setOpenUpdatePsw}
          setOpenSuccess={setSuccessUpdatePsw}
          admin_id={adminId}
          admin_email={adminEmail}
        />
      )}
    </TableContainer>
  );

  const tableEmpty = (
    <TableContainer component={Paper} className="user_table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="right">Noms</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="left">Télephone</TableCell>
            <TableCell align="right">Service</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableCell colSpan={6} align="center">
            <NoData />
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {state.isLoading && LoadingComp}

      {state.errorMessage === ERROR_MESSAGE.networkError && (
        <ConnexionError
          title={"Connectez-vous à internet"}
          subTitle={"Vous êtes hors connexion"}
        />
      )}

      {state.errorMessage === ERROR_MESSAGE.unknownError && (
        <ConnexionError
          title={"Une erreur inconnue s'est produite"}
          subTitle={"Réessayez plus tard"}
        />
      )}

      {!state.isLoading && data && data.length > 0 && tableData}

      {state.errorMessage !== ERROR_MESSAGE.networkError &&
        !state.isLoading &&
        state.errorMessage !== ERROR_MESSAGE.unknownError &&
        data &&
        data.length === 0 &&
        tableEmpty}
    </>
  );
}
