import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_NOTIFICATION = axios.create({
  baseURL: URL_BACKEND_APP + "/notification",
});

const API_REQUETE = axios.create({
  baseURL: URL_BACKEND_APP + "/requete",
});

const config = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
  },
});
const API_RAPPORT = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport",
});

// --------------------------------------------------
// RAPPORT API_CALLER UPDATE STATUS_RAPPORT
// --------------------------------------------------

const API_CALLER_ALIMENT = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_aliment",
});
const API_CALLER_BIOGAZ = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_biogaz",
});
const API_CALLER_POUSSIN = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_poussin",
});
const API_CALLER_OEUF = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_oeuf",
});
const API_CALLER_POULE = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_poule",
});
const API_CALLER_POULET = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_poulet",
});
const API_CALLER_INCUBATION = axios.create({
  baseURL: URL_BACKEND_APP + "/rapport_incubation",
});

export const notification_api = {
  create: (data, token) => {
    return API_NOTIFICATION.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return API_NOTIFICATION.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return API_NOTIFICATION.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return API_NOTIFICATION.put(`/update.php?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return API_NOTIFICATION.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  uploadImage: (id, token, fd) => {
    return API_NOTIFICATION.post(`/uploadImage.php?id=${id}`, fd, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export const rapport_api = {
  create: (data, token) => {
    return API_RAPPORT.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return API_RAPPORT.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getAll: (token) => {
    let url1 = URL_BACKEND_APP + "/rapport_aliment/read.php";
    let url2 = URL_BACKEND_APP + "/rapport_biogaz/read.php";
    let url3 = URL_BACKEND_APP + "/rapport_oeuf/read.php";
    let url4 = URL_BACKEND_APP + "/rapport_poussin/read.php";
    let url5 = URL_BACKEND_APP + "/rapport_poule/read.php";
    let url6 = URL_BACKEND_APP + "/rapport_poulet/read.php";
    let url7 = URL_BACKEND_APP + "/rapport_incubation/read.php";

    let req1 = axios.get(url1, config(token));
    let req2 = axios.get(url2, config(token));
    let req3 = axios.get(url3, config(token));
    let req4 = axios.get(url4, config(token));
    let req5 = axios.get(url5, config(token));
    let req6 = axios.get(url6, config(token));
    let req7 = axios.get(url7, config(token));

    return axios.all([req1, req2, req3, req4, req5, req6, req7]);
  },

  // update: (id, data, token) => {
  //   return API_RAPPORT.put(`/update.php?id=${id}`, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },

  // --------------------------------------------------------------
  //                       CHANGE_STATUS
  // --------------------------------------------------------------
  updateStatusAliment: (id, data, token) => {
    return API_CALLER_ALIMENT.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusBiogaz: (id, data, token) => {
    return API_CALLER_BIOGAZ.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusOeuf: (id, data, token) => {
    return API_CALLER_OEUF.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoussin: (id, data, token) => {
    return API_CALLER_POUSSIN.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoule: (id, data, token) => {
    return API_CALLER_POULE.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoulet: (id, data, token) => {
    return API_CALLER_POULET.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusIncubation: (id, data, token) => {
    return API_CALLER_INCUBATION.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return API_RAPPORT.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

// ------------------------------------------------------------------

export const requete_api = {
  create: (data, token) => {
    return API_REQUETE.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return API_REQUETE.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return API_REQUETE.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return API_REQUETE.put(`/update.php?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return API_REQUETE.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};
