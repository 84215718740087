import React from "react";
import "./cardfour.scss";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { OrderType } from "../../../types/GlobalType";
import _ from "lodash";
import { Skeleton } from "@mui/material";

type PropsType = {
  isLoading: boolean;
};

const CardFour = (props: PropsType) => {
  const { allOrder } = useSelector((state: RootState) => state.order);
  let orderValidate: OrderType[] | any[] = [];
  let orderCanceled: OrderType[] | any[] = [];
  if (allOrder.length > 0) {
    orderValidate = _.filter(allOrder, { statusCmd_id: 4 }); //order reglee
    orderCanceled = _.filter(allOrder, { statusCmd_id: 5 }); //order annulee
  }

  return (
    <>
      {props.isLoading ? (
        <div className="card_four">
          <div className="card_info">
            <Skeleton height={80} width={80} variant="rounded" />
          </div>
          <Skeleton width={50} height={50} variant="rounded" />
        </div>
      ) : (
        <div className="card_four">
          <Link to="/orders/list">
            <div className="card_info">
              <span>Commande</span>
              <h1>
                {allOrder.length - orderValidate.length - orderCanceled.length}
              </h1>
              <span>En cours</span>
            </div>
          </Link>
          <Link to="/orders/list">
            <div className="card_icon">
              <div className="wraper">
                <HistoryOutlinedIcon className="ico" />
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default CardFour;
