import { PortableWifiOff } from "@mui/icons-material";
import "./connexion.scss";

type Props = {
  title: string;
  subTitle: string;
};
const ConnexionError = ({ title, subTitle }: Props) => {
  const refleshPage = () => {
    window.location.reload();
  };
  return (
    <div className="connexion_error">
      <PortableWifiOff className="ico" />
      <h4>{title}</h4>
      <p>{subTitle}</p>
      <button onClick={refleshPage}> Réesayer</button>
    </div>
  );
};

export default ConnexionError;
