import React from "react";
import "./rapportDialog.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { RapportType, requestType } from "../../../types/GlobalType";
import moment from "moment";
import {
  CalendarMonth,
  CancelPresentation,
  CorporateFareOutlined,
  EqualizerOutlined,
  InsightsOutlined,
  LibraryBooks,
  NumbersOutlined,
  Person2Outlined,
  PhoneAndroidOutlined,
  Reply,
  SummarizeOutlined,
} from "@mui/icons-material";
import DoneOutlined from "@mui/icons-material/DoneOutlined";
import {
  ERROR_MESSAGE,
  ETAT_RAPPORT,
  ETAT_RAPPORT_ID,
  STATUS_RAPPORT_ID,
} from "../../../_service/_const";
import { useNavigate } from "react-router-dom";
import { rapport_api } from "../../../_service/notification.Call";
import MyLoading_v3 from "../../loader/Loader_v3";
import ConnexionBar from "../../errorComp/connexionBar";
import { ProtectedSectionForAdmin_Log } from "../../../_service/WithPermission";

type Props = {
  data: RapportType | undefined;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReloadOnUpdate: () => void;
  setOpenSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
};
const RapportDialog = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // console.log(props.data);

  const navigate = useNavigate();

  const [rapportData, setRapportData] = React.useState<RapportType>({
    id: props.data?.id,
    telephone: props.data?.telephone,
    date: props.data?.date,
    quantite: props.data?.quantite,
    nom: `${props.data?.nom} ${props.data?.postnom} ${props.data?.prenom}`,
    services_id: props.data?.services_id,
    commentaire: props.data?.commentaire,
    nature_designation: props.data?.nature_designation,
  });

  //assign etat Rapport thanks to his id
  let etatRapport: string = "";
  switch (props.data?.etat_rapportID) {
    case ETAT_RAPPORT_ID.BON:
      etatRapport = ETAT_RAPPORT.BON;
      break;
    case ETAT_RAPPORT_ID.TRES_BON:
      etatRapport = ETAT_RAPPORT.TRES_BON;
      break;
    case ETAT_RAPPORT_ID.MAUVAIS:
      etatRapport = ETAT_RAPPORT.MAUVAIS;
      break;
    case ETAT_RAPPORT_ID.TRES_MAUVAIS:
      etatRapport = ETAT_RAPPORT.TRES_MAUVAIS;
      break;
    case ETAT_RAPPORT_ID.MORT:
      etatRapport = ETAT_RAPPORT.MORT;
      break;
    default:
      break;
  }

  // let categotyType: string | number = 0;

  const handleSubmit = async (status_rapport_id: number) => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      let idRapport: number | string | any = "";

      let data = { status_rapport_id };

      try {
        //assign categoryType of Rapport thanks to the id of rapport

        if (!!props.data?.rapport_poulet_id) {
          idRapport = props.data?.rapport_poulet_id;
          await rapport_api.updateStatusPoulet(idRapport, data, token);
        } else if (!!props.data?.rapport_aliment_id) {
          idRapport = props.data?.rapport_aliment_id;
          await rapport_api.updateStatusAliment(idRapport, data, token);
        } else if (!!props.data?.rapport_biogaz_id) {
          idRapport = props.data?.rapport_biogaz_id;
          await rapport_api.updateStatusBiogaz(idRapport, data, token);
        } else if (!!props.data?.rapport_poussin_id) {
          idRapport = props.data?.rapport_poussin_id;
          await rapport_api.updateStatusPoussin(idRapport, data, token);
        } else if (!!props.data?.rapport_poule_id) {
          idRapport = props.data?.rapport_poule_id;
          await rapport_api.updateStatusPoule(idRapport, data, token);
        } else if (!!props.data?.rapport_oeuf_id) {
          idRapport = props.data?.rapport_oeuf_id;
          await rapport_api.updateStatusOeuf(idRapport, data, token);
        } else if (!!props.data?.rapport_Inc_id) {
          idRapport = props.data?.rapport_Inc_id;
          await rapport_api.updateStatusIncubation(idRapport, data, token);
        }

        setState({
          isLoading: false,
          errorMessage: null,
        });
        props.setOpenSuccess(true);
        props.forceReloadOnUpdate();
      } catch (error: any) {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <>
      {state.isLoading && <MyLoading_v3 isLoading={true} />}
      {!!state.errorMessage && <ConnexionBar />}
      <div className="rapport_detail">
        <div className="dialog">
          <div className="header">
            <span>
              <LibraryBooks className="ico" />
              {"Rapport journalier"}
            </span>
            <IconButton onClick={() => props.setOpen(false)}>
              <Close className="ico" />
            </IconButton>
          </div>

          <div className="body">
            <div className="item">
              <div className="label">
                <CalendarMonth className="ico" />
                <label>Date du rapport</label>
              </div>
              <input
                disabled
                type="text"
                value={moment(rapportData.date).format("llll")}
              />
            </div>
            <div className="item">
              <div className="label">
                <Person2Outlined className="ico" />
                <label>Nom de l'agent</label>
              </div>
              <input disabled type="text" value={rapportData.nom} />
            </div>
            <div className="item">
              <div className="label">
                <PhoneAndroidOutlined className="ico" />
                <label>Contact</label>
              </div>
              <input disabled type="text" value={rapportData.telephone} />
            </div>
            <div className="item">
              <div className="label">
                <CorporateFareOutlined className="ico" />
                <label>Service</label>
              </div>
              <input
                disabled
                type="text"
                value={rapportData.nature_designation}
              />
            </div>

            <div className="item">
              <div className="label">
                <NumbersOutlined className="ico" />
                <label>Quantité</label>
              </div>
              <input disabled type="text" value={rapportData.quantite} />
            </div>
            <div className="item">
              <div className="label">
                <InsightsOutlined className="ico" />
                <label>Etat du rapport</label>
              </div>
              <input disabled type="text" value={etatRapport} />
            </div>
            <div className="item">
              <div className="label">
                <EqualizerOutlined className="ico" />
                <label>Status</label>
              </div>
              <input
                disabled
                type="text"
                value={props.data?.status_rapport_designation}
              />
            </div>
            <div className="item comment">
              <label>Commentaire</label>
              <textarea
                disabled
                rows={4}
                value={
                  rapportData.commentaire
                    ? rapportData.commentaire
                    : "Aucun commentaire"
                }
              />
            </div>
          </div>

          <ProtectedSectionForAdmin_Log>
            <>
              {props.data?.status_rapport_id === STATUS_RAPPORT_ID.ANNULE ||
              props.data?.status_rapport_id === STATUS_RAPPORT_ID.VALIDE ? (
                <div className="btn">
                  <button onClick={() => props.setOpen(false)}>
                    <Reply />
                    <span>Retourner</span>
                  </button>
                </div>
              ) : (
                <div className="btn">
                  <button onClick={() => props.setOpen(false)}>
                    <Reply />
                    <span>Retourner</span>
                  </button>
                  <button
                    onClick={() => handleSubmit(STATUS_RAPPORT_ID.A_REVISER)}
                  >
                    <SummarizeOutlined />
                    <span>A Réviser</span>
                  </button>
                  <button
                    onClick={() => handleSubmit(STATUS_RAPPORT_ID.ANNULE)}
                  >
                    <CancelPresentation />
                    <span>Annuler rapport</span>
                  </button>
                  <button
                    onClick={() => handleSubmit(STATUS_RAPPORT_ID.VALIDE)}
                  >
                    <DoneOutlined />
                    <span>Valider rapport</span>
                  </button>
                </div>
              )}
            </>
          </ProtectedSectionForAdmin_Log>
        </div>
      </div>
    </>
  );
};

export default RapportDialog;
