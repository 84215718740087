import React from "react";
import "./messages.scss";
import IconButton from "@mui/material/IconButton";
import SearchBar from "../../components/search/Search";
import { Tooltip } from "@mui/material";
// import Chat from "../../components/messages/Chat";
import SuccessAlert from "../../components/alert/AlerteBar";
import MessageDetail from "../../components/messages/MessageDetail";
import { NotificationType, requestType } from "../../types/GlobalType";
import { notification_api } from "../../_service/notification.Call";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import { MessageOutlined, MoreVert } from "@mui/icons-material";
import MessageUpdate from "../../components/messages/MessageUpdate";
import MessageNew from "../../components/messages/MessageNew";
import { RotatingLines } from "react-loader-spinner";
import ConnexionError from "../../components/errorComp/Connexion";
import NoData from "../../components/errorComp/NoData";
import moment from "moment";
import _, { capitalize } from "lodash";

// const options = ["Ouvrir", "Supprimer"];

// const ITEM_HEIGHT = 30;

const Messages = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [openNew, setOpenNew] = React.useState<boolean>(false);
  const [openUpdate, setOpenUpdate] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);

  const [data, setData] = React.useState<NotificationType[]>([]);
  const [singleData, setsingleData] = React.useState<NotificationType | any>();

  const [successAddImage, setSuccessAddImage] = React.useState<Boolean>(false);
  const [successSend, setSuccessSend] = React.useState<Boolean>(false);
  const [successUpdate, setSuccessUpdate] = React.useState<Boolean>(false);
  const [successDelete, setSuccessDelete] = React.useState<Boolean>(false);

  const [reducerReloadValue, forceReload] = React.useReducer((x) => x + 1, 0);

  const [searchValue, setSearchValue] = React.useState<string>("");

  let sortedData: NotificationType[] = [];
  if (data.length) {
    sortedData = _.orderBy(data, ["date"], ["desc"]);
  }

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleDisplay = (d: NotificationType) => {
    setsingleData(d);
    setOpenDetail(true);
  };

  const fetchAll = () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
        succussMessage: "",
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      notification_api
        .getAll(token)
        .then((response) => {
          setState((prev) => ({
            ...prev,
            isLoading: false,
            succussMessage: "success",
          }));
          setData(response.data.data);
        })
        .catch((error) => {
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, [reducerReloadValue]);

  const LoadingComp = (
    <div className="loader">
      <RotatingLines
        visible={true}
        width="40"
        // color="#777"
        strokeWidth="3"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        // wrapperClass=""
        // wrapperStyle={{}}
      />
      <span>Chargement...</span>
    </div>
  );

  const content = (
    <div className="messages">
      <div className="header">
        <div className="left">
          <h4 className="title">Notification client</h4>
          <div className="btn" onClick={() => setOpenNew(true)}>
            <MessageOutlined className="icon" />
            <span>Nouveau</span>
          </div>
        </div>
        <div className="right">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
      </div>

      <div className="container">
        {sortedData
          .filter((items) =>
            items.description?.toLowerCase().includes(searchValue.toLowerCase())
          )
          .map((valueData) => (
            <div className="box_msg" key={valueData.id}>
              <div className="left" onClick={() => handleDisplay(valueData)}>
                {/* <input type="checkbox" name="" id="" /> */}
                <div className="message">
                  <div className="sender small">
                    <div className="avatar">
                      {valueData.titre && (
                        <span>
                          {Array.from(capitalize(valueData?.titre[0]))}
                        </span>
                      )}
                    </div>
                    <span>{capitalize(valueData?.titre?.slice(0, 30))} </span>
                  </div>
                  <div className="sender">
                    <div className="avatar">
                      {valueData.titre && (
                        <span>
                          {Array.from(capitalize(valueData?.titre[0]))}
                        </span>
                      )}
                    </div>
                    {`${capitalize(valueData?.titre?.slice(0, 30))}`}
                  </div>
                  <div className="text">
                    {/* <span>{`${valueData?.description?.slice(0, 120)}...`}</span> */}
                    {`${valueData?.description?.slice(0, 120)}...`}
                    <span className="date">
                      {moment(valueData.date).format("lll")}
                    </span>
                    {!!valueData.image_news && (
                      <span className="attach">
                        {!!valueData.image_news && "FILE"}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="icons">
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      setsingleData(valueData);
                      setOpenUpdate(true);
                    }}
                  >
                    <Tooltip title={"Modifier"}>
                      <MoreVert className="icon" />
                    </Tooltip>
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
      </div>

      {openNew && (
        <MessageNew
          setOpenNew={setOpenNew}
          setSuccess={setSuccessSend}
          forceReload={forceReload}
        />
      )}

      {openUpdate && (
        <MessageUpdate
          setOpenUpdate={setOpenUpdate}
          data={singleData}
          setSuccess={setSuccessUpdate}
          forceReload={forceReload}
        />
      )}

      {openDetail && (
        <MessageDetail
          setOpenDetail={setOpenDetail}
          data={singleData}
          setSuccessAddImage={setSuccessAddImage}
          setSuccessDelete={setSuccessDelete}
          forceReload={forceReload}
        />
      )}

      {/* {openChat && <Chat setOpen={setOpenChat} data={""} />} */}

      {successAddImage && (
        <SuccessAlert
          setOpen={setSuccessAddImage}
          label={"Image ajoutée avec success"}
          theme={"success"}
        />
      )}
      {successSend && (
        <SuccessAlert
          setOpen={setSuccessSend}
          label={"Message diffusion envoyé avec success"}
          theme={"success"}
        />
      )}
      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Notification modifiée avec succès"}
          theme={"success"}
        />
      )}
      {successDelete && (
        <SuccessAlert
          setOpen={setSuccessDelete}
          label={"Notification supprimée avec succès"}
          theme={"success"}
        />
      )}
    </div>
  );
  return (
    <>
      {state.isLoading && LoadingComp}
      {!state.isLoading && content}
      {state.errorMessage === ERROR_MESSAGE.networkError && (
        <ConnexionError
          title={"Connectez-vous à internet"}
          subTitle={"Vous êtes hors connexion"}
        />
      )}
      {!state.isLoading &&
        state.succussMessage === "success" &&
        data.length <= 0 && <NoData />}
    </>
  );
};

export default Messages;
