import React, { useState } from "react";
import "./singlesupplier.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { IconButton, Skeleton } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  Add,
  ArrowBackIosNew,
  BlurOn,
  MapOutlined,
  ModeEditOutline,
  PlaylistRemoveOutlined,
} from "@mui/icons-material";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/fournisseur.png";
import { CATEGORY_NATURE, ERROR_MESSAGE } from "../../_service/_const";
import { entree_stock_api } from "../../_service/entreeStock.Caller";
import { entreeType, requestType } from "../../types/GlobalType";
import { useDispatch } from "react-redux";
import {
  addAlimentEntree,
  addAllEntree,
  addBiogazEntree,
  addOeufEntree,
  addPoussinEntree,
} from "../../redux/entreeStockSlice.ts/entreeStockSlice";
import moment from "moment";
import EditSupplierDialog from "./EditSupplierDialog";
import SuccessAlert from "../../components/alert/AlerteBar";
import COUNTRY_CODES_EN from "../../_service/_countryCode";
import { capitalize } from "lodash";
import { ProtectedSectionForAdmin_Log } from "../../_service/WithPermission";

const SingleSupplier = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [orderData, setSupplierOrderData] = useState<entreeType[] | []>([]);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const [openEdit, setopenEdit] = React.useState<Boolean>(false);
  const [successUpdateSupplier, setSuccessUpdateSupplier] =
    React.useState<Boolean>(false);

  const [reducerValueOnUpdate, forceReloadOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );

  const {
    nom_entreprise,
    titre,
    nom,
    postnom,
    pays,
    ville,
    telephone,
    quartier,
    commune,
    avenue,
    cat_produit_id,
    fournisseur_id,
  } = location.state;

  // console.log(location.state);

  let country;
  if (pays && COUNTRY_CODES_EN[pays]) {
    country = COUNTRY_CODES_EN[pays].primary;
  } else {
    country = pays;
  }

  const Skeletons = new Array(5).fill(
    <li>
      <div>
        <p>
          <Skeleton height={10} width={200} variant="rectangular" />
        </p>
        <time>
          {" "}
          <Skeleton height={20} width={100} />
        </time>
      </div>
    </li>
  );
  const fetchAllEntree = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const [dataAliment, dataBio, dataOeuf, dataPoussin] =
          await entree_stock_api.entree.getAllByService(token);

        dispatch(addAlimentEntree(dataAliment.data.data));
        dispatch(addBiogazEntree(dataBio.data.data));
        dispatch(addOeufEntree(dataOeuf.data.data));
        dispatch(addPoussinEntree(dataPoussin.data.data));

        let mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
        ];

        dispatch(addAllEntree(mergedData));
        setSupplierOrderData(mergedData);

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  let OrdersSupplier: entreeType[] = [];
  if (orderData.length > 0) {
    orderData.forEach((element) => {
      if (element.fournisseur_id === fournisseur_id)
        OrdersSupplier.push(element);
    });
  }

  React.useEffect(() => {
    fetchAllEntree();
  }, []);
  return (
    <>
      <div className="single_supplier_page">
        <div className="header">
          <div className="left">
            <IconButton
              sx={{ backgroundColor: "#fff" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNew className="ico" />
            </IconButton>
            <h3>Information du fournisseur</h3>
          </div>
          <div className="right">
            <ProtectedSectionForAdmin_Log>
              <Link
                to="/orders-suppliers"
                state={{ fournisseur_id, nom, postnom, telephone }}
              >
                <div className="btn_commande">
                  <Add className="ico" />
                  <span>Nouvelle Entrée</span>
                </div>
              </Link>
              <div className="btn_update" onClick={() => setopenEdit(true)}>
                <ModeEditOutline className="ico" />
                <span>Modifier</span>
              </div>
            </ProtectedSectionForAdmin_Log>
          </div>
        </div>

        <div className="container">
          <div className="left">
            <div className="top">
              <div className="avatar">
                <img src={Logo} alt="Founisseur logo" />
                {/* <DriveFileRenameOutlineOutlined className="ico" /> */}
                <label className="username" htmlFor="supplierProfil">
                  {nom_entreprise?.toUpperCase()} / {titre?.toUpperCase()}
                </label>
                {/* <input
                type="file"
                name="logo"
                id="supplierProfil"
                className="input_file"
              /> */}
              </div>
              <div className="box">
                <div className="box_icon">
                  <PersonOutlineIcon className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Hote</span>
                  <div className="second_info">
                    <span>{nom}</span>
                    {/* <span>{postnom} </span> */}
                  </div>
                </div>
              </div>

              <div className="body">
                <div className="box">
                  <div className="box_icon">
                    <MapOutlined className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Pays</span>
                    <div className="second_info">
                      <span>
                        {country}, Ville de {ville}
                      </span>
                      <span></span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <LocationOnOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Adresse</span>
                    <div className="second_info">
                      <span> Q. {quartier}</span>
                      <span>
                        C. {commune}, Av. {avenue}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <PhoneIphoneOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Téléphone</span>
                    <div className="second_info">
                      <span>{telephone}</span>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="box_icon">
                    <SupervisorAccountOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Service</span>
                    <div className="second_info">
                      <span>
                        Fournisseur &nbsp;
                        {cat_produit_id === 1 && CATEGORY_NATURE.aliment}
                        {cat_produit_id === 2 && CATEGORY_NATURE.biogaz}
                        {cat_produit_id === 3 && CATEGORY_NATURE.oeuf}
                        {cat_produit_id === 4 && CATEGORY_NATURE.poussin}
                        {cat_produit_id === 5 && CATEGORY_NATURE.poule}
                        {cat_produit_id === 6 && CATEGORY_NATURE.poulet}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <h4> Historique des fournitures </h4>

            <ul>
              {state.isLoading && Skeletons}
              {!state.isLoading && orderData.length > 0 && (
                <>
                  {orderData
                    .sort((a: any, b: any) => {
                      let dataA: any = new Date(a.date).getTime();
                      let dateB: any = new Date(b.date).getTime();
                      return dataA - dateB;
                    })
                    .reverse()
                    .map((item: entreeType, index: number) => {
                      return (
                        <li key={index}>
                          <div>
                            <p>
                              <BlurOn className="ico" />
                              {item.nature_designation} - {item.categorie}
                            </p>
                            <time>
                              {capitalize(
                                moment(item.created_at).format(
                                  "dddd Do MMMM, YYYY"
                                )
                              )}
                            </time>

                            <span className="status">Qté: {item.quantite}</span>
                          </div>
                        </li>
                        // <li key={index}>
                        //   <div>
                        //     <p>
                        //       Achat {item.nature_designation} - {item.categorie}
                        //     </p>
                        //     <time>
                        //       {moment(item.date).format("dddd Do MMMM, YYYY")}
                        //     </time>
                        //   </div>
                        // </li>
                      );
                    })}
                </>
              )}
              {!state.isLoading && orderData.length === 0 && (
                <>
                  <li>
                    <div>
                      <h4>Aucun Achat</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icons">
                      <PlaylistRemoveOutlined
                        sx={{ fontSize: 50, color: "#777" }}
                      />
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>

      {openEdit && (
        <EditSupplierDialog
          data={location.state}
          setOpen={setopenEdit}
          setSuccessUpdate={setSuccessUpdateSupplier}
          forceReloadOnUpdate={forceReloadOnUpdate}
        />
      )}

      {successUpdateSupplier && (
        <SuccessAlert
          setOpen={setSuccessUpdateSupplier}
          label={"Modification effectuée avec succès"}
          theme={"success"}
        />
      )}
    </>
  );
};

export default SingleSupplier;
