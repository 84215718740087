import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RapportType} from "../../types/GlobalType";
import { STATUS_RAPPORT_ID } from "../../_service/_const";

interface RapportInitialState{
  allRapport: RapportType[],
  rapportEtablieCount: number
}

const initialState:RapportInitialState = {
  allRapport: [],
  rapportEtablieCount:0
};
const rapportSlice = createSlice({
  name: "rapport",
  initialState,
  reducers: {
    addallRapport: (state, action:PayloadAction<RapportType []>) => {
      state.allRapport=Object.assign([], action.payload)
    },
    addRapportEtablieCount: (state, action:PayloadAction<RapportType []>) => {

      let rapportEstablishedNum: number = 0;
      if (action.payload.length > 0) {
        for (let item of action.payload) {
          if (item.status_rapport_id === STATUS_RAPPORT_ID.ETABLI) {
            rapportEstablishedNum++;
          }
        }
      }
      
      state.rapportEtablieCount=rapportEstablishedNum
    },
  },
});

export const {
  addallRapport,
  addRapportEtablieCount
} = rapportSlice.actions;

export default rapportSlice.reducer;
