import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { entreeType } from "../../types/GlobalType";

interface OrderInitialState{
  allEntree:entreeType[]
  alimentEntree: entreeType[],
  biogazEntree: entreeType[],
  poussinEntree: entreeType[],
  oeufEntree: entreeType[],
}

const initialState:OrderInitialState = {
  allEntree: [],
  poussinEntree: [],
  alimentEntree: [],
  biogazEntree: [],
  oeufEntree: [],

};
const entreeStockSlice = createSlice({
  name: "entreeStock",
  initialState,
  reducers: {

    addAllEntree: (state, action:PayloadAction<entreeType [] | any >) => {
      state.allEntree=Object.assign([], action.payload)
    },
    addPoussinEntree: (state, action:PayloadAction<entreeType [] | any >) => {
      state.poussinEntree=Object.assign([], action.payload)
    },
    addBiogazEntree: (state, action:PayloadAction<entreeType [] | any>) => {
      state.biogazEntree=Object.assign([], action.payload)
    },
    addAlimentEntree: (state, action:PayloadAction<entreeType [] | any>) => {
      state.alimentEntree=Object.assign([], action.payload)
    },
    addOeufEntree: (state, action:PayloadAction<entreeType [] | any>) => {
      state.oeufEntree=Object.assign([], action.payload)
    },
  },
});

export const {
  addAllEntree,
  addPoussinEntree,
  addOeufEntree,
  addAlimentEntree,
  addBiogazEntree

} = entreeStockSlice.actions;

export default entreeStockSlice.reducer;
