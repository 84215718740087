import React, { useRef } from "react";
import "./entree.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import {
  // Add,
  ArrowBackIosNew,
  // VerticalAlignBottom,
  VerticalAlignBottomOutlined,
} from "@mui/icons-material";
import moment from "moment";
// import SyntesisEntree from "../../components/entrees/entrees/SyntesisEntree";
// import SyntesisOthers from "../../components/entrees/autres/SyntesisOthers";
import { requestType } from "../../types/GlobalType";
import { useDispatch } from "react-redux";
import { entree_stock_api } from "../../_service/entreeStock.Caller";
import {
  addAlimentEntree,
  addAllEntree,
  addBiogazEntree,
  addOeufEntree,
  addPoussinEntree,
} from "../../redux/entreeStockSlice.ts/entreeStockSlice";
import { ERROR_MESSAGE } from "../../_service/_const";

const names = ["Tous"];
// const names = [
//   "Semaine en cours",
//   "30 Derniers jours",
//   "Jour spécifique",
//   "Année spécifique",
// ];
// const years = ["2024", "2023", "2022", "2021"];

const Entree = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("entree");

  const now = moment().format("YYYY-MM-DD");
  const currentYear = moment().format("YYYY");
  const [triPeriod, setTriePeriod] = React.useState<string>("Semaine en cours");
  const [yearPeriod, setYearPeriod] = React.useState<string>(currentYear);
  const [dayPeriod, setDayPeriod] = React.useState<string>(now);

  const buttonRef = useRef<HTMLButtonElement>(null);

  // const data = {
  //   triPeriod,
  //   yearPeriod,
  //   dayPeriod,
  // };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const [dataAliment, dataBio, dataOeuf, dataPoussin] =
          await entree_stock_api.entree.getAllByService(token);

        dispatch(addAlimentEntree(dataAliment.data.data));
        dispatch(addBiogazEntree(dataBio.data.data));
        dispatch(addOeufEntree(dataOeuf.data.data));
        dispatch(addPoussinEntree(dataPoussin.data.data));

        let mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
        ];

        dispatch(addAllEntree(mergedData));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <div className="entree_page">
        <div className="header">
          <div className="left">
            <IconButton
              sx={{ backgroundColor: "#fff" }}
              onClick={() => navigate("/store")}
            >
              <ArrowBackIosNew className="ico" />
            </IconButton>
            <h4>
              {urlRoute[2] === "entree" && " Entrées stock - Achats"}
              {urlRoute[2] === "others" && " Autres entrées"}
            </h4>
            {/* <h3>Entrée stock -
              {urlRoute[2] === "entree" && " Achat"}
              {urlRoute[2] === "others" && " autres"}
            </h3> */}
          </div>

          <div className="right">
            {/* <div className="btn_commande">
              <Add className="ico" />
              <span>Nouvelle entrée</span>
            </div> */}
            <button ref={buttonRef}>
              <VerticalAlignBottomOutlined className="ico" />
              <span>Exporter XLSX</span>
            </button>
            <button className="ico_sm" ref={buttonRef}>
              <VerticalAlignBottomOutlined />
            </button>
          </div>
        </div>
        <div className="container">
          <div className="left">
            <div className="top">
              <div className="btns">
                <Link to="/entree">
                  <button
                    className={urlRoute[2] === "entree" ? "btn_active" : ""}
                  >
                    Entrées Fournisseurs
                  </button>
                </Link>
                <Link to="/entree/others">
                  <button
                    className={urlRoute[2] === "others" ? "btn_active" : ""}
                  >
                    Autres entrées
                  </button>
                </Link>
              </div>
              <div className="tri">
                {/* {triPeriod === names[names.length - 1].toLocaleLowerCase() && (
                  <div>
                    <select
                      onChange={(e) => setYearPeriod(e.target.value)}
                      defaultValue={names[0]}
                    >
                      {years.map((value, index) => (
                        <option key={index} value={value.toLowerCase()}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {triPeriod === names[names.length - 2].toLocaleLowerCase() && (
                  <div>
                    <input
                      type="date"
                      onChange={(e) => setDayPeriod(e.target.value)}
                      value={dayPeriod}
                    />
                  </div>
                )} */}

                <div>
                  <select
                    onChange={(e) => setTriePeriod(e.target.value)}
                    defaultValue={names[0]}
                  >
                    {names.map((value, index) => (
                      <option key={index} value={value.toLowerCase()}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="content">
              <Outlet context={[buttonRef, state.isLoading]} />
            </div>
          </div>
          {/* <div className="right">
            {urlRoute[2] === "entree" && <SyntesisEntree data={data} />}
            {urlRoute[2] === "others" && <SyntesisOthers data={data} />}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Entree;
