import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_CALLER = axios.create({
  // baseURL: URL_BACKEND_APP+"/motif_sorties",
  baseURL: URL_BACKEND_APP + "/motif",
});

const motif_sortie_api = {
  create: (data, token) => {
    return API_CALLER.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },

  getAll: (token) => {
    return API_CALLER.get("/read.php", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },

  update: (id, data, token) => {
    return API_CALLER.put("/update.php?id=" + id, data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },

  delete: (id, token) => {
    return API_CALLER.delete(`/delete.php?id=${id}`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
};

export default motif_sortie_api;
