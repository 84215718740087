import React from "react";
import "./clientmoral.scss";
import SearchBar from "../../search/Search";
import List from "./List";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import _, { lowerCase } from "lodash";
// import { PersonOffOutlined, PersonOutline } from "@mui/icons-material";

const ClientMoral = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");

  const { allClient } = useSelector((state: RootState) => state.client);
  let moralClients: any = [];

  if (allClient.length > 0) {
    moralClients = _.filter(allClient, { is_legal_person: 1 });
  }

  let homme: number = 0;
  let femme: number = 0;

  if (moralClients) {
    for (let item of moralClients) {
      if (lowerCase(item.sexe) === "m") {
        homme++;
      }
    }
    femme = moralClients.length - homme;
  }

  return (
    <div className="table_client_moral">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        {/* <div className="btns">
          <div className="status validate">
            <PersonOutline className="ico" />
            <span>Hommes {homme}</span>
          </div>
          <div className="status pending">
            <PersonOffOutlined className="ico" />
            <span>Femmes {femme}</span>
          </div>
        </div> */}
      </div>
      <List searchValue={searchValue} />
    </div>
  );
};

export default ClientMoral;
