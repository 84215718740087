import React, { useEffect } from "react";
import "./salelist.scss";
import { Box } from "@mui/material";
import SearchBar from "../../search/Search";
import SingleSale from "../dialog/SingleSale";
import { sortieType } from "../../../types/GlobalType";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { MOTIF_SORTIE } from "../../../_service/_const";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import NoData from "../../errorComp/NoData";
import MyLoadingv3 from "../../loader/Loader_v3";
import exportFromJSON from "export-from-json";

const SaleList = () => {
  const context: any[] = useOutletContext();
  const isLoading: boolean = context[0];

  const [searchValue, setSearchValue] = React.useState<string>("");
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [data, setData] = React.useState<sortieType | any>();

  const {
    alimentSortie,
    biogazSortie,
    oeufSortie,
    poussinSortie,
    pouleSortie,
    pouletSortie,
  } = useSelector((state: RootState) => state.sortieStock);

  let allSortie: sortieType[] = [];

  if (alimentSortie.length > 0) {
    alimentSortie.forEach((value) => allSortie.push(value));
  }
  if (biogazSortie.length > 0) {
    biogazSortie.forEach((value) => allSortie.push(value));
  }
  if (oeufSortie.length > 0) {
    oeufSortie.forEach((value) => allSortie.push(value));
  }
  if (poussinSortie.length > 0) {
    poussinSortie.forEach((value) => allSortie.push(value));
  }
  if (pouleSortie.length > 0) {
    pouleSortie.forEach((value) => allSortie.push(value));
  }
  if (pouletSortie.length > 0) {
    pouletSortie.forEach((value) => allSortie.push(value));
  }

  let sortieVente: sortieType[] | any[] = [];
  let sortieAutre: sortieType[] | any[] = [];

  if (allSortie.length > 0) {
    for (let item of allSortie) {
      item.motif_designation === MOTIF_SORTIE.sortie_incubation ||
      item.motif_designation === MOTIF_SORTIE.sortie_mauvais_etat ||
      item.motif_designation === MOTIF_SORTIE.sortie_tres_mauvais_etat ||
      item.motif_designation === MOTIF_SORTIE.sortie_mort
        ? sortieAutre.push(item)
        : sortieVente.push(item);
    }
  }

  const contextBtnRef: any = useOutletContext();
  const contextBtnRef_ok = contextBtnRef[0];
  const isLoading_ok: boolean = contextBtnRef[1];

  const handleClick = () => {
    const fileName = "NAGRITECH_VENTE_TABLE";
    const exportType = "xls";
    const fields = [
      "id",
      "nom",
      "postnom",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
      "quantite",
      "prixunitaire",
    ];
    exportFromJSON({ data: sortieVente, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef_ok.current;
    // console.log(button);
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  if (sortieVente.length <= 0) {
    return (
      <>
        <div className="list_autre_sortie">
          <div className="search">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              label={"Recherche produit"}
            />
          </div>
          <NoData />
        </div>
      </>
    );
  }

  return (
    <>
      <MyLoadingv3 isLoading={isLoading_ok} />
      <div className="list_vente_page">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche client"}
          />
        </div>
        <div className="header">
          {/* <h4>Liste des ventes</h4> */}
          {/* <div className='produits'>
              <span >Tous</span>
              <span >Oeufs</span>
              <span>Poussins</span>
              <span>Poulets</span>
              <span>Aliment</span>
              <span>Biogaz</span>
            </div> */}
        </div>

        <Box sx={{ width: "100%" }} className="table_list">
          <ul className="responsive-table">
            <li className="table-header">
              <div className="col col-4">Id</div>
              <div className="col col-2">Produit</div>
              <div className="col col-2">Date</div>
              <div className="col col-4">Qnté</div>
              <div className="col col-4">Montant</div>
              <div className="col col-2">Agent </div>
            </li>

            {sortieVente
              .filter(
                (item: sortieType) =>
                  item?.nature_designation
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  item?.nom
                    ?.toLowerCase()
                    ?.includes(searchValue.toLowerCase()) ||
                  item?.date?.toLowerCase()?.includes(searchValue.toLowerCase())
              )
              .map((value: sortieType, index: number) => (
                <li
                  className="table-row"
                  key={index}
                  onClick={() => {
                    setData(value);
                    setOpenDetail(true);
                  }}
                >
                  <div className="col col-4" data-label="Id">
                    {`0${index + 1}`}
                  </div>

                  <div className="col col-2" data-label="Produit">
                    {value.nature_designation}
                  </div>
                  <div className="col col-2" data-label="Date">
                    {moment(value.date).format("L")}
                  </div>
                  <div className="col col-4" data-label="Quantité">
                    {value.quantite}
                  </div>
                  <div className="col col-4" data-label="Montant">
                    {value.prixunitaire * value.quantite} {value.devise}
                  </div>
                  <div className="col col-2" data-label="Client">
                    {`${value.nom?.toUpperCase()} ${value.postnom?.toUpperCase()}`}
                  </div>
                </li>
              ))}
          </ul>
        </Box>

        {openDetail && <SingleSale setOpen={setOpenDetail} data={data} />}
      </div>
    </>
  );
};

export default SaleList;
