/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Box, Typography } from "@mui/material";

// Material Dashboard 2 React components
type PercentageType = {
  label: string;
  color: string;
};

type IconType = () => JSX.Element;

interface Props {
  color: string;
  title: string;
  count: number | string;
  percentage: PercentageType;
  icon: JSX.Element;
}

function CardSore({ color, title, count, percentage, icon }: Props) {
  return (
    <Card>
      <Box display="flex" justifyContent="space-between" p={1} px={2}>
        <Box
          sx={{
            backgroundColor: color,
            width: "3rem",
            height: "3rem",
            borderRadius: 2,
            // mt: 1,
          }}
          bgcolor={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {icon}
        </Box>
        <Box textAlign="right" lineHeight={1.25}>
          <span style={{ fontWeight: "400", fontSize: 12 }}>{title}</span>
          <Typography
            variant="h5"
            fontWeight="bold"
            fontSize={20}
            sx={{ color: "#343434" }}
          >
            {count}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{}} />
      <Box display="flex" alignItems="center" p={0}>
        {/* <Typography component="p" variant="button" color="text" display="flex">
          &nbsp;{percentage.label}
        </Typography> */}
      </Box>
    </Card>
  );
}

// Setting default values for the props of CardSore
CardSore.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the CardSore
CardSore.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "#15a01f3a",
    "#b684113a",
    "#a038153a",
    "#6e88703c",
    "#416a0035",
    "#a599133a",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
      "#15a01f3a",
      "#b684113a",
      "#a038153a",
      "#6e88703c",
      "#416a0035",
      "#a599133a",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default CardSore;
