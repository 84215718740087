import * as React from "react";
import "./orderbiogaz.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ClientsData } from "../../../data/data";
import { Box } from "@mui/material";
import { dymmy } from "../../../types/UserTypes";
import { Link } from "react-router-dom";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
export default function List() {
  const [data, setData] = React.useState<dymmy[]>([]);
  // const [singleData, setSingleData] = React.useState<RapportType>();
  // const [openDetail, setOPenDetail] = React.useState<Boolean>(false)
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    setData(ClientsData);
  }, []);

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ minWidth: 650 }}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="right">Nature de commande</TableCell>
              <TableCell align="right">Client</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Quantité</TableCell>
              <TableCell align="right">Montant payé</TableCell>
              <TableCell align="right">Prix total</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.nom}</TableCell>
                    <TableCell align="right">{row.telephone}</TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    <TableCell align="right">{row.quantite}</TableCell>
                    <TableCell align="right">{row.quantite}</TableCell>
                    <TableCell align="right">{row.prix}</TableCell>
                    <TableCell align="right">
                      <div className="btn">
                        <span className="status validate">{row.status}</span>
                        <Link to={`/orders-suppliers/${2}`}>
                          <ArrowForwardIosOutlined sx={{ fontSize: 13 }} />
                        </Link>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        labelRowsPerPage={"Clients par page"}
        rowsPerPageOptions={[8, 15]}
        component="div"
        count={ClientsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* {openDetail && <RapportDialog setOpen={setOPenDetail} data={singleData}/>} */}
    </Box>
  );
}
