import React, { useState } from "react";
import "./editCategoryDialog.scss";
import { ProductType } from "../../../types/CategoryType";
import { natureType, requestType } from "../../../types/GlobalType";
import { isNumber } from "../../../_utils/number_utils";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_NATURE,
  CATEGOTY_ID,
  ERROR_MESSAGE,
} from "../../../_service/_const";
import nature_api from "../../../_service/nature.Caller";
import { Close, Inventory } from "@mui/icons-material";
import { capitalize } from "lodash";
import MyLoadingv3 from "../../loader/Loader_v3";

type Props = {
  data: ProductType | undefined;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnUpdate: () => void;
};
const EditCategoryDialog = (props: Props) => {
  const [natureData, setNattureData] = useState<natureType>({
    designation: props.data?.designation,
    type: props.data?.type,
    prixunitaire: props.data?.prixunitaire,
    sexe: props.data?.sexe,
    mode: props.data?.mode,
    devise: props.data?.devise,
    cat_produit_id: props?.data?.cat_produit_id,
  });

  // console.log(props.data);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // const [error, setError] = useState(false);
  // const [errorNumPrice, setErrorNumPrice] = useState(false);

  const handleChange = (e: any) => {
    setNattureData({ ...natureData, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });
    if (
      natureData.designation?.trim() === "" ||
      natureData.type?.trim() === "" ||
      natureData.prixunitaire?.toString().trim() === "" ||
      natureData.categorie?.trim() === ""
    ) {
      return setState({
        isLoading: false,
        errorMessage: "Veuillez compléter les champs vides",
      });
    }

    if (!isNumber(natureData.prixunitaire)) {
      return setState({
        isLoading: false,
        errorMessage: "Le prix n'est pas valide",
      });
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const { designation, type, prixunitaire, sexe, categorie, devise } =
        natureData;

      setState({ isLoading: true, errorMessage: null });

      nature_api
        .update(
          props.data?.id,
          {
            designation,
            type,
            prixunitaire,
            sexe,
            categorie,
            devise,
            cat_produit_id: natureData.cat_produit_id,
          },
          token
        )
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
              succussMessage: "Modification effectuée",
            });
            props.setSuccessUpdate(true);
            props.setOpen(false);
            props.forceUpdateOnUpdate();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <div className="edit_category">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <div className="title_left">
              <Inventory className="ico" />
              <span>Modifier Catégorie</span>
              {state.errorMessage && (
                <span className="errorMsg">{state.errorMessage}</span>
              )}
            </div>
            <Close className="ico" onClick={() => props.setOpen(false)} />
            {/* {errorNumPrice && <span className="errorMsg">Prix non valide</span>} */}
          </div>
        </div>

        <div className="body">
          <div className="item">
            <label>Catégorie</label>
            <select
              name="cat_produit_id"
              onChange={handleChange}
              defaultValue={natureData.cat_produit_id}
            >
              <option value={natureData.cat_produit_id}>
                {(natureData.cat_produit_id === CATEGOTY_ID.ALIMENT &&
                  capitalize(CATEGORY_NATURE.aliment)) ||
                  (natureData.cat_produit_id === CATEGOTY_ID.BIOGAZ &&
                    capitalize(CATEGORY_NATURE.biogaz)) ||
                  (natureData.cat_produit_id === CATEGOTY_ID.OEUF &&
                    capitalize(CATEGORY_NATURE.oeuf)) ||
                  (natureData.cat_produit_id === CATEGOTY_ID.POUSSIN &&
                    capitalize(CATEGORY_NATURE.poussin)) ||
                  (natureData.cat_produit_id === CATEGOTY_ID.POULE &&
                    capitalize(CATEGORY_NATURE.poule)) ||
                  (natureData.cat_produit_id === CATEGOTY_ID.POULET &&
                    capitalize(CATEGORY_NATURE.poulet))}
              </option>
              {/* <option value={CATEGOTY_ID.BIOGAZ}>
                {capitalize(CATEGORY_NATURE.biogaz)}
              </option>
              <option value={CATEGOTY_ID.OEUF}>
                {capitalize(CATEGORY_NATURE.oeuf)}
              </option>
              <option value={CATEGOTY_ID.POUSSIN}>
                {capitalize(CATEGORY_NATURE.poussin)}
              </option>
              <option value={CATEGOTY_ID.POULE}>
                {capitalize(CATEGORY_NATURE.poule)}
              </option>
              <option value={CATEGOTY_ID.POULET}>
                {capitalize(CATEGORY_NATURE.poulet)}
              </option> */}
            </select>
          </div>

          <div className="item">
            <label>Type</label>
            <input
              // disabled
              placeholder="Type"
              type="text"
              value={natureData.type}
              name="type"
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Désignation</label>
            <input
              // disabled
              placeholder="Désignation"
              type="text"
              value={natureData.designation}
              name="designation"
              onChange={handleChange}
            />
          </div>

          <div className="item">
            <label>Mode de vente</label>
            <input
              // disabled
              placeholder="Type"
              type="text"
              value={natureData.mode}
              name="mode"
              onChange={handleChange}
            />
          </div>
          <div className="item">
            <label>Devise</label>
            <select
              defaultValue={natureData.devise}
              name="devise"
              onChange={handleChange}
            >
              <option value="USD">Dollar(USD)</option>
              {/* <option value="CDF">Franc congolais</option> */}
            </select>
          </div>

          <div className="item">
            <label>Prix untaire</label>
            <input
              placeholder="Prix untaire"
              type="text"
              value={natureData.prixunitaire}
              name="prixunitaire"
              onChange={handleChange}
            />
          </div>

          {natureData.cat_produit_id === CATEGOTY_ID.POULE && (
            <div className="item">
              <label>Sexe</label>
              <select
                defaultValue={natureData.sexe}
                name="sexe"
                onChange={handleChange}
              >
                <option value="M">Male</option>
                <option value="F">Femelle</option>
              </select>
            </div>
          )}
        </div>

        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Annuler</button>
          <button onClick={handleSubmit}>Valider</button>
        </div>

        <MyLoadingv3 isLoading={state.isLoading} />
      </div>
    </div>
  );
};

export default EditCategoryDialog;
