import React, { useReducer, useState } from "react";
import "./category.scss";
import Search from "@mui/icons-material/Search";
import CategoryTable from "../../table/CategoryTable";
import { CircularProgress } from "@mui/material";
import {
  AddBoxRounded,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { isValidNumber } from "../../../_utils/number_utils";
import nature_api from "../../../_service/nature.Caller";
import SuccessAlert from "../../alert/AlerteBar";
import { requestType } from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_NATURE,
  CATEGOTY_ID,
  ERROR_MESSAGE,
} from "../../../_service/_const";
import { capitalize } from "lodash";
import { ProtectedSectionForAdmin_Log } from "../../../_service/WithPermission";

type NatureType = {
  cat_produit_id?: string;
  designation: string;
  mode: string;
  type: string;
  sexe?: string;
  prixunitaire: number | string;
  // categorie: string;
  devise: string;
};

const Category = () => {
  const [category, setCategory] = useState<number | string>("");
  const [natureData, setNatureData] = useState<NatureType>({
    mode: "",
    designation: "",
    type: "",
    prixunitaire: "",
    sexe: "M",
    // categorie: "",
    devise: "USD",
  });
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [successCreate, setSuccessCreate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const handleChange = (e: any) => {
    setNatureData({ ...natureData, [e.target.name]: e.target.value });
  };
  const handleChangeCat = (e: any) => {
    setCategory(e.target.value);
  };

  const handleSubmit = () => {
    const { designation, type, prixunitaire, mode } = natureData;
    if (
      mode.trim() == "" ||
      category == "" ||
      designation?.trim() == "" ||
      type?.trim() == "" ||
      prixunitaire?.toString().trim() == ""
    ) {
      return setState({
        isLoading: false,
        errorMessage: "Veuillez compléter les champs vides",
      });
    }

    if (!isValidNumber(prixunitaire)) {
      return setState({
        isLoading: false,
        errorMessage: "Le prix n'est pas valide",
      });
    }
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      setState({ isLoading: true, errorMessage: null });
      nature_api
        .create({ ...natureData, cat_produit_id: category }, token)
        .then((response) => {
          // console.log(response);
          setState((prev) => ({ ...prev, isLoading: false }));
          setNatureData({
            designation: "",
            mode: "",
            type: "",
            prixunitaire: "",
            sexe: "MALE",
            // categorie: "",
            devise: "CDF",
          });
          setSuccessCreate(true);
          forceUpdateOnInsert();
        })
        .catch((error) => {
          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            } else {
              return setState({
                isLoading: false,
                errorMessage: error.response.data.message,
              });
            }
          }
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <div className="category">
      <div className="title">
        <span>Gestion - catégorisation des produits</span>
        <div className="header">
          <ProtectedSectionForAdmin_Log>
            {!openForm && (
              <AddBoxRounded
                className="ico"
                onClick={() => setOpenForm(true)}
              />
            )}
          </ProtectedSectionForAdmin_Log>

          {openForm && (
            <RemoveCircleOutlineOutlined
              className="ico"
              onClick={() => setOpenForm(false)}
            />
          )}
          <div className="Searchbar">
            <Search className="icon" />
            <input
              placeholder="Recherche"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="list">
          {/* <span>Liste catégories</span> */}
          <CategoryTable
            reducerValueOnInsert={reducerValueOnInsert}
            searchValue={searchValue}
          />
        </div>

        {openForm && (
          <div className="add">
            <span>Nouvelle catégorie</span>
            {state.errorMessage && (
              <p className="alert">{capitalize(state.errorMessage)}</p>
            )}

            <div className="form">
              <select name="categorie" onChange={handleChangeCat}>
                <option value="" selected>
                  Selectionner la catégorie
                </option>
                <option value={CATEGOTY_ID.ALIMENT}>
                  {capitalize(CATEGORY_NATURE.aliment)}
                </option>
                <option value={CATEGOTY_ID.BIOGAZ}>
                  {capitalize(CATEGORY_NATURE.biogaz)}
                </option>
                <option value={CATEGOTY_ID.OEUF}>
                  {capitalize(CATEGORY_NATURE.oeuf)}
                </option>
                <option value={CATEGOTY_ID.POUSSIN}>
                  {capitalize(CATEGORY_NATURE.poussin)}
                </option>
                <option value={CATEGOTY_ID.POULE}>
                  {capitalize(CATEGORY_NATURE.poule)}
                </option>
                <option value={CATEGOTY_ID.POULET}>
                  {capitalize(CATEGORY_NATURE.poulet)}
                </option>
              </select>
              <input
                placeholder="Type"
                type="text"
                name="type"
                value={natureData.type}
                onChange={handleChange}
              />
              <input
                placeholder="Désignation"
                type="text"
                name="designation"
                value={natureData.designation}
                onChange={handleChange}
              />
              <select name="mode" onChange={handleChange}>
                <option value="" selected>
                  selectionner mode de vente
                </option>
                <option value="piece">Vente par pièce</option>
                <option value="kg">Vente par kg</option>
                <option value="sac">Vente par sac</option>
              </select>
              {/* <input
                placeholder="Catégorie"
                type="text"
                name="categorie"
                value={natureData.categorie}
                onChange={handleChange}
              /> */}
              <input
                placeholder="Prix unitaire"
                type="text"
                name="prixunitaire"
                value={natureData.prixunitaire}
                onChange={handleChange}
              />
              <select
                name="devise"
                defaultValue={natureData.devise}
                onChange={handleChange}
              >
                {/* <option value="CDF" selected>
                  Franc congolais (CDF)
                </option> */}
                <option value="USD" selected>
                  Dollar (USD)
                </option>
              </select>
              {category == CATEGOTY_ID.POULE && (
                <select
                  name="sexe"
                  defaultValue={natureData.sexe}
                  onChange={handleChange}
                >
                  <option value="M" selected>
                    Male
                  </option>
                  <option value="F">Femelle</option>
                </select>
              )}
              {!state.isLoading && (
                <button onClick={handleSubmit}>Enregistrer</button>
              )}{" "}
              {state.isLoading && (
                <button>
                  <CircularProgress size={20} className="circular" />
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      {successCreate && (
        <SuccessAlert
          setOpen={setSuccessCreate}
          label={"Opération effectuée avec success"}
          theme={"success"}
        />
      )}
    </div>
  );
};

export default Category;
