import { Skeleton } from "@mui/material";
import React from "react";

const SkeletonRight = () => {
  const skeletons = new Array(7).fill(
    <li>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            width: "80%",
          }}
        >
          <Skeleton />
          <Skeleton />
        </div>
        <Skeleton height={20} width={20} />
      </div>
    </li>
  );
  return <>{skeletons}</>;
};

export default SkeletonRight;
