import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_CALLER_CLIENT = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_clients",
});

const API_CALLER_ALIMENT = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_aliment",
});
const API_CALLER_BIOGAZ = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_biogaz",
});
const API_CALLER_POUSSIN = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_poussin",
});
const API_CALLER_OEUF = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_oeuf",
});
const API_CALLER_POULE = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_poule",
});
const API_CALLER_POULET = axios.create({
  baseURL: URL_BACKEND_APP + "/commande_poulet",
});

const config = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
  },
});

const clientOrder = {
  getOne: (id, token) => {
    return API_CALLER_CLIENT.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  getAllByService: (token) => {
    let url1 = URL_BACKEND_APP + "/commande_aliment/read.php";
    let url2 = URL_BACKEND_APP + "/commande_biogaz/read.php";
    let url3 = URL_BACKEND_APP + "/commande_oeuf/read.php";
    let url4 = URL_BACKEND_APP + "/commande_poussin/read.php";
    let url5 = URL_BACKEND_APP + "/commande_poule/read.php";
    let url6 = URL_BACKEND_APP + "/commande_poulet/read.php";

    let req1 = axios.get(url1, config(token));
    let req2 = axios.get(url2, config(token));
    let req3 = axios.get(url3, config(token));
    let req4 = axios.get(url4, config(token));
    let req5 = axios.get(url5, config(token));
    let req6 = axios.get(url6, config(token));

    // return axios.all([req1, req2]);
    return axios.all([req1, req2, req3, req4, req5, req6]);
  },

  getAll: (token) => {
    return API_CALLER_CLIENT.get(`/read.php`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getPoussinnnn: (token) => {
    return axios.get(
      `https://api-nagritech-v1.50webs.org/commande_poussin/read.php`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  },
  getAllByClient: (id, token) => {
    return axios.get(
      URL_BACKEND_APP + "/commande_client/readByIdClient.php?id=" + id,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
  },

  update: (id, data, token) => {
    return API_CALLER_CLIENT.put(`/update.php?id=${id}`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id) => {
    return API_CALLER_CLIENT.delete(`/delete.php?id=${id}`);
  },
};

const categoryOrder = {
  // --------------------------------------------------------------
  //                       CREATE
  // --------------------------------------------------------------
  createAliment: (data, token) => {
    return API_CALLER_ALIMENT.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createBiogaz: (data, token) => {
    return API_CALLER_BIOGAZ.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createOeuf: (data, token) => {
    return API_CALLER_OEUF.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createPoussin: (data, token) => {
    return API_CALLER_POUSSIN.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createPoule: (data, token) => {
    return API_CALLER_POULE.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  createPoulet: (data, token) => {
    return API_CALLER_POULET.post("/create.php", data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  // --------------------------------------------------------------
  //                         UPDATE ALL
  // --------------------------------------------------------------
  updateAliment: (id, data, token) => {
    return API_CALLER_ALIMENT.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateBiogaz: (id, data, token) => {
    return API_CALLER_BIOGAZ.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateOeuf: (id, data, token) => {
    return API_CALLER_OEUF.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updatePoussin: (id, data, token) => {
    return API_CALLER_POUSSIN.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updatePoule: (id, data, token) => {
    return API_CALLER_POULE.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updatePoulet: (id, data, token) => {
    return API_CALLER_POULET.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  // --------------------------------------------------------------
  //                         UPDATE MONTANT
  // --------------------------------------------------------------
  updateMontantAliment: (id, data, token) => {
    return API_CALLER_ALIMENT.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateMontantBiogaz: (id, data, token) => {
    return API_CALLER_BIOGAZ.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateMontantOeuf: (id, data, token) => {
    return API_CALLER_OEUF.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateMontantPoussin: (id, data, token) => {
    return API_CALLER_POUSSIN.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateMontantPoule: (id, data, token) => {
    return API_CALLER_POULE.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateMontantPoulet: (id, data, token) => {
    return API_CALLER_POULET.put("/updateMontant.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  // --------------------------------------------------------------
  //                       CHANGE_STATUS
  // --------------------------------------------------------------
  updateStatusAliment: (id, data, token) => {
    return API_CALLER_ALIMENT.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusBiogaz: (id, data, token) => {
    return API_CALLER_BIOGAZ.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusOeuf: (id, data, token) => {
    return API_CALLER_OEUF.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoussin: (id, data, token) => {
    return API_CALLER_POUSSIN.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoule: (id, data, token) => {
    return API_CALLER_POULE.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  updateStatusPoulet: (id, data, token) => {
    return API_CALLER_POULET.put("/changeStatus.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export const order_api = { clientOrder, categoryOrder };
