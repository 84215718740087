import { Dialog, DialogContent } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

type Props = {
  isLoading: boolean;
};
const MyLoading_v2 = ({ isLoading }: Props) => {
  return (
    <>
      {isLoading && (
        <Dialog open={isLoading}>
          <DialogContent
            sx={{
              width: "200px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <div className="loader">
              {/* <Circles
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> */}
              <RotatingLines
                visible={true}
                width="40"
                // color="#777"
                strokeWidth="3"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                // wrapperClass=""
                // wrapperStyle={{}}
              />
              <span style={{ marginTop: 10 }}>Chargement...</span>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default MyLoading_v2;
