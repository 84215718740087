import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { OrderType } from "../../../types/GlobalType";
import { ORDER_STATUS_ } from "../../../_service/_const";
import { useEffect } from "react";
import exportFromJSON from "export-from-json";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const orders = useSelector((state: RootState) => state.order.allOrder);

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  const handleClick = () => {
    const fileName = "NAGRITECH_Commande_All";
    const exportType = "xls";
    const fields = [
      "nom_entreprise",
      "titre",
      "nom",
      "postnom",
      "prenom",
      "nature_designation",
      "quantite",
      "prixunitaire",
      "prixtotal",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
    ];
    exportFromJSON({ data: orders, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef?.current;
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  // ----------------------------------------------

  if (orders.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Num</div>
            <div className="col col-3">Nature</div>
            <div className="col col-4">Quantite</div>
            <div className="col col-4">date</div>
            <div className="col col-4">Fournisseur</div>
            <div className="col col-4">Status</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Num</div>
          <div className="col col-3">Nature</div>
          <div className="col col-4">Quantite</div>
          <div className="col col-2">Fournisseur</div>
          <div className="col col-4">date</div>
          <div className="col col-4">Status</div>
        </li>

        {orders
          .filter((item: OrderType) =>
            item?.nom?.toLowerCase().includes(props.searchValue.toLowerCase())
          )
          .map((order: OrderType, index: number) => (
            <Link to={`/orders/${order.nom}`} state={order}>
              <li className="table-row" key={index}>
                <div className="col col-3" data-label="Id">
                  {`0${index + 1}`}
                </div>
                <div className="col col-3" data-label="Nature">
                  {order.nature_designation}
                </div>
                <div className="col col-4" data-label="Quantité">
                  {order.quantite}
                </div>
                <div className="col col-2 Ese" data-label="Client">
                  {!order?.is_legal_person ? (
                    `${order.nom} ${order.prenom}`
                  ) : (
                    <>
                      {order.nom_entreprise}
                      <span className="container-Ese">
                        <span className="Ese">e</span>
                      </span>
                    </>
                  )}
                </div>
                <div className="col col-4" data-label="Date commande">
                  {order.created_at?.split(" ")[0]}
                </div>
                <div className="col col-4" data-label="Status">
                  {order.status_cmd_designation}
                  {/* {order.statusCmd_id === ORDER_STATUS_.one.value &&
                    ORDER_STATUS_.one.designatoin}
                  {order.statusCmd_id === ORDER_STATUS_.two.value &&
                    ORDER_STATUS_.two.designatoin}
           
                  {order.statusCmd_id === ORDER_STATUS_.four.value &&
                    ORDER_STATUS_.four.designatoin}
                  {order.statusCmd_id === ORDER_STATUS_.five.value &&
                    ORDER_STATUS_.five.designatoin} */}
                </div>
              </li>
            </Link>
          ))}
      </ul>
    </Box>
  );
}
