import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import _, { capitalize } from "lodash";
import { ClientType } from "../../../types/UserTypes";
import NoData from "../../errorComp/NoData";
import { useEffect } from "react";
import exportFromJSON from "export-from-json";
interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const clients = useSelector((state: RootState) => state.client.allClient);

  let orderedData = _.orderBy(clients, ["created_at"], ["desc"]);

  let moralClients: ClientType[] | any;

  if (clients.length > 0) {
    moralClients = _.filter(orderedData, { is_legal_person: 1 });
  }

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  const handleClick = () => {
    const fileName = "CLIENTS_TABLE";
    const exportType = "xls";
    const fields = [
      "nom_entreprise",
      "titre",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
    ];
    exportFromJSON({ data: moralClients, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef.current;
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);

  if (moralClients.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-2">Entreprise </div>
            <div className="col col-3">Contact</div>
            <div className="col col-4">Fonction-Hôte</div>
            {/* <div className="col col-4">Genre</div> */}
            <div className="col col-4">Ville</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-2">Entreprise </div>
          <div className="col col-3">Contact</div>
          <div className="col col-4">Fonction-Hôte</div>
          {/* <div className="col col-4">Genre</div> */}
          <div className="col col-4">Ville</div>
        </li>

        {moralClients
          .filter(
            (item: ClientType) =>
              item?.nom_entreprise
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item.nom
                .toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item.postnom
                .toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.prenom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.ville
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.telephone
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((client: ClientType | any, index: number) => {
            return (
              <Link to={`/clients/${client.nom}`} state={client} key={index}>
                <li className="table-row">
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-2" data-label="Entreprise">
                    {client.nom_entreprise.toUpperCase()}
                  </div>
                  <div className="col col-3" data-label="Contact">
                    {client.telephone}
                  </div>
                  <div className="col col-4" data-label="Hôte">
                    {client.titre.toUpperCase()}
                  </div>
                  {/* <div className="col col-4" data-label="Genre">
                    {client.sexe}
                  </div> */}
                  <div className="col col-4" data-label="Ville">
                    {capitalize(client.ville)}
                  </div>
                </li>
              </Link>
            );
          })}
      </ul>
    </Box>
  );
}
