import React, { useCallback, useEffect, useState } from "react";
import "./order.scss";
import {
  AccountCircleOutlined,
  AddShoppingCartOutlined,
  ArrowBackIosNew,
  CancelOutlined,
  MoreVertOutlined,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  TextField,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import RecentOrder from "../../components/order/RecentOrder";
import OrderCardDialog from "../../components/order/OrderCardDialog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import client_api from "../../_service/client.Caller";
import { natureType, requestType } from "../../types/GlobalType";
import {
  ERROR_MESSAGE,
  CATEGOTY_ID,
  SUCCESS_MESSAGE,
  CATEGORY_NATURE,
} from "../../_service/_const";
import { ClientType } from "../../types/UserTypes";
import _, { capitalize, toUpper } from "lodash";
import nature_api from "../../_service/nature.Caller";
import ConnexionBar from "../../components/errorComp/connexionBar";
import { order_api } from "../../_service/order.Caller";
import SuccessDialog from "../../components/dialog/SuccessDialog";

// const options = ["commande Client", "commande fournisseur"];

// const ITEM_HEIGHT = 30;

const Order = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);

  // console.log(location.state);

  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const [allClients, setAllClients] = React.useState<ClientType[]>([]);
  const [allNatures, setAllNatures] = React.useState<natureType[]>([]);
  const [loading_allNature, setLoading_allNature] = useState<boolean>(true);
  const [msgError, setmsgError] = useState<string>("");

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // const [orderNature, setorderNature] = React.useState<string>("poussin");
  const [orderNatureItem, setorderNatureItem] = React.useState<string | any>();
  const [orderNatureItemParse, setorderNatureItemParse] = React.useState<
    natureType | any
  >();

  let orderedData = _.orderBy(allNatures, ["designation"], ["asc"]);

  const [openOrder, setopenOrder] = React.useState<boolean>(false);
  const [quantity, setQuantity] = React.useState<number>(1);
  const [montant, setMontant] = React.useState<number>(0);
  const [natureId, setNatureId] = React.useState<number>(0); //example of natures("Poulet vif, bombon 6kg, etc")
  const [categorieId, setCategorieId] = React.useState<number>(0); //we have 6categories(oeuf, poussin, poule, poulet, biogaz,aliment)

  let newClient: any = {};
  let oldClient: any = {};
  let currentClient: any;

  const handleChangeOrderNatureItem = (e: any) => {
    setorderNatureItem(e.target.value);
    setorderNatureItemParse(JSON.parse(e.target.value));
    setNatureId(JSON.parse(e.target.value).id);
    setCategorieId(JSON.parse(e.target.value).cat_produit_id);
    // if (!!orderNatureItem) setorderNatureItemParse(JSON.parse(orderNatureItem));
  };

  const noAction = () => null;

  if (allClients.length > 0) {
    newClient = _.filter(allClients, {
      telephone: location.state.newClientPhoneNum,
    });
    currentClient = newClient[0];
  } else if (location?.state?.oldClientData) {
    oldClient = [location.state.oldClientData];
    currentClient = oldClient[0];
  }
  const handleSubmit = async () => {
    let pt: number = 0;
    let pu: number = 0;

    if (orderNatureItemParse !== undefined) {
      pu = +orderNatureItemParse?.prixunitaire;
      pt = +orderNatureItemParse?.prixunitaire * +quantity;
    }

    if (+montant > pt) {
      setmsgError("Le montant est supérieur au montant à payer");
      setTimeout(() => {
        setmsgError("");
      }, 3000);

      return 0;
    }

    if (localStorage.getItem("user")) {
      setLoadingBtn(true);
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        natures_idNature: natureId,
        clients_idClient: currentClient.client_id,
        quantite: quantity,
        montant: montant,
        // montant: orderNatureItemParse?.prixunitaire * quantity,
      };

      // console.log(data);

      try {
        switch (categorieId) {
          case CATEGOTY_ID.ALIMENT:
            await order_api.categoryOrder.createAliment(data, token);
            break;
          case CATEGOTY_ID.BIOGAZ:
            await order_api.categoryOrder.createBiogaz(data, token);
            break;
          case CATEGOTY_ID.OEUF:
            await order_api.categoryOrder.createOeuf(data, token);
            break;
          case CATEGOTY_ID.POUSSIN:
            await order_api.categoryOrder.createPoussin(data, token);
            break;
          case CATEGOTY_ID.POULE:
            await order_api.categoryOrder.createPoule(data, token);
            break;
          case CATEGOTY_ID.POULET:
            await order_api.categoryOrder.createPoulet(data, token);
            break;
          default:
            break;
        }

        setLoadingBtn(false);

        setState({
          isLoading: false,
          errorMessage: null,
        });

        setOpenSuccess(true);
        setTimeout(() => {
          setOpenSuccess(false);
          navigate(-1);
        }, 3000);
      } catch (error: any) {
        setLoadingBtn(false);
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await client_api.getAll(token);
        setAllClients(response.data.data);

        setState({
          errorMessage: "",
          isLoading: false,
          succussMessage: SUCCESS_MESSAGE.success,
        });
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  const fetchAllNature = useCallback(async () => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await nature_api.getAll(token);
        setAllNatures(response.data.data);

        setLoading_allNature(false);
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setLoading_allNature(false);
        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }, []);

  // console.log(currentClient);

  useEffect(() => {
    if (location?.state?.newClientPhoneNum) {
      fetchAll();
    }
    fetchAllNature();
  }, []);

  return (
    <div className="order_page">
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNew className="ico" />
          </IconButton>
          <span className="title">Nouvelle Commande cliente</span>
          <Link to="/orders/list">
            <span className="cmd_btn">Liste</span>
          </Link>
        </div>
        <div className="right">
          {/* <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertOutlined className="icon" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => {
                    option === "commande Client"
                      ? navigate("/orders")
                      : navigate("/orders-suppliers");
                    handleClose();
                  }}
                  sx={{ fontSize: 13 }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div> */}
        </div>
      </div>
      <div className="container">
        <div className="left">
          <div className="commande">
            <h4> Identification du client</h4>
            {/* <div className="search">
              <SearchUserOrder />
            </div> */}
            <div className="form_user">
              {loading_allNature ? (
                <div className="user_input">
                  <div className="item">
                    <Skeleton width={200} height={40} />
                  </div>
                  <div className="item">
                    <Skeleton width={200} height={40} />
                  </div>
                </div>
              ) : (
                <div className="user_input">
                  <div className="item">
                    <div className="label">
                      <AccountCircleOutlined className="ico" />
                      <div className="info">
                        <span className="headerrr">Identité</span>
                        <span>
                          {!currentClient?.is_legal_person
                            ? toUpper(
                                `${currentClient?.nom} ${currentClient?.prenom}`
                              )
                            : toUpper(currentClient.nom_entreprise)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">
                      <PhoneAndroidOutlined className="ico" />
                      <div className="info">
                        <span className="headerrr">Contact</span>
                        <span className="value">
                          {" "}
                          {currentClient?.telephone}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {loading_allNature ? (
              <div className="header_com">
                <Skeleton width={200} height={40} />
                <Skeleton width={100} height={40} />
              </div>
            ) : (
              <div className="header_com">
                <h4> Description de la commande</h4>
                <select
                  defaultValue={undefined}
                  value={orderNatureItem}
                  onChange={handleChangeOrderNatureItem}
                >
                  <option value="0">Selectionner Produit</option>
                  {orderedData.map((item: natureType | any, index: number) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.designation}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="form_commande">
              {loading_allNature ? (
                // <div className="poussin">
                <Skeleton height={250} style={{ marginTop: "-40px" }} />
              ) : (
                // </div>
                <div className="poussin">
                  <div className="type_category">
                    <div className="item">
                      <span className="label">Catégorie:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.ALIMENT &&
                              capitalize(CATEGORY_NATURE.aliment)) ||
                            (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.BIOGAZ &&
                              capitalize(CATEGORY_NATURE.biogaz)) ||
                            (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.OEUF &&
                              capitalize(CATEGORY_NATURE.oeuf)) ||
                            (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.POUSSIN &&
                              capitalize(CATEGORY_NATURE.poussin)) ||
                            (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.POULE &&
                              capitalize(CATEGORY_NATURE.poule)) ||
                            (orderNatureItemParse?.cat_produit_id ===
                              CATEGOTY_ID.POULET &&
                              capitalize(CATEGORY_NATURE.poulet))
                          : "------"}
                      </span>
                    </div>
                    <div className="item">
                      <span className="label">Type:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? orderNatureItemParse?.type
                          : "------"}
                      </span>
                    </div>
                  </div>
                  <div className="type_category">
                    <div className="item">
                      <span className="label">Unité de Vente:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? orderNatureItemParse?.mode
                          : "------"}
                      </span>
                    </div>
                    <div className="item">
                      <span className="label">Prix Unitaire:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? `${orderNatureItemParse?.prixunitaire} ${orderNatureItemParse.devise}`
                          : "------"}
                      </span>
                    </div>
                  </div>
                  <div className="quantite_prixunitaire">
                    <TextField
                      label="Quantité"
                      id="outlined-size-small"
                      size="small"
                      inputProps={{
                        type: "number",
                        min: 1,
                      }}
                      value={quantity}
                      onChange={(e) => setQuantity(+e.target.value)}
                      fullWidth
                      sx={{ backgroundColor: "#fff" }}
                    />
                    <TextField
                      label="Montant"
                      type="number"
                      id="outlined-size-small"
                      size="small"
                      value={montant}
                      onChange={(e) => setMontant(+e.target.value)}
                      fullWidth
                      sx={{ backgroundColor: "#fff" }}
                    />
                  </div>

                  <div className="total_reste">
                    <span>
                      Prix total à payer:{" "}
                      {orderNatureItemParse
                        ? `${orderNatureItemParse?.prixunitaire * quantity} ${
                            orderNatureItemParse.devise
                          }`
                        : "------"}
                    </span>

                    {msgError && (
                      <div className="errorMsg">
                        <span>{msgError}</span>
                        <CancelOutlined className="icon" />
                      </div>
                    )}
                  </div>
                </div>
              )}

              {!loadingBtn &&
                (loading_allNature ? (
                  <Skeleton height={60} style={{ marginTop: "-40px" }} />
                ) : (
                  <button
                    onClick={
                      categorieId !== 0 && categorieId !== undefined
                        ? handleSubmit
                        : noAction
                    }
                    disabled={
                      categorieId === 0 || categorieId === undefined
                        ? true
                        : false
                    }
                    className={
                      categorieId === 0 || categorieId === undefined
                        ? "disabled"
                        : "activated"
                    }
                  >
                    <span>Passer la commande</span>
                    <AddShoppingCartOutlined className="ico" />
                  </button>
                ))}

              {loadingBtn && (
                <button className="activated">
                  <span>
                    <CircularProgress size={19} sx={{ color: "#fff" }} />
                  </span>
                  {/* <AddShoppingCartOutlined className="ico" /> */}
                </button>
              )}
            </div>
          </div>
        </div>
        <RecentOrder />
      </div>

      {/* {<SuccessDialog />} */}
      {openSuccess && (
        <SuccessDialog title={"Commande effectuée avec succes"} />
      )}

      {openOrder && <OrderCardDialog setOpen={setopenOrder} />}
    </div>
  );
};

export default Order;
