import { LinearProgress, Skeleton } from "@mui/material";
import React from "react";
import "./cardpoussin.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { natureType } from "../../../types/GlobalType";
// import _ from "lodash";
import { calculPercentage } from "../../../_utils/fonctions";
import { CATEGOTY_ID } from "../../../_service/_const";

type PropsType = {
  isLoading: boolean;
};
const CardPoussin = (props: PropsType) => {
  const { poussinStore } = useSelector((state: RootState) => state.stock);
  const { allNature } = useSelector((state: RootState) => state.nature);

  //tri de nature des poussins specifiquement
  const poussinNature: natureType[] = [];

  for (let nature of allNature) {
    if (nature.cat_produit_id === CATEGOTY_ID.POUSSIN)
      poussinNature.push(nature);
  }

  //calcule de quantite par nature des poussins presents dans le stock
  let natureArray: natureType & { quantite: number }[] = [];
  if (poussinNature.length > 0) {
    for (let item of poussinNature) {
      let itemCopy: natureType & { quantite: number } = {
        quantite: 0,
      };
      for (let i of poussinStore) {
        if (
          item.designation?.toLowerCase() ===
          i.nature_designation?.toLowerCase()
        ) {
          let qnty: number = i.quantite;
          itemCopy = { ...item, quantite: itemCopy.quantite + qnty };
        }
      }
      if (itemCopy.quantite === 0) natureArray.push({ ...item, quantite: 0 });
      else natureArray.push(itemCopy);
    }
  }

  //calcul de la somme (qunatite) totale avec la methode reduce
  let qntyTotal: number = 0;
  qntyTotal = natureArray.reduce((a: number, b: any): number => {
    return a + b?.quantite;
  }, 0);

  return (
    <>
      {props.isLoading ? (
        <div className="poussin_card">
          <div className="header">
            <Skeleton variant="rounded" width={120} height={25} />
            <Skeleton variant="rounded" width={50} height={25} />
          </div>
          <div className="items_sk">
            <Skeleton variant="rounded" sx={{ width: "100%" }} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} />
            <Skeleton variant="rounded" sx={{ width: "100%" }} />
          </div>
        </div>
      ) : (
        <div className="poussin_card">
          <div className="header">
            <span>Bank-Poussins</span>
            <div className="number">
              <span>{qntyTotal}</span>
              <span>Total</span>
            </div>
          </div>
          <div className="content">
            {/* <div className="title">
          <span>Etat actuel</span>
          <span></span>
        </div> */}
            <div className="state">
              <div className="items">
                {natureArray.map((item: any, index: number) => {
                  let perc = (item.quantite / qntyTotal) * 100;
                  return (
                    <div className="item" key={index}>
                      <div className="item_header">
                        <span className="title">{item.designation}</span>
                        <span className="count">
                          {item.quantite}/
                          {calculPercentage(qntyTotal, item.quantite)}%
                        </span>
                      </div>
                      <div className="item_progressbar">
                        <LinearProgress
                          value={calculPercentage(qntyTotal, item.quantite)}
                          variant="determinate"
                          color={
                            calculPercentage(qntyTotal, item.quantite) < 50
                              ? "error"
                              : "info"
                          }
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CardPoussin;
