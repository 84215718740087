import React, { useState } from "react";
import "./ordersupplierall.scss";
import SearchBar from "../../search/Search";
import List from "./List";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { ORDER_STATUS_ } from "../../../_service/_const";
import { orderSpecType } from "../../../types/GlobalType";
import DialogList from "../_orderDialog/DialogList";

const OrderSupplierAll = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sortedData, setSortedData] = useState<orderSpecType[] | any>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const { allOrder } = useSelector((state: RootState) => state.order);

  let cmdEtablie: number = 0;
  let cmdReserve: number = 0;
  let cmdPartiellementRegle: number = 0;
  let cmdRegle: number = 0;
  let cmdAnnule: number = 0;

  let cmdEtablieArr: orderSpecType[] | any = [];
  let cmdReserveArr: orderSpecType[] | any = [];
  let cmdPartiellementRegleArr: orderSpecType[] | any = [];
  let cmdRegleArr: orderSpecType[] | any = [];
  let cmdAnnuleArr: orderSpecType[] | any = [];

  if (allOrder) {
    for (let item of allOrder) {
      if (item.statusCmd_id === ORDER_STATUS_.one.value) {
        cmdEtablieArr.push(item);
        cmdEtablie++;
      }
      if (item.statusCmd_id === ORDER_STATUS_.two.value) {
        cmdReserveArr.push(item);
        cmdReserve++;
      }
      // if (item.statusCmd_id === ORDER_STATUS_.three.value) {
      //   cmdPartiellementRegleArr.push(item);
      //   cmdPartiellementRegle++;
      // }
      if (item.statusCmd_id === ORDER_STATUS_.four.value) {
        cmdRegleArr.push(item);
        cmdRegle++;
      }
      if (item.statusCmd_id === ORDER_STATUS_.five.value) {
        cmdAnnuleArr.push(item);
        cmdAnnule++;
      }
    }
  }

  const handleOpenDialog = (arr: orderSpecType, status: string) => {
    setSortedData(arr);
    setStatusOrder(status);
    setOpen(true);
  };

  return (
    <div className="OrderSupplierAll">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>

        <div className="btns">
          {/* <Tooltip title={"Commande annulée"}> */}
          <span
            className="status canceled"
            onClick={() =>
              handleOpenDialog(cmdAnnuleArr, ORDER_STATUS_.five.designatoin)
            }
          >
            Annulée {cmdAnnule}
          </span>
          {/* </Tooltip> */}
          {/* <Tooltip title={"Commande établie"}> */}
          <span
            className="status pending"
            onClick={() =>
              handleOpenDialog(cmdEtablieArr, ORDER_STATUS_.one.designatoin)
            }
          >
            Etablie {cmdEtablie}
          </span>
          {/* </Tooltip> */}
          {/* <Tooltip title={"Commande réservée"}> */}
          <span
            className="status pending"
            onClick={() =>
              handleOpenDialog(cmdReserveArr, ORDER_STATUS_.two.designatoin)
            }
          >
            Réservée {cmdReserve}
          </span>
          {/* </Tooltip> */}
          {/* <Tooltip title={"Commande réglée"}> */}
          <span
            className="status validate"
            onClick={() =>
              handleOpenDialog(cmdRegleArr, ORDER_STATUS_.four.designatoin)
            }
          >
            Réglée {cmdRegle}
          </span>
          {/* </Tooltip> */}
        </div>
      </div>
      {open && (
        <DialogList
          orders={sortedData}
          status={statusOrder}
          setOpen={setOpen}
        />
      )}
      <List searchValue={searchValue} />
    </div>
  );
};

export default OrderSupplierAll;
