import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { SupplierType } from "../../../types/UserTypes";
import { useEffect } from "react";
import exportFromJSON from "export-from-json";
import COUNTRY_CODES_EN from "../../../_service/_countryCode";
import _, { capitalize, toLower } from "lodash";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const suppliers = useSelector(
    (state: RootState) => state.supplier.allSupplier
  );

  let orderedData = _.orderBy(suppliers, ["created_at"], ["desc"]);

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  let contextBtnRef_ok: any = contextBtnRef[0];
  const handleClick = () => {
    const fileName = "NAGRITECH_FOURNISSEURS_TABLE";
    const exportType = "xls";
    const fields = [
      "id",
      "nom",
      "postnom",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
    ];
    exportFromJSON({ data: suppliers, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef_ok.current;
    // console.log(button);
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  // ----------------------------------------------

  if (suppliers.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-2">Entreprise </div>
            <div className="col col-3">Téléphone</div>
            {/* <div className="col col-4">Création</div> */}
            <div className="col col-2">Adresse mail</div>
            <div className="col col-4">Pays</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-2">Entreprise </div>
          <div className="col col-3">Téléphone</div>
          {/* <div className="col col-4">Création</div> */}
          <div className="col col-2">Adresse mail</div>
          <div className="col col-4">Ville</div>
        </li>

        {orderedData
          .filter(
            (item: SupplierType) =>
              item?.nom_entreprise
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.email
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.nom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.postnom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.telephone
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((supplier: SupplierType, index: number) => {
            // console.log(supplier);
            let country;
            if (supplier.pays && COUNTRY_CODES_EN[supplier.pays]) {
              country = COUNTRY_CODES_EN[supplier.pays].primary;
            } else {
              country = supplier.pays;
            }
            return (
              <Link to={`/suppliers/${supplier.nom}`} state={supplier}>
                <li className="table-row" key={index}>
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-2" data-label="Entreprise">
                    {`${supplier.nom_entreprise?.toUpperCase()} `}
                  </div>
                  <div className="col col-3" data-label="Téléphone">
                    {supplier.telephone}
                  </div>
                  {/* <div className="col col-4" data-label="Service">
              {supplier.created_at}
            </div> */}
                  <div className="col col-2" data-label="Email">
                    {toLower(supplier.email?.toLowerCase())}
                  </div>
                  <div className="col col-4" data-label="Ville">
                    {capitalize(supplier.ville)}
                  </div>
                </li>
              </Link>
            );
          })}
      </ul>
    </Box>
  );
}
