import React from "react";
import "./updatepsw.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {
  Lock,
  Password,
  PhoneAndroidOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../../../types/GlobalType";
import admin_api from "../../../../_service/admin.Caller";
import { APP_CONST, ERROR_MESSAGE } from "../../../../_service/_const";
import MyLoading_v3 from "../../../loader/Loader_v3";
import ConnexionBar from "../../../errorComp/connexionBar";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  agent_id: string | number | undefined;
  agent_num: string | undefined;
  setOpenSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
};
const UpdatePswd = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        await admin_api.resetAgentPsw(props.agent_id, token);

        props.setOpenSuccess(true);
        props.setOpen(false);
        setState({
          isLoading: false,
          errorMessage: null,
        });
      } catch (error: any) {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <>
      {state.isLoading && <MyLoading_v3 isLoading={true} />}
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="update_pswd">
        <div className="dialog">
          <div className="header_pswd">
            <div className="header_left">
              <Lock />
              {"Modification mot de passe"}
            </div>
            <Close className="ico_close" onClick={() => props.setOpen(false)} />
          </div>
          <div className="body">
            <div className="item">
              <div className="label">
                <PhoneAndroidOutlined className="ico" />
                <span>{props.agent_num}</span>
              </div>
            </div>
            <div className="item ">
              <div className="label">
                <div className="inputContainer">
                  <input
                    disabled
                    type="text"
                    placeholder={`Default pswd: ${APP_CONST.defaultPassword}`}
                  />

                  <VisibilityOutlined className="icoPas" />
                </div>
              </div>
            </div>
            <button onClick={handleUpdate}>Réinitialiser</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePswd;
