import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { incubationType } from "../../types/GlobalType";

interface OrderInitialState{
  allIncubation: incubationType[],
}

const initialState:OrderInitialState = {
  allIncubation: [],
};
const incubationSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addallIncubation: (state, action:PayloadAction<incubationType []>) => {
      state.allIncubation=Object.assign([], action.payload)
    },
  },
});

export const {
  addallIncubation,
} = incubationSlice.actions;

export default incubationSlice.reducer;
