import React, { useReducer, useState } from "react";
import "./admin.scss";
import Search from "@mui/icons-material/Search";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import { IconButton, Menu, MenuItem } from "@mui/material";
import SuccessAlert from "../../alert/AlerteBar";
import AddAdminDialog from "./AddAdminDialog";
import AdminTable from "../../table/AdminTableConfig";

const options = ["Ajouter nouvel admin"];
const ITEM_HEIGHT = 48;

const Admin = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openAdd, setOpenAdd] = useState<Boolean>(false);
  const open = Boolean(anchorEl);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [successCreate, setSuccessCreate] = useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="user_config">
      <div className="title">
        <span>Gestion des administrateurs</span>
        <div className="header">
          <div className="Searchbar">
            <Search className="icon" />
            <input
              placeholder="Rechercher par nom"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertOutlined className="ico" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "18ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={() => {
                    setOpenAdd(true);
                    handleClose();
                  }}
                  sx={{ fontSize: 12, fontWeight: "bold" }}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
      <div className="container">
        <AdminTable
          reducerValueOnInsert={reducerValueOnInsert}
          seachValue={searchValue}
        />
      </div>

      {successCreate && (
        <SuccessAlert
          setOpen={setSuccessCreate}
          label={"Opération effectuée avec success"}
          theme={"success"}
        />
      )}
      {openAdd && (
        <AddAdminDialog
          setOpenAdd={setOpenAdd}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessCreate={setSuccessCreate}
        />
      )}
    </div>
  );
};

export default Admin;
