import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/admin",
});

const admin_api = {
  login: (data) => {
    return API_CALLER.post("/login.php", data);
  },

  // logout: () => {
  //   return API_CALLER.get("/logout.php");
  // },

  // create: (data) => {
  //   return API_CALLER.post("/create.php", data);
  // },

  create: (data, token) => {
    return API_CALLER.post("/create.php", data, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
  getOne: (id, token) => {
    return API_CALLER.get(`/readById.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  getAll: (token) => {
    return API_CALLER.get("/read.php", {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  update: (id, data, token) => {
    return API_CALLER.put("/update.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  delete: (id, token) => {
    return API_CALLER.delete(`/delete.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  updatePassword: (id, data, token) => {
    return API_CALLER.put("/updatePassword.php?id=" + id, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },

  resetClientPsw: (id, token) => {
    return API_CALLER.get(`/resetClientPassword.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
  resetAgentPsw: (id, token) => {
    return API_CALLER.get(`/resetAgentPassword.php?id=${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  },
};

export default admin_api;
