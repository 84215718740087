import * as React from "react";
// import "./rapport_ok.scss";
import "./list.scss";
import { Box } from "@mui/material";
import RapportDialog from "./RapportDialog";
import { RapportType } from "../../../types/GlobalType";
import {
  CATEGORY_NATURE,
  CATEGOTY_ID,
  STATUS_RAPPORT_ID,
} from "../../../_service/_const";
import _, { capitalize } from "lodash";
import moment from "moment";
import aliment from "../../../assets/aliment.jpg";
import bio from "../../../assets/categories/gas.svg";
import egg from "../../../assets/categories/egg.svg";
import poussin from "../../../assets/poussin.jpg";
import poule from "../../../assets/categories/chicken_1.svg";
import inc from "../../../assets/categories/incubator_brun.svg";
import poulet from "../../../assets/poulet.jpg";
import NoData from "../../errorComp/NoData";

type Props = {
  data: RapportType[];
  searchValue: string;
  forceReloadOnUpdate: () => void;
  setOpenSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
};

export default function List(props: Props) {
  const [singleData, setSingleData] = React.useState<RapportType>();
  const [openDetail, setOPenDetail] = React.useState<Boolean>(false);

  if (props.data.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }

  let orderedData = _.orderBy(props.data, ["created_at"], ["desc"]);

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-2">Date </div>
          <div className="col col-3">Service</div>
          <div className="col col-3"></div>
          <div className="col col-3">Agent</div>
          <div className="col col-3">Status</div>
        </li>
        {orderedData
          ?.filter(
            (items) =>
              items.nom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              items.prenom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              items.nature_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              items.status_rapport_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              items.date
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((item, index) => {
            let category: string = "";
            let imgName: string = "";

            switch (item.cat_produit_id) {
              case CATEGOTY_ID.ALIMENT:
                category = CATEGORY_NATURE.aliment;
                imgName = aliment;
                break;
              case CATEGOTY_ID.BIOGAZ:
                category = CATEGORY_NATURE.biogaz;
                imgName = bio;
                break;
              case CATEGOTY_ID.OEUF:
                category = CATEGORY_NATURE.oeuf;
                imgName = egg;
                break;
              case CATEGOTY_ID.POUSSIN:
                category = CATEGORY_NATURE.poussin;
                imgName = poussin;
                break;
              case CATEGOTY_ID.POULE:
                category = CATEGORY_NATURE.poule;
                imgName = poule;
                break;
              case CATEGOTY_ID.POULET:
                category = CATEGORY_NATURE.poulet;
                imgName = poulet;

                break;
              default:
                break;
            }

            return (
              <li
                className="table-row"
                key={index}
                onClick={() => {
                  setSingleData(item);
                  setOPenDetail(true);
                }}
              >
                <div className="col col-3" data-label="Id">
                  {`0${index + 1}`}
                </div>
                <div className="col col-2" data-label="Date">
                  {capitalize(moment(item.date).format("llll"))}
                </div>

                {!!item.rapport_Inc_id ? (
                  <div className="col col-3" data-label="Service">
                    {capitalize("Incubation")}
                  </div>
                ) : (
                  <div className="col col-3" data-label="Service">
                    {capitalize(category)}
                  </div>
                )}

                {!!item.rapport_Inc_id ? (
                  <div className="col col-3">
                    <img src={inc} alt="img_category" className="img_cat" />
                  </div>
                ) : (
                  <div className="col col-3">
                    <img src={imgName} alt="img_category" className="img_cat" />
                  </div>
                )}

                <div className="col col-3" data-label="Nom Agent">
                  {`${item.nom} ${item.prenom}`}
                </div>
                <div className="col col-3" data-label="Status">
                  {item.status_rapport_id === STATUS_RAPPORT_ID.VALIDE && (
                    <div className="status validate">
                      {capitalize(item.status_rapport_designation)}
                    </div>
                  )}
                  {item.status_rapport_id === STATUS_RAPPORT_ID.ETABLI && (
                    <div className="status pending">
                      {capitalize(item.status_rapport_designation)}
                    </div>
                  )}
                  {item.status_rapport_id === STATUS_RAPPORT_ID.A_REVISER && (
                    <div className="status reviewing">
                      {capitalize(item.status_rapport_designation)}
                    </div>
                  )}
                  {item.status_rapport_id === STATUS_RAPPORT_ID.ANNULE && (
                    <div className="status canceled">
                      {capitalize(item.status_rapport_designation)}
                    </div>
                  )}
                </div>
              </li>
            );
          })}
      </ul>

      {openDetail && (
        <RapportDialog
          setOpen={setOPenDetail}
          data={singleData}
          forceReloadOnUpdate={props.forceReloadOnUpdate}
          setOpenSuccess={props.setOpenSuccess}
        />
      )}
    </Box>
  );
}
