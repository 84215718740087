import { Dialog, DialogContent } from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

type Props = {
  isLoading: boolean;
};
const styles = {
  root: {
    backgroundColor: "transparent",
  },
};
const MyLoadingv3 = ({ isLoading }: Props) => {
  return (
    <>
      {isLoading && (
        <Dialog
          open={isLoading}
          PaperProps={{
            style: { backgroundColor: "transparent", boxShadow: "none" },
          }}
        >
          {/* <DialogContent
            sx={{
              width: "200px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          > */}
          <div className="loader">
            {/* <Circles
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              /> */}
            <RotatingLines
              visible={true}
              width="40"
              strokeColor="#fff"
              strokeWidth="3"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
            <span style={{ marginTop: 10 }}>Chargement...</span>
          </div>
          {/* </DialogContent> */}
        </Dialog>
      )}
    </>
  );
};

export default MyLoadingv3;
