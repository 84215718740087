import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_CALLER = axios.create({
  // baseURL: URL_BACKEND_APP+"/nature",
  baseURL: URL_BACKEND_APP + "/incubation",
});

const incubation_api = {
  getAll: (token) => {
    return API_CALLER.get("/read.php", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  },
};

export default incubation_api;
