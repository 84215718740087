import React, { useState } from "react";
import "./newCommande.scss";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/GlobalType";
import client_api from "../../_service/client.Caller";
import { CATEGORY_NATURE, ERROR_MESSAGE } from "../../_service/_const";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { capitalize } from "lodash";
import { Person2 } from "@mui/icons-material";
import ErrorBar from "../../components/errorComp/ErrorBar";

type Props = {
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessCreate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
};

type ClientType = {
  nom?: string;
  postnom?: string;
  prenom?: string;
  nom_entreprise?: string;
  titre?: string;
  annee_existence?: string;
  sexe: string;
  pays?: string;
  ville: string;
  commune: string;
  quartier?: string;
  avenue?: string;
  email?: string;
  password?: string;
  telephone?: string;
  tranche_age_id: string;
};

const NewCommande = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const [error, setError] = useState<boolean>(false);

  const [clientType, setClientType] = useState<string>("1");

  //phone number state
  const [phoneNumber, setPhoneNumber] = useState("");
  const [validdNumber, setValidNumber] = useState<boolean>(true);
  const handleChangePhone = (input: string) => {
    setPhoneNumber(input);
    setValidNumber(validatePhoneNumber(input));
  };

  //country state
  const [selectedCountry, setSelectedCountry] = useState<string>("CD");

  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const [data, setData] = React.useState<ClientType>({
    nom: "",
    postnom: "",
    prenom: "",
    nom_entreprise: "",
    titre: "",
    annee_existence: "",
    sexe: "M",
    email: "",
    password: "1234",
    ville: "",
    commune: "",
    quartier: "",
    avenue: "",
    tranche_age_id: "1",
  });

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleClientChange = (e: any) => {
    setClientType(e.target.value);
    setData({
      nom: "",
      postnom: "",
      prenom: "",
      nom_entreprise: "",
      titre: "",
      annee_existence: "",
      sexe: "M",
      email: "",
      password: "12345678",
      ville: "",
      commune: "",
      quartier: "",
      avenue: "",
      tranche_age_id: "1",
    });

    setPhoneNumber("");
    setSelectedCountry("");
  };

  if (error) {
    setTimeout(() => {
      setError(false);
    }, 4000);
  }

  async function handleSubmitPhysicalClient() {
    setState({ isLoading: false, errorMessage: null });
    const { nom, postnom, prenom, email, ville, commune, quartier, avenue } =
      data;

    if (nom?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le nom",
      }));
    if (postnom?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le postnom",
      }));
    if (prenom?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le prénom",
      }));
    if (email?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter l'adresse mail",
      }));

    if (phoneNumber?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le numéro de téléphone ",
      }));
    if (ville?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter la ville",
      }));
    if (selectedCountry?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le pays",
      }));
    if (quartier?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le quartier",
      }));
    if (avenue?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner l'avenue",
      }));
    if (commune?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner la commune",
      }));

    setState({ isLoading: true, errorMessage: null, succussMessage: "" });

    // console.log(data);
    // console.log(`+${phoneNumber}`, selectedCountry);

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      client_api
        .create(
          { ...data, telephone: `+${phoneNumber}`, pays: selectedCountry },
          token
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 205) {
            return setState({
              isLoading: false,
              errorMessage: "Adresse mail invalide",
            });
          }
          if (response.status === 203) {
            return setState({
              isLoading: false,
              errorMessage: "Ce numéro existe déjà",
            });
          }
          props.setSuccessCreate(true);

          setState({ isLoading: false, errorMessage: null });
          props.setOpenAdd(false);

          navigate("/orders", {
            state: { newClientPhoneNum: `+${phoneNumber}` },
          });
          props.forceUpdateOnInsert();

          setData({
            nom: "",
            postnom: "",
            prenom: "",
            sexe: "M",
            email: "",
            password: "12345678",
            pays: "RD Congo",
            ville: "",
            commune: "",
            quartier: "",
            avenue: "",
            tranche_age_id: "1",
          });

          setSelectedCountry("");
          setPhoneNumber("");
        })
        .catch((error: any) => {
          // console.log(error, "sssssssssssssssssss");
          if (error.response) {
            if (error.response.status === 401) {
              return setState({
                isLoading: false,
                errorMessage: "Cette adresse mail existe",
              });
            }
            if (error.response.status === 406) {
              return setState({
                isLoading: false,
                errorMessage: "Numéro de téléphone invalide",
              });
            }
            if (error.response.status === 422) {
              return setState({
                isLoading: false,
                errorMessage: "Adresse email invalide",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }
  async function handleSubmitMoralClient() {
    setState({ isLoading: false, errorMessage: null });

    const {
      nom_entreprise,
      titre,
      nom,
      email,
      ville,
      commune,
      quartier,
      sexe,
      avenue,
      password,
      annee_existence,
    } = data;

    let dataMoralData = {
      nom_entreprise,
      titre,
      nom,
      email,
      ville,
      commune,
      quartier,
      avenue,
      sexe,
      password,
      annee_existence,
    };

    // console.log(dataMoralData);

    if (nom?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le nom de du représentant",
      }));
    if (nom_entreprise?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le nom de l'entreprise",
      }));
    if (titre?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le titre du représentant",
      }));
    if (email?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter l'adresse mail",
      }));

    if (phoneNumber?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le numéro de téléphone ",
      }));
    if (ville?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter la ville",
      }));
    if (selectedCountry?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le pays",
      }));
    if (quartier?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le quartier",
      }));
    if (avenue?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner l'avenue",
      }));
    if (commune?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner la commune",
      }));

    setState({ isLoading: true, errorMessage: null, succussMessage: "" });

    // console.log(data);
    // console.log(`+${phoneNumber}`, selectedCountry);

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      client_api
        .createMoralClient(
          {
            ...dataMoralData,
            telephone: `+${phoneNumber}`,
            pays: selectedCountry,
          },
          token
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 205) {
            return setState({
              isLoading: false,
              errorMessage: "Adresse mail invalide",
            });
          }
          if (response.status === 203) {
            return setState({
              isLoading: false,
              errorMessage: "Cette adresse mail existe déja",
            });
          }
          props.setSuccessCreate(true);

          setState({ isLoading: false, errorMessage: null });
          props.setOpenAdd(false);

          navigate("/orders", {
            state: { newClientPhoneNum: `+${phoneNumber}` },
          });
          props.forceUpdateOnInsert();

          setData({
            nom: "",
            postnom: "",
            prenom: "",
            sexe: "M",
            email: "",
            password: "12345678",
            pays: "RD Congo",
            ville: "",
            commune: "",
            quartier: "",
            avenue: "",
            tranche_age_id: "1",
          });

          setSelectedCountry("");
          setPhoneNumber("");
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 401) {
              return setState({
                isLoading: false,
                errorMessage: "Cette adresse mail existe",
              });
            }
            if (error.response.status === 406) {
              return setState({
                isLoading: false,
                errorMessage: "Numéro de téléphone invalide",
              });
            }
            if (error.response.status === 422) {
              return setState({
                isLoading: false,
                errorMessage: "Adresse email invalide",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }

  return (
    <div className="newCommande">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <Person2 className="ico" />
            <span>Enregistrer client</span>
          </div>
        </div>

        {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}

        <div className="body">
          <div className="item">
            <label>Type du Client</label>
            <select
              name="clientType"
              value={clientType}
              onChange={handleClientChange}
            >
              <option value="1">Personne</option>
              <option value="0">Organisation</option>
            </select>
          </div>

          {/* PHYSICAL CLIENT DETAIL FORM */}
          {clientType === "1" && (
            <>
              <div className="item">
                <label>Nom</label>
                <input
                  placeholder="Nom..."
                  type="text"
                  name="nom"
                  value={data.nom}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Post-nom</label>
                <input
                  placeholder="Post-nom..."
                  type="text"
                  name="postnom"
                  value={data.postnom}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Prénom</label>
                <input
                  placeholder="Prénon..."
                  type="text"
                  name="prenom"
                  value={data.prenom}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Genre</label>
                <select name="sexe" value={data.sexe} onChange={handleChange}>
                  <option value="M">Masculin</option>
                  <option value="F">Feminin</option>
                </select>
              </div>

              <div className="item">
                <label>Adresse mail</label>
                <input
                  placeholder="Email..."
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>

              <div className="item pswd">
                <label>Mot de passe</label>
                <input
                  placeholder="Par défaut: 123456789"
                  type="text"
                  disabled
                  value={data.password}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Tranche d'âge</label>
                <select
                  name="tranche_age_id"
                  value={data.tranche_age_id}
                  onChange={handleChange}
                >
                  <option value="1">15-20</option>
                  <option value="2">20-30</option>
                  <option value="3">30-40</option>
                  <option value="4">40-50</option>
                  <option value="5">50 et plus</option>
                </select>
              </div>

              <div className="item">
                <label>Service</label>
                <select
                  name="services_id"
                  // defaultValue={data.}
                  onChange={handleChange}
                >
                  <option value="1">
                    {capitalize(CATEGORY_NATURE.aliment)}
                  </option>
                  <option value="2">
                    {capitalize(CATEGORY_NATURE.biogaz)}
                  </option>
                  <option value="3">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                  <option value="4">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                  <option value="5">{capitalize(CATEGORY_NATURE.poule)}</option>
                  <option value="6">
                    {capitalize(CATEGORY_NATURE.poulet)}
                  </option>
                </select>
              </div>

              <div className="item phone">
                <label>Téléphone</label>
                <PhoneInput
                  country={"cd"}
                  value={phoneNumber}
                  onChange={handleChangePhone}
                />
                {!validdNumber && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Entrer un numéro valide
                  </span>
                )}
                {/* <input
              placeholder="Téléphone..."
              type="text"
              name="telephone"
              value={data.telephone}
              onChange={handleChange}
            /> */}
              </div>

              <div className="item country">
                <label>Pays</label>
                <ReactFlagsSelect
                  selected={selectedCountry}
                  onSelect={(code) => setSelectedCountry(code)}
                  optionsSize={13}
                  selectedSize={13}
                  searchPlaceholder="Chercher pays"
                  placeholder="Choix du pays"
                  searchable
                  className="menu-flags"
                  selectButtonClassName="menu-flags-button"
                />
              </div>

              {/* <div className="item">
            <label>Pays</label>
            <input
              placeholder="Pays..."
              type="text"
              name="pays"
              value={data.pays}
              onChange={handleChange}
              disabled
            />
          </div> */}
              <div className="item">
                <label>Ville</label>
                <input
                  placeholder="Ville..."
                  type="text"
                  name="ville"
                  value={data.ville}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Commune</label>
                <input
                  placeholder="Commune..."
                  type="text"
                  name="commune"
                  value={data.commune}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Quartier</label>
                <input
                  placeholder="Quartier..."
                  type="text"
                  name="quartier"
                  value={data.quartier}
                  onChange={handleChange}
                />
              </div>

              <div className="item avenue">
                <label>Avenue</label>
                <input
                  placeholder="Avenue..."
                  type="text"
                  name="avenue"
                  value={data.avenue}
                  onChange={handleChange}
                />
              </div>
            </>
          )}

          {/* MORAL CLIENT DETAIL FORM */}
          {clientType === "0" && (
            <>
              <div className="item">
                <label>Entreprise</label>
                <input
                  placeholder="Entreprise..."
                  type="text"
                  name="nom_entreprise"
                  value={data.nom_entreprise}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Répresentant</label>
                <input
                  placeholder="Représentant..."
                  type="text"
                  name="nom"
                  value={data.nom}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Titre</label>
                <input
                  placeholder="Titre..."
                  type="text"
                  name="titre"
                  value={data.titre}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Genre</label>
                <select name="sexe" value={data.sexe} onChange={handleChange}>
                  <option value="M">Masculin</option>
                  <option value="F">Feminin</option>
                </select>
              </div>

              <div className="item">
                <label>Service</label>
                <select
                  name="services_id"
                  // defaultValue={data.}
                  onChange={handleChange}
                >
                  <option value="1">
                    {capitalize(CATEGORY_NATURE.aliment)}
                  </option>
                  <option value="2">
                    {capitalize(CATEGORY_NATURE.biogaz)}
                  </option>
                  <option value="3">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                  <option value="4">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                  <option value="5">{capitalize(CATEGORY_NATURE.poule)}</option>
                  <option value="6">
                    {capitalize(CATEGORY_NATURE.poulet)}
                  </option>
                </select>
              </div>

              <div className="item orgMail">
                <label>Adresse mail</label>
                <input
                  placeholder="Email..."
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
              <div className="item orgAnneExistence">
                <label>Année d'existence</label>
                <input
                  placeholder="Année..."
                  type="number"
                  min={0}
                  name="annee_existence"
                  value={data.annee_existence}
                  onChange={handleChange}
                />
              </div>
              <div className="item phone">
                <label>Téléphone</label>
                <PhoneInput
                  country={"cd"}
                  value={phoneNumber}
                  onChange={handleChangePhone}
                />
                {!validdNumber && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Entrer un numéro valide
                  </span>
                )}
              </div>

              <div className="item orgCountry">
                <label>Pays</label>
                <ReactFlagsSelect
                  selected={selectedCountry}
                  onSelect={(code) => setSelectedCountry(code)}
                  optionsSize={13}
                  selectedSize={13}
                  searchPlaceholder="Chercher pays"
                  placeholder="Choix du pays"
                  searchable
                  className="menu-flags"
                  selectButtonClassName="menu-flags-button"
                />
              </div>

              {/* <div className="item">
            <label>Pays</label>
            <input
              placeholder="Pays..."
              type="text"
              name="pays"
              value={data.pays}
              onChange={handleChange}
              disabled
            />
          </div> */}
              <div className="item">
                <label>Ville</label>
                <input
                  placeholder="Ville..."
                  type="text"
                  name="ville"
                  value={data.ville}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Commune</label>
                <input
                  placeholder="Commune..."
                  type="text"
                  name="commune"
                  value={data.commune}
                  onChange={handleChange}
                />
              </div>

              <div className="item">
                <label>Quartier</label>
                <input
                  placeholder="Quartier..."
                  type="text"
                  name="quartier"
                  value={data.quartier}
                  onChange={handleChange}
                />
              </div>

              <div className="item avenueMoral">
                <label>Avenue</label>
                <input
                  placeholder="Avenue..."
                  type="text"
                  name="avenue"
                  value={data.avenue}
                  onChange={handleChange}
                />
              </div>
            </>
          )}
        </div>

        {clientType === "1" && (
          <div className="btn">
            {!state.isLoading && (
              <>
                <button onClick={() => props.setOpenAdd(false)}>Annuler</button>
                <button onClick={handleSubmitPhysicalClient}>Ajouter</button>
              </>
            )}
            {state.isLoading && (
              <>
                <button>Annuler</button>
                <button>
                  <CircularProgress size={15} className="circular" />
                </button>
              </>
            )}
          </div>
        )}
        {clientType === "0" && (
          <div className="btn">
            {!state.isLoading && (
              <>
                <button onClick={() => props.setOpenAdd(false)}>Annuler</button>
                <button onClick={handleSubmitMoralClient}>Ajouter</button>
              </>
            )}
            {state.isLoading && (
              <>
                <button>Annuler</button>
                <button>
                  <CircularProgress size={15} className="circular" />
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewCommande;
