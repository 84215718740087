import React, { useState } from "react";
import "./singleorder.scss";
import { IconButton } from "@mui/material";
import {
  ArrowBackIosNew,
  ArrowForwardIos,
  ArrowForwardIosOutlined,
  AssignmentReturned,
  BlurOn,
  Close,
  DoneOutlined,
  DoubleArrow,
  Image,
  MapOutlined,
} from "@mui/icons-material";
// import ArrowForwardIosOutlined from '@mui/icons-material/FiberManualRecordOutlined';
import { useLocation, useNavigate } from "react-router-dom";
import {
  CATEGOTY_ID,
  ERROR_MESSAGE,
  STATUS_ORDER_ID,
} from "../../_service/_const";
import moment from "moment";
import { order_api } from "../../_service/order.Caller";
import { requestType } from "../../types/GlobalType";
import AttachedFile from "../../components/order/attachFile/AttachedFile";
import ErrorBar from "../../components/errorComp/ErrorBar";
import MyLoadingv3 from "../../components/loader/Loader_v3";
import ConnexionBar from "../../components/errorComp/connexionBar";
import SuccessDialog from "../../components/dialog/SuccessDialog";
import { roundedDownTwoDigit } from "../../_utils/number_utils";
import { capitalize } from "lodash";
import WarningDialog from "../../components/dialog/WarningDialog";
import { ProtectedSectionFor } from "../../_service/WithPermission";

const SingleOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openFile, setOpenFile] = React.useState<boolean>(false);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [openSuccessUpdateOrderStatus, setOpenSuccessUpdateOrderStatus] =
    React.useState<Boolean>(false);
  const [openStockWarning, setOpenStockWarning] =
    React.useState<Boolean>(false);

  const [montant_entry, setMontant] = React.useState<number>(0);
  const [errorMsg, setErrorMsg] = React.useState<string>("");

  const handleUpdateAmount = async () => {
    if (!!montant_entry === false) {
      setErrorMsg("Le montant n'est pas valide");
      return setTimeout(() => {
        setErrorMsg("");
      }, 4000);
    }

    if (montant !== undefined && prixtotal) {
      //ferify if montantant entré est superieur au reste a payer
      if (+montant_entry > +prixtotal - +montant) {
        setErrorMsg("Le montant est supérieur au reste à payer");
        return setTimeout(() => {
          setErrorMsg("");
        }, 4000);
      }
    }
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      let idOrder: number | string | any = "";
      let data = {
        montant: +montant_entry,
      };

      try {
        let response;
        if (location.state.cat_produit_id === CATEGOTY_ID.ALIMENT) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantAliment(
            idOrder,
            data,
            token
          );
          // console.log(res);
        } else if (location.state.cat_produit_id === CATEGOTY_ID.BIOGAZ) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantBiogaz(
            idOrder,
            data,
            token
          );
          // console.log(res);
        } else if (location.state.cat_produit_id === CATEGOTY_ID.OEUF) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantOeuf(
            idOrder,
            data,
            token
          );
          // console.log(res);
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POUSSIN) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantPoussin(
            idOrder,
            data,
            token
          );
          // console.log(res);
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POULE) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantPoule(
            idOrder,
            data,
            token
          );
          // console.log(res);
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POULET) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateMontantPoulet(
            idOrder,
            data,
            token
          );
          // console.log(res);
        }
        setState({
          isLoading: false,
          errorMessage: null,
        });

        // console.log(response);

        if (response?.status === 200) {
          setOpenSuccessUpdateOrderStatus(true);
          return setTimeout(() => {
            setOpenSuccessUpdateOrderStatus(false);
            // props.setOpen(false);
            navigate(-1);
          }, 2000);
        }
      } catch (error: any) {
        // console.log(error, "ERRRRRRRR");
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  const handleChangeOrderStatus = async (order_status_id: number) => {
    // console.log(order_status_id);
    // console.log(location.state);

    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;
      const id = userLS.user.id;

      let idOrder: number | string | any = "";

      let data = { statusCmd_id: order_status_id, admins_idAdmin: id };

      try {
        let response;

        if (location.state.cat_produit_id === CATEGOTY_ID.ALIMENT) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusAliment(
            idOrder,
            data,
            token
          );
        } else if (location.state.cat_produit_id === CATEGOTY_ID.BIOGAZ) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusBiogaz(
            idOrder,
            data,
            token
          );
        } else if (location.state.cat_produit_id === CATEGOTY_ID.OEUF) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusOeuf(
            idOrder,
            data,
            token
          );
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POUSSIN) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusPoussin(
            idOrder,
            data,
            token
          );
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POULE) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusPoule(
            idOrder,
            data,
            token
          );
        } else if (location.state.cat_produit_id === CATEGOTY_ID.POULET) {
          idOrder = location.state.id;
          response = await order_api.categoryOrder.updateStatusPoulet(
            idOrder,
            data,
            token
          );
        }
        // console.log(response);
        setState({
          isLoading: false,
          errorMessage: null,
        });

        if (response?.status === 200) {
          setOpenSuccessUpdateOrderStatus(true);
          return setTimeout(() => {
            setOpenSuccessUpdateOrderStatus(false);
            navigate(-1);
          }, 2000);
        } else if (response?.status === 205) return setOpenStockWarning(true);

        // props.setOpenSuccess(true);
        // props.forceReloadOnUpdate();
      } catch (error: any) {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const {
    statusCmd_id,
    cmd_client_id,
    nature_designation,
    categorie,
    devise,
    quantite,
    prixtotal,
    montant,
    nom,
    prenom,
    postnom,
    telephone,
    created_at,
    status_cmd_designation,
    paiement_img,
  } = location.state;

  const btn_section = (cmd_status_id: number) => {
    if (
      cmd_status_id === STATUS_ORDER_ID.REGLE ||
      cmd_status_id === STATUS_ORDER_ID.ANNULE ||
      cmd_status_id === STATUS_ORDER_ID.ETABLIE_AVEC_DETTE
    )
      return <div className="btns"></div>;
    else if (cmd_status_id === STATUS_ORDER_ID.RESERVE) {
      if (
        !!montant &&
        !!prixtotal &&
        roundedDownTwoDigit(+prixtotal) === roundedDownTwoDigit(+montant)
      ) {
        return (
          <div className="btns">
            <button
              onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.REGLE)}
              className="settled"
            >
              <DoneOutlined />
              {"Régler commande"}
            </button>

            <button
              onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.ANNULE)}
              className="canceled"
            >
              <Close />
              {"Annuler commande"}
            </button>
          </div>
        );
      } else {
        return (
          <>
            <div className="btns">
              <button
                onClick={() =>
                  handleChangeOrderStatus(STATUS_ORDER_ID.ETABLIE_AVEC_DETTE)
                }
                className="settled"
              >
                <DoneOutlined />
                {"Régler avec dette"}
              </button>

              <button
                onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.ANNULE)}
                className="canceled"
              >
                <Close />
                {"Annuler commande"}
              </button>
            </div>
            <div className="intput_validation">
              <div className="left_input">
                <input
                  type="number"
                  value={montant_entry}
                  onChange={(event) =>
                    setMontant(roundedDownTwoDigit(event.target.valueAsNumber))
                  }
                  placeholder="Montant restant"
                  min={0}
                />
                <button onClick={handleUpdateAmount}>Effectuer paiement</button>
              </div>
              <div className="total_reste">
                {prixtotal && montant !== undefined && (
                  <span>
                    Reste à payer:
                    {` ${roundedDownTwoDigit(+prixtotal - +montant)}  ${
                      location.state.devise
                    }`}
                  </span>
                )}
              </div>
            </div>
          </>
        );
      }
    } else if (cmd_status_id === STATUS_ORDER_ID.ETABLI) {
      if (
        !!montant &&
        !!prixtotal &&
        roundedDownTwoDigit(+prixtotal) === roundedDownTwoDigit(+montant)
      ) {
        return (
          <>
            <div className="btns">
              <button
                onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.REGLE)}
                className="settled"
              >
                <DoneOutlined />
                {"Régler commande"}
              </button>

              <button
                onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.ANNULE)}
                className="canceled"
              >
                <Close />
                {"Annuler commande"}
              </button>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="btns">
              <button
                onClick={() => {
                  handleChangeOrderStatus(STATUS_ORDER_ID.RESERVE);
                }}
                className="reserved"
              >
                <AssignmentReturned />
                {"Réserver Commande"}
              </button>

              <button
                onClick={() => handleChangeOrderStatus(STATUS_ORDER_ID.ANNULE)}
                className="canceled"
              >
                <Close />
                {"Annuler commande"}
              </button>
            </div>
            <div className="intput_validation">
              <div className="left_input">
                <input
                  type="number"
                  value={montant_entry}
                  onChange={(event) =>
                    setMontant(roundedDownTwoDigit(event.target.valueAsNumber))
                  }
                  placeholder="Montant restant"
                  min={0}
                />
                <button onClick={handleUpdateAmount}>Effectuer paiement</button>
              </div>
              <div className="total_reste">
                {prixtotal && montant !== undefined && (
                  <span>
                    Reste à payer:
                    {` ${roundedDownTwoDigit(+prixtotal - +montant)}  ${
                      location.state.devise
                    }`}
                  </span>
                )}
              </div>
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      <MyLoadingv3 isLoading={state.isLoading} />
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="single_order_page">
        <div className="header">
          <div className="left">
            <IconButton
              sx={{ backgroundColor: "#fff" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIosNew className="ico" />
            </IconButton>
            <h3>Commande cliente</h3>
            <DoubleArrow className="userIco" />
            <span className="nom">{` ${nom} `}</span>
          </div>
          <div className="right">
            {!!paiement_img ? (
              <div className="btn_update" onClick={() => setOpenFile(true)}>
                <Image className="ico" />
                <span>Preuve de paiement</span>
              </div>
            ) : (
              <div className="btn_update disabled">
                <Image className="ico" />
                <span>Preuve de paiement</span>
              </div>
            )}
          </div>
        </div>

        <div className="container_">
          <div className="header">
            <div className="title">
              <BlurOn className="ico" />
              <h4>Détail commande - {nature_designation}</h4>
              {+prixtotal === +montant &&
                statusCmd_id !== STATUS_ORDER_ID.REGLE &&
                statusCmd_id !== STATUS_ORDER_ID.ANNULE && (
                  <span className="iconoir--twitter-verified-badge_rouge"></span>
                )}
              {statusCmd_id === STATUS_ORDER_ID.REGLE && (
                <span className="iconoir--twitter-verified-badge"></span>
              )}
            </div>
            <div className="right_side_title">
              {/* <Close className="ico" onClick={() => props.setOpen(false)} /> */}
            </div>
          </div>
          {!!errorMsg && <ErrorBar title={errorMsg} />}
          <div className="left">
            <div className="top">
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Id Commande</span>
                  <div className="second_info">
                    <span>Nag-Id-00{cmd_client_id}</span>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Status</span>
                  <div className="second_info">
                    <span>
                      {statusCmd_id === STATUS_ORDER_ID.ETABLIE_AVEC_DETTE
                        ? "Réglée avec dette"
                        : status_cmd_designation}
                    </span>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Client</span>
                  <div className="second_info">
                    {!!location.state.is_legal_person ? (
                      <span>{`${nom}`}</span>
                    ) : (
                      <span>{`${prenom} ${nom} ${postnom}`}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Télephone</span>
                  <div className="second_info">
                    <span>{telephone}</span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_info">
                  <span className="main_info">Date de la commande</span>
                  <div className="status_info">
                    {capitalize(moment(created_at).format("llll"))}
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_info">
                  <span className="main_info">Preuve paiement</span>
                  {!!paiement_img ? (
                    <div
                      className="status_info paiement"
                      onClick={() => setOpenFile(true)}
                    >
                      <Image className="ico" />
                    </div>
                  ) : (
                    <>
                      <div className="status_info paiement">Aucune</div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="body">
              <div className="content">
                <div className="box nature">
                  <div className="box_icon">
                    <ArrowForwardIos className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Nature commande</span>
                    <div className="second_info">
                      <span>
                        {" "}
                        {categorie} - {nature_designation}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="box nature">
                  <div className="box_icon">
                    <ArrowForwardIosOutlined className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Reste</span>
                    <div className="second_info reste">
                      <span>{`${devise} ${roundedDownTwoDigit(
                        +prixtotal - +montant
                      )}`}</span>
                    </div>
                  </div>
                </div>

                <div className="box">
                  <div className="box_icon">
                    <ArrowForwardIos className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Quantité</span>
                    <div className="second_info">
                      <span>{quantite}</span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <ArrowForwardIos className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Prix unitaire</span>
                    <div className="second_info">
                      <span>
                        {quantite &&
                          prixtotal &&
                          `${devise} ${+prixtotal / +quantite}`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <ArrowForwardIos className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Cout total</span>
                    <div className="second_info">
                      <span>{prixtotal && `${devise} ${+prixtotal}`}</span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <ArrowForwardIos className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Montant payé</span>
                    <div className="second_info">
                      {montant === null ? (
                        <span>{`${devise} 0`}</span>
                      ) : (
                        <span>{montant && `${devise} ${+montant}`}</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ---------------------------------------------------------- */}
          {/*                FOOTER HANDLE DISPLAY BTNS */}
          {/* ---------------------------------------------------------- */}

          <div className="footer">
            <ProtectedSectionFor roleRequired="Financier">
              <>{btn_section(statusCmd_id)}</>
            </ProtectedSectionFor>
          </div>
        </div>
      </div>

      {openFile && (
        <AttachedFile setOpen={setOpenFile} data={nom} img={paiement_img} />
      )}

      {openSuccessUpdateOrderStatus && (
        <SuccessDialog
          title={"Mise à jour de la commande effectuée avec success"}
        />
      )}
      {openStockWarning && (
        <WarningDialog
          title={`Veuillez approvisionner le stock en - ${nature_designation} - avant de régler cette commande`}
          setOpen={setOpenStockWarning}
        />
      )}
    </>
  );
};

export default SingleOrder;
