import React, { useState } from "react";
import "./addUserDialog.scss";
import agent_api from "../../../_service/agent.Caller";
import { UserType } from "../../../types/UserTypes";
import { CircularProgress } from "@mui/material";
import { requestType } from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_NATURE,
  CATEGOTY_ID,
  ERROR_MESSAGE,
} from "../../../_service/_const";
import ErrorBar from "../../errorComp/ErrorBar";
import ConnexionBar from "../../errorComp/connexionBar";
import { Person2Rounded } from "@mui/icons-material";
import MyLoadingv3 from "../../loader/Loader_v3";

type Props = {
  setOpenAdd: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessCreate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
};

const AddUserDialog = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const [errorMsg, setErrorMsg] = useState("");

  const [data, setData] = React.useState<UserType>({
    nom: "",
    postnom: "",
    prenom: "",
    sexe: "M",
    email: "",
    password: "1234",
    telephone: "",
    pays: "RD Congo",
    ville: "",
    commune: "",
    quartier: "",
    avenue: "",
    services_id: "",
  });

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  async function handleSubmit() {
    setErrorMsg("");
    setState({ isLoading: false, errorMessage: null });
    const {
      nom,
      postnom,
      prenom,
      email,
      telephone,
      ville,
      commune,
      quartier,
      avenue,
    } = data;
    if (
      nom?.trim() === "" ||
      postnom?.trim() === "" ||
      prenom?.trim() === "" ||
      email?.trim() === "" ||
      telephone?.trim() === "" ||
      ville?.trim() === "" ||
      quartier?.trim() === "" ||
      commune?.trim() === "" ||
      avenue?.trim() === ""
    ) {
      return setErrorMsg("veuillez compléter les champs vides");
    }

    setState({ isLoading: true, errorMessage: null, succussMessage: "" });

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      agent_api
        .create(data, token)
        .then((response) => {
          if (response.status === 205) {
            return setState({
              isLoading: false,
              errorMessage: "Adresse mail invalide",
            });
          }
          props.setSuccessCreate(true);
          setState({ isLoading: false, errorMessage: null });
          props.setOpenAdd(false);
          props.forceUpdateOnInsert();

          setData({
            nom: "",
            postnom: "",
            prenom: "",
            sexe: "M",
            email: "",
            password: "",
            telephone: "",
            pays: "RD Congo",
            ville: "",
            commune: "",
            quartier: "",
            avenue: "",
            // service: "",
            services_id: "",
          });
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 401) {
              return setState({
                isLoading: false,
                errorMessage: "Utilisateur avec cette adresse mail existe",
              });
            }
            if (error.response.status === 422) {
              return setState({
                isLoading: false,
                errorMessage: "Adresse email invalide",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: ERROR_MESSAGE.networkError,
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }

  return (
    <>
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="add_user">
        <div className="dialog">
          <div className="header">
            <div className="title__">
              <div className="title_left__">
                <Person2Rounded className="ico" />
                <span>Nouvel agent</span>
              </div>
            </div>
          </div>
          {!!errorMsg && <ErrorBar title={errorMsg} />}
          {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
          <div className="body">
            <div className="item">
              <label>Nom</label>
              <input
                placeholder="Nom..."
                type="text"
                name="nom"
                value={data.nom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Post-nom</label>
              <input
                placeholder="Post-nom..."
                type="text"
                name="postnom"
                value={data.postnom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Prénom</label>
              <input
                placeholder="Prénon..."
                type="text"
                name="prenom"
                value={data.prenom}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Genre</label>
              <select name="sexe" value={data.sexe} onChange={handleChange}>
                <option value="M">Masculin</option>
                <option value="F">Feminin</option>
              </select>
            </div>
            <div className="item">
              <label>Adresse mail</label>
              <input
                placeholder="Email..."
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>
            <div className="item pswd">
              <label>Mot de passe (1234)</label>
              <input
                placeholder="Default 1234"
                type="password"
                disabled
                value={data.password}
                onChange={handleChange}
                style={{ fontWeight: "700" }}
              />
            </div>
            <div className="item">
              <label>Téléphone</label>
              <input
                placeholder="Téléphone..."
                type="text"
                name="telephone"
                value={data.telephone}
                onChange={handleChange}
              />
            </div>
            {/* <div className="item">
              <label>Service</label>
              <select
                name="services_id"
                defaultValue={data.services_id}
                value={data.services_id}
                onChange={handleChange}
              >
                <option value={CATEGOTY_ID.ALIMENT}>
                  {CATEGORY_NATURE.aliment}
                </option>
                <option value={CATEGOTY_ID.BIOGAZ}>
                  {CATEGORY_NATURE.biogaz}
                </option>
                <option value={CATEGOTY_ID.OEUF}>{CATEGORY_NATURE.oeuf}</option>
                <option value={CATEGOTY_ID.POUSSIN}>
                  {CATEGORY_NATURE.poussin}
                </option>
                <option value={CATEGOTY_ID.POULE}>
                  {CATEGORY_NATURE.poule}
                </option>
                <option value={CATEGOTY_ID.POULET}>
                  {CATEGORY_NATURE.poulet}
                </option>
              </select>
            </div> */}

            <div className="item">
              <label>Ville</label>
              <input
                placeholder="Ville..."
                type="text"
                name="ville"
                value={data.ville}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Commune</label>
              <input
                placeholder="Commune..."
                type="text"
                name="commune"
                value={data.commune}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Quartier</label>
              <input
                placeholder="Quartier..."
                type="text"
                name="quartier"
                value={data.quartier}
                onChange={handleChange}
              />
            </div>
            <div className="item">
              <label>Avenue</label>
              <input
                placeholder="Avenue..."
                type="text"
                name="avenue"
                value={data.avenue}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="btn">
            <button onClick={() => props.setOpenAdd(false)}>Annuler</button>
            <button onClick={handleSubmit}>Ajouter</button>
            <MyLoadingv3 isLoading={state.isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddUserDialog;
