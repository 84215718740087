import React from "react";
import "./errorBar.scss";
import { ErrorOutlineOutlined } from "@mui/icons-material";

type Props = {
  title: string;
};
export default function ErrorBar(props: Props) {
  return (
    <div className="errorBar">
      <ErrorOutlineOutlined />
      <span className="sp1">{props.title}</span>
    </div>
  );
}
