import React, { useState } from "react";
import "./editProfilDialog.scss";
import { AdminType } from "../../types/UserTypes";
import { requestType } from "../../types/GlobalType";
import SuccessAlert from "../alert/AlerteBar";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Person2Rounded } from "@mui/icons-material";
import MyLoadingv3 from "../loader/Loader_v3";

type Props = {
  data: AdminType | undefined;
  setOpenEdit: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReloadOnUpdate: () => void;
};
const EditProfilDialog = (props: Props) => {
  const [nom, setNom] = useState<string | undefined>(props.data?.nom);
  const [postnom, setpostnom] = useState<string | undefined>(
    props.data?.postnom
  );
  const [prenom, setprenom] = useState<string | undefined>(props.data?.prenom);
  const [telephone, settelephone] = useState<string | undefined>(
    props.data?.telephone
  );
  const [ville, setville] = useState<string | undefined>(props.data?.ville);
  const [avenue, setavenue] = useState<string | undefined>(props.data?.avenue);
  const [quartier, setquartier] = useState<string | undefined>(
    props.data?.quartier
  );

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [successUpdate, setSuccessUpdate] = React.useState<Boolean>(false);

  const navigate = useNavigate();

  const handleUpdate = () => {
    setState({ isLoading: false, errorMessage: null, succussMessage: "" });
    if (
      nom === "" ||
      postnom === "" ||
      prenom === "" ||
      telephone === "" ||
      ville === "" ||
      quartier === "" ||
      avenue === ""
    ) {
      return setState((prev) => ({
        ...prev,
        errorMessage: "veuillez compléter les champs vides",
      }));
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        nom: nom,
        postnom: postnom,
        prenom: prenom,
        telephone: telephone,
        ville: ville,
        quartier: quartier,
        avenue: avenue,
        services_id: props.data?.service_id,
        email: props.data?.email,
      };

      setState({ isLoading: true, errorMessage: null, succussMessage: "" });

      const headers = {
        Authorization: "Bearer " + token,
      };
      axios
        .put(
          URL_BACKEND_APP + "/admin/update.php?id=" + props?.data?.admin_id,
          data,
          {
            headers,
          }
        )
        .then((response) => {
          // console.log(response);
          if (response) {
            setSuccessUpdate(true);
            setState({
              isLoading: false,
              succussMessage: "Modification effectuée",
            });
            props.setOpenEdit(false);
            props.forceReloadOnUpdate();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <>
      <div className="editprofil">
        <div className="dialog">
          <div className="header">
            <div className="title">
              <Person2Rounded className="ico" />
              <span>Modification Profil</span>
            </div>
          </div>
          <div className="body">
            <input
              placeholder="Nom..."
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              disabled
            />
            <input
              placeholder="Post-nom..."
              type="text"
              value={postnom}
              onChange={(e) => setpostnom(e.target.value)}
              disabled
            />
            <input
              placeholder="Prénon..."
              type="text"
              value={prenom}
              onChange={(e) => setprenom(e.target.value)}
              disabled
            />
            {/* <input placeholder="Email..." type="text" value={props.data?.email} /> */}
            <input
              placeholder="Téléphone..."
              type="text"
              value={telephone}
              onChange={(e) => settelephone(e.target.value)}
            />
            {/* <input placeholder="Pays..." type="text" value={props.data?.pays} /> */}
            <input
              placeholder="Ville..."
              type="text"
              value={ville}
              onChange={(e) => setville(e.target.value)}
            />
            <input
              placeholder="Quartier..."
              type="text"
              value={quartier}
              onChange={(e) => setquartier(e.target.value)}
            />
            <input
              placeholder="Avenue..."
              type="text"
              value={avenue}
              onChange={(e) => setavenue(e.target.value)}
            />
          </div>
          <div className="errorMsg">
            {state.errorMessage && <span>{state.errorMessage}</span>}
          </div>
          <div className="btn">
            <button onClick={() => props.setOpenEdit(false)}>Annuler</button>
            <button onClick={handleUpdate}>Modifier</button>
          </div>
        </div>

        {successUpdate && (
          <SuccessAlert
            setOpen={setSuccessUpdate}
            label={"Modification effectuée"}
            theme={"success"}
          />
        )}
      </div>
      {<MyLoadingv3 isLoading={state.isLoading} />}
    </>
  );
};

export default EditProfilDialog;
