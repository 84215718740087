import React from "react";
import "./ordercarddialog.scss";
import { IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";
import poussin from "../../assets/poussin.jpg";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const OrderCardDialog = (props: Props) => {
  return (
    <div className="order_card_dialog">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <span>Liste des commandes</span>
          </div>
          <IconButton
            sx={{ width: "30px", height: "30px" }}
            onClick={() => props.setOpen(false)}
          >
            <Close className="ico" />
          </IconButton>
        </div>

        <div className="body">
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
          <div className="item">
            <div className="content">
              <div className="box_info">
                <span className="main_info">Nature & Catégorie & Type</span>
                <div className="second_info">
                  <span>Poussin</span>
                  <span>Hybride</span>
                  <span>Sasso</span>
                </div>
              </div>
              <div className="box_info">
                <span className="main_info">
                  Quantite & prix unitaire & Prix total
                </span>
                <div className="second_info">
                  <span>20</span>
                  <span>$200</span>
                  <span>$200</span>
                </div>
              </div>
            </div>
            <div className="user_paiement">
              <span className="main_info">Montant payé & Reste à Payer</span>
              <div className="second_info">
                <span>$20</span>
                <span>$200</span>
              </div>
            </div>
            <img src={poussin} alt="avatar_commande" />
            <IconButton sx={{ width: "30px", height: "30px" }}>
              <DeleteForever className="ico" />
            </IconButton>
          </div>
        </div>
        <div className="btn">
          <button>Annuler la commande</button>
          <button>Valider la commande</button>
        </div>
      </div>
    </div>
  );
};

export default OrderCardDialog;
