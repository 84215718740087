import React from "react";
import "./notificationDialog.scss";
import { RequeteType, requestType } from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import { requete_api } from "../../../_service/notification.Call";
import { ERROR_MESSAGE } from "../../../_service/_const";
import { CircularProgress } from "@mui/material";
import { LibraryBooks } from "@mui/icons-material";

type Props = {
  data: RequeteType | undefined;
  setOpenEditDial: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReload: () => void;
};
const EditDialog = (props: Props) => {
  const [requeteData, setRequeteData] = React.useState<RequeteType>({
    question: props.data?.question,
    reponse: props.data?.reponse,
  });

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // console.log(requeteData);

  const navigate = useNavigate();

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });
    if (requeteData.question === "" || requeteData.reponse === "") {
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter tous les champs",
      }));
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        question: requeteData.question,
        reponse: requeteData.reponse,
      };
      setState({ isLoading: true, errorMessage: null });
      requete_api
        .update(props.data?.id, data, token)
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
            });
            setRequeteData({ question: "", reponse: "" });
            props.setSuccess(true);
            props.setOpenEditDial(false);
            props.forceReload();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  return (
    <div className="message_dialog">
      <div className="dialog">
        <div className="header">
          <div className="left">
            <LibraryBooks className="ico" />
            <span>Modifier requête</span>
          </div>
          {!state.isLoading && (
            <div className="Right">
              <button onClick={() => props.setOpenEditDial(false)}>
                Annuler
              </button>
              <button onClick={handleSubmit}>Modifier</button>
            </div>
          )}
          {state.isLoading && (
            <div className="Right_loading">
              <button disabled>Annuler</button>
              <button>
                <CircularProgress size={12} className="circular" />
              </button>
            </div>
          )}
        </div>
        <div className="body">
          <input
            placeholder="requête..."
            type="text"
            value={requeteData.question}
            onChange={(e) =>
              setRequeteData((prev) => ({ ...prev, question: e.target.value }))
            }
          />
          <textarea
            placeholder="Orientation..."
            name=""
            id=""
            cols={30}
            rows={5}
            value={requeteData.reponse}
            onChange={(e) =>
              setRequeteData((prev) => ({ ...prev, reponse: e.target.value }))
            }
          ></textarea>
        </div>
        {state.errorMessage && (
          <span className="errorMsg">{state.errorMessage}</span>
        )}
      </div>
    </div>
  );
};

export default EditDialog;
