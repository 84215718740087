import React from "react";
import "./editMotifDialog.scss";
import { MotifType } from "../../../types/CategoryType";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../../types/GlobalType";
import motif_sortie_api from "../../../_service/motifSortie.Caller";
import { ERROR_MESSAGE } from "../../../_service/_const";

type Props = {
  data: MotifType | undefined;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnUpdate: () => void;
};

const EditMotifDialog = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [motif, setMotif] = React.useState<string | undefined>(
    props.data?.motif_designation
  );

  const navigate = useNavigate();

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });

    if (motif === "")
      return setState({
        ...state,
        errorMessage: "veuillez compléter le champs vide",
      });

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      setState({ isLoading: true, errorMessage: null });

      motif_sortie_api
        .update(
          props.data?.motif_id,
          {
            designation: motif,
          },
          token
        )
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
              succussMessage: "Modification effectuée",
            });
            props.setSuccessUpdate(true);
            props.setOpen(false);
            props.forceUpdateOnUpdate();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <div className="edit_motif">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <span>Modification motif</span>
            {state.errorMessage && (
              <span className="errorMsg">{state.errorMessage}</span>
            )}
          </div>
        </div>

        <div className="body">
          <div className="item">
            {/* <label>Motif sortie</label> */}
            <input
              placeholder="Motif sortie"
              type="text"
              value={motif}
              onChange={(e) => setMotif(e.target.value)}
            />
          </div>
        </div>
        <div className="btn">
          <button onClick={() => props.setOpen(false)}>Annuler</button>
          {!state.isLoading && <button onClick={handleSubmit}>Valider</button>}
          {state.isLoading && (
            <button>
              <CircularProgress size={15} className="circular" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditMotifDialog;
