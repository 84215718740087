import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { OrderType } from "../../../types/GlobalType";
import {
  ORDER_STATUS_,
  STATUS_ORDER,
  STATUS_ORDER_ID,
} from "../../../_service/_const";
import exportFromJSON from "export-from-json";
import { useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { AttachFile } from "@mui/icons-material";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const orders = useSelector((state: RootState) => state.order.oeufOrder);
  let sortedData = _.orderBy(orders, ["date"], ["desc"]);

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  const handleClick = () => {
    const fileName = "NAGRITECH_Commande_Oeuf";
    const exportType = "xls";
    const fields = [
      "nom_entreprise",
      "titre",
      "nom",
      "postnom",
      "prenom",
      "nature_designation",
      "quantite",
      "prixunitaire",
      "prixtotal",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
    ];
    exportFromJSON({ data: orders, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef.current;
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  // ----------------------------------------------

  if (orders.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-4">Id</div>
            <div className="col col-3">Produit</div>
            <div className="col col-3">Date</div>
            <div className="col col-4">Qnté</div>
            <div className="col col-4">Montant</div>
            <div className="col col-2">Client </div>
            <div className="col col-4">Status</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-4">Id</div>
          <div className="col col-3">Produit</div>
          <div className="col col-3">Date</div>
          <div className="col col-4">Qnté</div>
          <div className="col col-4">Montant</div>
          <div className="col col-2">Client </div>
          <div className="col col-4">Status</div>
        </li>

        {sortedData
          .filter(
            (item: OrderType) =>
              item?.nom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.nature_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.date
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.prenom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.nom_entreprise
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((order: OrderType, index: number) => {
            let statusCmd: string = "";
            switch (order.statusCmd_id) {
              case STATUS_ORDER_ID.ETABLI:
                statusCmd = STATUS_ORDER.ETABLI;
                break;
              case STATUS_ORDER_ID.ETABLIE_AVEC_PAIEMENT:
                statusCmd = STATUS_ORDER.ETABLIE_AVEC_PAIEMENT;
                break;
              case STATUS_ORDER_ID.RESERVE:
                statusCmd = STATUS_ORDER.RESERVE;
                break;
              case STATUS_ORDER_ID.REGLE:
                statusCmd = STATUS_ORDER.REGLE;
                break;
              case STATUS_ORDER_ID.ANNULE:
                statusCmd = STATUS_ORDER.ANNULE;
                break;

              default:
                break;
            }
            return (
              <Link to={`/orders/${order.nom}`} state={order}>
                <li className="table-row" key={index}>
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-3" data-label="Nature">
                    {order.nature_designation}
                  </div>
                  <div className="col col-4" data-label="Date commande">
                    {moment(order.date).format("DD-MM-YYYY")}
                  </div>
                  <div className="col col-4" data-label="Quantité">
                    {order.quantite}
                  </div>

                  <div className="col col-4" data-label="Prix">
                    {order.prixtotal} {order.devise}{" "}
                  </div>
                  <div className="col col-2 Ese" data-label="Client">
                    {!order?.is_legal_person ? (
                      `${order.prenom} ${order.nom}`
                    ) : (
                      <>
                        {order.nom_entreprise}
                        <span className="container-Ese">
                          <span className="Ese">e</span>
                        </span>
                      </>
                    )}
                  </div>
                  <div
                    className="col col-4 statusContainer"
                    data-label="Status"
                  >
                    {/* handle status */}
                    {order.statusCmd_id === STATUS_ORDER_ID.ETABLI && (
                      <span className="status pending ">Etablie</span>
                    )}
                    {order.statusCmd_id === STATUS_ORDER_ID.RESERVE && (
                      <span className="status reserved ">
                        Réservé<em></em>
                      </span>
                    )}
                    {order.statusCmd_id ===
                      STATUS_ORDER_ID.ETABLIE_AVEC_PAIEMENT && (
                      <span className="status pending ">Etablie</span>
                    )}
                    {order.statusCmd_id === STATUS_ORDER_ID.REGLE && (
                      <span className="status validate ">Réglée</span>
                    )}
                    {order.statusCmd_id ===
                      STATUS_ORDER_ID.ETABLIE_AVEC_DETTE && (
                      <span className="status dette ">Dette</span>
                    )}
                    {order.statusCmd_id === STATUS_ORDER_ID.ANNULE && (
                      <span className="status canceled ">Annulée</span>
                    )}
                    {/* handle joined icos */}
                    {!!order.paiement_img && <AttachFile className="ico" />}

                    {/* handle badge */}
                    {order.prixtotal === order.montant &&
                      order.statusCmd_id !== STATUS_ORDER_ID.REGLE &&
                      order.statusCmd_id !== STATUS_ORDER_ID.ANNULE && (
                        <span className="iconoir--twitter-verified-badge_rouge"></span>
                      )}
                    {order.statusCmd_id === STATUS_ORDER_ID.REGLE && (
                      <span className="iconoir--twitter-verified-badge"></span>
                    )}
                  </div>
                </li>
              </Link>
            );
          })}
      </ul>
    </Box>
  );
}
