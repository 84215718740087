import React from "react";
import List from "./List";
import "./supplieroeuf.scss";
import SearchBar from "../../search/Search";

const SupplierOeuf = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");

  return (
    <div className="table_supplier_poussin">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        {/* <div className="btns">
          <span className="status validate">Active 10</span>
          <span className="status pending">Non active 100</span>
        </div> */}
      </div>
      <List searchValue={searchValue} />
    </div>
  );
};

export default SupplierOeuf;
