import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { OrderType, orderSpecType } from "../../types/GlobalType";

interface OrderInitialState{
  allOrder: OrderType[],
  alimentOrder: orderSpecType[],
  biogazOrder: orderSpecType[],
  poussinOrder: orderSpecType[],
  oeufOrder: orderSpecType[],
  pouleOrder: orderSpecType[],
  pouletOrder: orderSpecType[],
}

const initialState:OrderInitialState = {
  allOrder: [],
  poussinOrder: [],
  biogazOrder: [],
  alimentOrder: [],
  oeufOrder: [],
  pouleOrder: [],
  pouletOrder: [],
};
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    addAllOrder: (state, action:PayloadAction<OrderType []>) => {
      state.allOrder=Object.assign([], action.payload)
    },
    addPoussinOrder: (state, action:PayloadAction<orderSpecType [] | any >) => {
      state.poussinOrder=Object.assign([], action.payload)
    },
    addBiogazOrder: (state, action:PayloadAction<orderSpecType [] | any>) => {
      state.biogazOrder=Object.assign([], action.payload)
    },
    addAlimentOrder: (state, action:PayloadAction<orderSpecType [] | any>) => {
      state.alimentOrder=Object.assign([], action.payload)
    },
    addOeufOrder: (state, action:PayloadAction<orderSpecType [] | any>) => {
      state.oeufOrder=Object.assign([], action.payload)
    },
    addPouleOrder: (state, action:PayloadAction<orderSpecType [] | any>) => {
      state.pouleOrder=Object.assign([], action.payload)
    },
    addPouletOrder: (state, action:PayloadAction<orderSpecType [] | any>) => {
      state.pouletOrder=Object.assign([], action.payload)
    },
  },
});

export const {
  addAllOrder,
  addPoussinOrder,
  addOeufOrder,
  addAlimentOrder,
  addBiogazOrder,
  addPouleOrder,
  addPouletOrder

} = orderSlice.actions;

export default orderSlice.reducer;
