import React from "react";
import "./recentorder.scss";
import { ArrowForwardIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { orderSpecType, OrderType, requestType } from "../../types/GlobalType";
import { order_api } from "../../_service/order.Caller";
import { ERROR_MESSAGE } from "../../_service/_const";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  addAlimentOrder,
  addAllOrder,
  addBiogazOrder,
  addOeufOrder,
  addPouleOrder,
  addPouletOrder,
  addPoussinOrder,
} from "../../redux/orderSlice/orderSlice";
import moment from "moment";
import { Skeleton } from "@mui/material";
import ConnexionError from "../errorComp/Connexion";
import _ from "lodash";
import NoData from "../errorComp/NoData";

const RecentOrder = () => {
  const navigate = useNavigate();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const orders = useSelector((state: RootState) => state.order);

  let orderedData: OrderType[] | orderSpecType[] = [];

  orderedData = _.orderBy(orders.allOrder, ["created_at"], ["desc"]);

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let [
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
        ] = await order_api.clientOrder.getAllByService(token);

        let axios_Fake_Objet = {
          config: null,
          data: { data: [], message: "Situation Commande is Empty" },
          status: 205,
          statusText: "OK",
        };
        if (dataAliment.data.data === undefined)
          dataAliment = Object.create(axios_Fake_Objet);

        if (dataBio.data.data === undefined)
          dataBio = Object.create(axios_Fake_Objet);

        if (dataOeuf.data.data === undefined)
          dataOeuf = Object.create(axios_Fake_Objet);

        if (dataPoussin.data.data === undefined)
          dataPoussin = Object.create(axios_Fake_Objet);

        if (dataPoule.data.data === undefined)
          dataPoule = Object.create(axios_Fake_Objet);

        if (dataPoulet.data.data === undefined)
          dataPoulet = Object.create(axios_Fake_Objet);

        const mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
          ...dataPoule.data.data,
          ...dataPoulet.data.data,
        ];

        // console.log(dataAliment);
        // console.log(dataPoussin);
        // console.log(dataOeuf);
        // console.log(mergedData);

        dispatch(addAllOrder(mergedData));
        dispatch(addPoussinOrder(dataPoussin.data.data));
        dispatch(addOeufOrder(dataOeuf.data.data));
        dispatch(addAlimentOrder(dataAliment.data.data));
        dispatch(addBiogazOrder(dataBio.data.data));
        dispatch(addPouleOrder(dataPoule.data.data));
        dispatch(addPouletOrder(dataPoulet.data.data));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent---OOOO----");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const skeletons = new Array(7).fill(
    <li>
      <div className="item">
        <div className="left">
          <span>
            <Skeleton />
          </span>
          <span>
            <Skeleton />
          </span>
        </div>
        <Skeleton />
      </div>
    </li>
  );

  React.useEffect(() => {
    fetchAll();
  }, []);

  if (orderedData.length < 1) {
    return (
      <div className="recent_order">
        <div className="content">
          <h4>Commandes récentes</h4>
          <ul>
            <li>
              <NoData />
            </li>
          </ul>
        </div>
      </div>
    );
  }

  return (
    <div className="recent_order">
      <div className="content">
        <h4>Commandes récentes</h4>
        <ul>
          {state.isLoading
            ? skeletons
            : orderedData
                .slice()
                // .sort((a: any, b: any) => {
                //   let dataA: any = new Date(a.date).getTime();
                //   let dateB: any = new Date(b.date).getTime();
                //   return dataA - dateB;
                // })
                // .reverse()
                .slice(0, 7)
                .map((item: OrderType, index: number) => (
                  <li key={index}>
                    <Link to={`/orders/${item.nom}`} state={item}>
                      <div className="item">
                        <div className="left">
                          <span>Commande {item.nature_designation}</span>
                          <span>
                            Quantite: {item.quantite} -{" "}
                            {moment(item.date).format("Do MMMM, YYYY")}
                          </span>
                        </div>{" "}
                        <div className="plus_btn">
                          <ArrowForwardIos className="ico" />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}

          {state.errorMessage === ERROR_MESSAGE.networkError && (
            <ConnexionError title="Erreur de connexion" subTitle="" />
          )}
        </ul>
      </div>
      <Link to="/orders/list">
        <div className="footer">
          <span>Toutes les commandes</span>
          <ArrowForwardIosOutlined className="ico" />
        </div>
      </Link>
    </div>
  );
};

export default RecentOrder;
