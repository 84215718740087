import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { entreeType, sortieType } from "../../types/GlobalType";

interface SortieInitialState{
  allSortie:entreeType[]
  alimentSortie: entreeType[],
  biogazSortie: entreeType[],
  poussinSortie: entreeType[],
  oeufSortie: entreeType[],
  pouleSortie: entreeType[],
  pouletSortie: entreeType[],
}

const initialState:SortieInitialState = {
  allSortie: [],
  poussinSortie: [],
  alimentSortie: [],
  biogazSortie: [],
  oeufSortie: [],
  pouleSortie: [],
  pouletSortie: [],

};
const sortieStockSlice = createSlice({
  name: "entreeStock",
  initialState,
  reducers: {

    addAllSortie: (state, action:PayloadAction<sortieType [] | any >) => {
      state.allSortie=Object.assign([], action.payload)
    },
    addPoussinSortie: (state, action:PayloadAction<sortieType [] | any >) => {
      state.poussinSortie=Object.assign([], action.payload)
    },
    addBiogazSortie: (state, action:PayloadAction<sortieType [] | any>) => {
      state.biogazSortie=Object.assign([], action.payload)
    },
    addAlimentSortie: (state, action:PayloadAction<sortieType [] | any>) => {
      state.alimentSortie=Object.assign([], action.payload)
    },
    addOeufSortie: (state, action:PayloadAction<sortieType [] | any>) => {
      state.oeufSortie=Object.assign([], action.payload)
    },
    addPouleSortie: (state, action:PayloadAction<sortieType [] | any>) => {
      state.pouleSortie=Object.assign([], action.payload)
    },
    addPouletSortie: (state, action:PayloadAction<sortieType [] | any>) => {
      state.pouletSortie=Object.assign([], action.payload)
    },
  },
});

export const {
  addAllSortie,
  addPoussinSortie,
  addOeufSortie,
  addAlimentSortie,
  addBiogazSortie,
  addPouleSortie,
  addPouletSortie

} = sortieStockSlice.actions;

export default sortieStockSlice.reducer;
