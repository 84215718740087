import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { SupplierType } from "../../../types/UserTypes";
import _ from "lodash";
import { useEffect } from "react";
import exportFromJSON from "export-from-json";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const suppliers = useSelector(
    (state: RootState) => state.supplier.allSupplier
  );

  let biogazSuppliers: SupplierType[] | any = [];

  if (suppliers?.length > 0) {
    biogazSuppliers = _.filter(suppliers, { cat_produit_id: 2 });
  }

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  const handleClick = () => {
    const fileName = "NAGRITECH_Fournisseur_Biogaz";
    const exportType = "xls";
    const fields = ["id", "nom", "postnom", "pays", "ville", "telephone"];
    exportFromJSON({ data: biogazSuppliers, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef.current;
    if (button) {
      // console.log("Adding event listener");
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        // console.log("Removing event listener");
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  // ----------------------------------------------

  if (biogazSuppliers?.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-2">Fournisseur </div>
            <div className="col col-3">Contact</div>
            {/* <div className="col col-4">Création</div> */}
            <div className="col col-4">Pays</div>
            <div className="col col-4">Ville</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-2">Fournisseur </div>
          <div className="col col-3">Contact</div>
          {/* <div className="col col-4">Création</div> */}
          <div className="col col-4">Pays</div>
          <div className="col col-4">Ville</div>
        </li>

        {biogazSuppliers
          ?.filter(
            (item: SupplierType) =>
              item?.nom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.postnom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.telephone
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((supplier: any, index: number) => (
            <Link to={`/suppliers/${2}`} state={supplier}>
              <li className="table-row" key={index}>
                <div className="col col-3" data-label="Id">
                  {`0${index + 1}`}
                </div>
                <div className="col col-2" data-label="supplier Name">
                  {`${supplier.nom} ${supplier.postnom} ${supplier.prenom}`}
                </div>
                <div className="col col-3" data-label="Contact">
                  {supplier.telephone}
                </div>
                {/* <div className="col col-4" data-label="Service">
              {supplier.created_at}
            </div> */}
                <div className="col col-4" data-label="Genre">
                  {supplier.pays}
                </div>
                <div className="col col-4" data-label="Status">
                  {supplier?.ville}
                </div>
              </li>
            </Link>
          ))}
      </ul>
    </Box>
  );
}
