import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

const API_CALLER = axios.create({
  baseURL: URL_BACKEND_APP + "/fournisseur",
});

const config = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
  },
});

const supplier_api = {
  create: (data, token) => {
    return API_CALLER.post("/createMoral.php", data, config(token));
  },

  getAll: (token) => {
    return API_CALLER.get(`/read.php`, config(token));
  },

  getById: (id, token) => {
    return API_CALLER.get(`/readById.php?id=${id}`, config(token));
  },

  // update: (id, data, token) => {
  //   return API_CALLER.put("/update.php?id=" + id, data, config(token));
  // },
  update: (id, data, token) => {
    return API_CALLER.put("/updateMoral.php?id=" + id, data, config(token));
  },

  uploadLogo: (id, token) => {
    return API_CALLER.put(`/uploadLogo.php?id=${id}`, config(token));
  },
};

export default supplier_api;
