// import { Box } from "@mui/material";
import "./dialogList.scss";
import { Link } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { incubationType } from "../../../types/GlobalType";
// import { ORDER_STATUS_ } from "../../../_service/_const";
import { Close, ListAlt } from "@mui/icons-material";
import moment from "moment";

interface Props {
  // searchValue: string;
  incubation: incubationType[] | any;
  status: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function List(props: Props) {
  const { incubation } = props;
  if (incubation.length < 1) {
    return (
      <div className="dialog_list_">
        <div className="content">
          <div className="titleHeader">
            <div className="left_side">
              <ListAlt />
              <span>Incubation {props.status}</span>
            </div>
            <div className="closeBtn" onClick={() => props.setOpen(false)}>
              <Close className="ico" />
            </div>
          </div>
          <ul className="responsive-table">
            <li className="table-header">
              <div className="col col-3">Id</div>
              <div className="col col-3">Nature</div>
              <div className="col col-3">Qte </div>
              <div className="col col-3">Date entrée</div>
              <div className="col col-3">Date sortie</div>
              <div className="col col-3">Durée</div>
              <div className="col col-4">Status</div>
            </li>
            <li className="table-row body">
              <NoData />
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="dialog_list_">
      <div className="content">
        <div className="titleHeader">
          <div className="left_side">
            <ListAlt />
            <span>Incubation {props.status}</span>
          </div>
          <div className="closeBtn" onClick={() => props.setOpen(false)}>
            <Close className="ico" />
          </div>
        </div>
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-3">Nature</div>
            <div className="col col-3">Qte </div>
            <div className="col col-3">Date entrée</div>
            <div className="col col-3">Date sortie</div>
            <div className="col col-3">Durée</div>
            <div className="col col-4">Status</div>
          </li>

          {incubation
            // .filter((item: OrderType) =>
            //   item?.nom?.toLowerCase().includes(props.searchValue.toLowerCase())
            // )
            .map((item: incubationType, index: number) => {
              let now = moment();
              let dateOne = moment(item.dateEntree);
              let dateTwo = moment(now);
              let dayDiff: number = dateTwo.diff(dateOne, "day");

              if (dayDiff > 21) {
                dayDiff = 21;
              }

              return (
                <li className="table-row body" key={index}>
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-3" data-label="Nature">
                    {item.nature_designation}
                  </div>
                  <div className="col col-3" data-label="Quantité">
                    {item.quantite}
                  </div>
                  <div className="col col-3" data-label="Date entrée">
                    {moment(item.dateEntree).format("L")}
                  </div>
                  <div className="col col-3" data-label="Date sortie">
                    {item.dateSortie
                      ? moment(item.dateSortie).format("L")
                      : "--"}
                  </div>
                  <div className="col col-3" data-label="Durée effectuée">
                    {dayDiff}/21 jrs
                  </div>
                  <div className="col col-4" data-label="Status">
                    {item.status_inc_designation}
                  </div>
                </li>
              );
              // </Link>
            })}
        </ul>
      </div>
    </div>
  );
}
