import React from "react";
import "./updatepsw.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { requestType } from "../../../types/GlobalType";
import { PhoneAndroidOutlined, VisibilityOutlined } from "@mui/icons-material";
import { APP_CONST, ERROR_MESSAGE } from "../../../_service/_const";
import { useNavigate } from "react-router-dom";
import MyLoading_v3 from "../../../components/loader/Loader_v3";
import ConnexionBar from "../../../components/errorComp/connexionBar";
import admin_api from "../../../_service/admin.Caller";

type Props = {
  client_id: number;
  client_num: string;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  setOpenSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
};
const UpdatePswd = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdate = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      // console.log(password, props.client_id);
      // console.log(password?.length);

      try {
        await admin_api.resetClientPsw(props.client_id, token);

        props.setOpenSuccess(true);
        props.setOpen(false);
        setState({
          isLoading: false,
          errorMessage: null,
        });
      } catch (error: any) {
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <>
      {state.isLoading && <MyLoading_v3 isLoading={true} />}
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="update_pswd">
        <div className="dialog">
          <div className="header">
            <span>Réinitialisation mot de passe</span>
            <IconButton onClick={() => props.setOpen(false)}>
              <Close className="ico" />
            </IconButton>
          </div>
          <div className="body">
            <div className="item">
              <div className="label">
                <PhoneAndroidOutlined className="ico" />
                <span>{props.client_num}</span>
              </div>
            </div>
            <div className="item ">
              <div className="label">
                <div className="inputContainer">
                  <input
                    disabled
                    type="text"
                    placeholder={`Default pswd: ${APP_CONST.defaultPassword}`}
                  />

                  <VisibilityOutlined className="icoPas" />
                </div>
              </div>
            </div>
            <button onClick={handleUpdate}>Réinitialiser</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePswd;
