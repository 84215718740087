import React, { useState } from "react";
import "./messageDetail.scss";
import {
  Attachment,
  Close,
  DeleteOutlineOutlined,
  Done,
  Image,
  MessageOutlined,
  Reply,
  UploadFile,
  UploadFileSharp,
} from "@mui/icons-material";
import { NotificationType, requestType } from "../../types/GlobalType";
import "moment/locale/fr";
import { capitalizeFirstLetter } from "../../_utils/string_utils";
import moment from "moment";
import "moment/locale/fr";
import Delete from "../dialog/Delete";
import { notification_api } from "../../_service/notification.Call";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import AttachedFile from "./attachFile/AttachedFile";
import { Tooltip } from "@mui/material";
import MyLoadingv3 from "../loader/Loader_v3";
import axios from "axios";

interface IFile {
  url: string;
  name: string;
}

type Props = {
  setOpenDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessAddImage: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessDelete: React.Dispatch<React.SetStateAction<Boolean>>;
  data: NotificationType;
  forceReload: () => void;
};
const MessageDetail = (props: Props) => {
  const [openDelete, setOpenDelete] = React.useState<Boolean>(false);
  const [openFile, setOpenFile] = React.useState<boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleDelete = (id: string | number | undefined) => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      notification_api
        .delete(id, token)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setLoadingDeleteBtn(false);
            setOpenDelete(false);
            props.setOpenDetail(false);
            props.setSuccessDelete(true);
            props.forceReload();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          setLoadingDeleteBtn(false);
          if (error.response) {
            if (error.response.status === 422) {
              setOpenDelete(false);
              return setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  //UPLOAD FILE

  const [file, setfile] = useState<File | null>(null);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      // console.log(files, "Files---------SSSS");
      // console.log(files[0], "Files---------INDEX 0");
      setfile(files[0]);
    }
  };

  const upload = () => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      let formData = new FormData();
      if (file) {
        setState({
          isLoading: true,
          errorMessage: null,
        });

        formData.append("image", file);
        formData.append("name", "MyNotifImage");
        axios({
          url:
            URL_BACKEND_APP +
            "/notification/uploadImage.php?id=" +
            props.data.id,
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          data: formData,
        }).then(
          (res) => {
            // console.log(res, "RESSSSSSSSSSSSSULT");

            setState({
              isLoading: false,
              errorMessage: null,
            });

            setfile(null);
            props.setOpenDetail(false);
            props.setSuccessAddImage(true);
            props.forceReload();
          },
          (error) => {
            // console.log(error, "ERRRRRRRRRRRRRRROR");
            setState({
              isLoading: false,
              errorMessage: "Vérifier votre connexion et actualiser",
            });

            // setfile(undefined);

            if (error.message === ERROR_MESSAGE.networkError) {
              return setState({
                isLoading: false,
                errorMessage: ERROR_MESSAGE.networkError,
              });
            }
            if (error.response) {
              if (error.response.status === 403) {
                localStorage.clear();
                return navigate("/login");
              }
              if (error.response.status === 405) {
                localStorage.clear();
                return navigate("/login");
              }
            }
          }
        );
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const handleUpload = () => {
    // setProgress(0);
    // if (!currentFile) return;

    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const formData = new FormData();
      if (file) {
        formData.append("file_notification", file);
        formData.append("name", file.name);
        try {
          let response = notification_api.uploadImage(
            props.data?.id,
            token,
            formData
          );
          // console.log("UPLOADED");
          // console.log(response);

          setState({
            isLoading: false,
            errorMessage: null,
          });
          // console.log(response);
          // props.setOpenSuccess(true);
          // props.forceReloadOnUpdate();
        } catch (error: any) {
          // console.log(error);
          setState({
            isLoading: false,
            errorMessage: "Vérifier votre connexion et actualiser",
          });

          // setfile(undefined);

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
            if (error.response.status === 405) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  return (
    <>
      {<MyLoadingv3 isLoading={state.isLoading} />}

      <div className="message_dialog">
        <div className="dialog">
          <div className="header">
            <div className="header-left">
              <MessageOutlined className="ico" />
              {" Message client"}
            </div>
            <Close className="ico" onClick={() => props.setOpenDetail(false)} />
          </div>
          <div className="body_detail">
            <div className="title">
              <span>OBJET: {capitalizeFirstLetter(props.data?.titre)}</span>
              <div className="title_right">
                {props.data?.image_news && (
                  <Tooltip title="Image jointe">
                    <Attachment
                      className="ico"
                      onClick={() => setOpenFile(true)}
                    />
                  </Tooltip>
                )}
                <span>{moment(props.data?.date).format("lll")}</span>
              </div>
            </div>
            <div className="container">
              {capitalizeFirstLetter(props.data?.description)}
            </div>
          </div>
          {state.errorMessage && (
            <span className="errorMsg">{state.errorMessage}</span>
          )}
          <div className="footer">
            <div className="footer_left">
              <div className="deleteBtn">
                <label>
                  <Tooltip title="Supprimer la notification">
                    <DeleteOutlineOutlined
                      className="ico"
                      onClick={() => setOpenDelete(true)}
                    />
                  </Tooltip>
                </label>
              </div>
              {!!props.data?.image_news ? (
                <div className="updateImage">
                  <label htmlFor="myInput">
                    <Tooltip title="Modifier l'image">
                      <UploadFileSharp className="ico" />
                    </Tooltip>
                  </label>
                  <input
                    type="file"
                    id="myInput"
                    hidden
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg"
                  />
                </div>
              ) : (
                <div className="addImage">
                  <label htmlFor="myInput">
                    <Tooltip title="Joindre une image">
                      <UploadFile className="ico" />
                    </Tooltip>
                  </label>
                  <input
                    type="file"
                    id="myInput"
                    hidden
                    onChange={handleChangeFile}
                    accept="image/png, image/jpeg"
                  />
                </div>
              )}

              {/* {file?.name.slice(0, 10)} */}
              {file && (
                <>
                  <Image color="success" />
                  ...
                  <div className="image_btn">
                    <div
                      className="cancel"
                      onClick={() => props.setOpenDetail(false)}
                    >
                      <Reply className="ico" />
                      <span>Annuler</span>
                    </div>
                    <div className="update" onClick={upload}>
                      <Done className="ico" />
                      <span>Ajouter</span>
                    </div>
                  </div>
                </>
              )}
            </div>

            <span className="moment">{moment(props.data?.date).fromNow()}</span>
          </div>
        </div>
      </div>

      {openFile && (
        <AttachedFile
          data={props.data?.titre}
          setOpen={setOpenFile}
          img={props.data?.image_news}
        />
      )}

      {openDelete && (
        <Delete
          setOpen={setOpenDelete}
          id={props.data?.id}
          label={"Voulez-vous vraiment supprimer cette catégorie?"}
          handleDelete={handleDelete}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
    </>
  );
};

export default MessageDetail;
