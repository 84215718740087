import React from "react";
import "./recentorder.scss";
import { ArrowForwardIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";
import moment from "moment";
import { entreeType } from "../../types/GlobalType";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import NoData from "../errorComp/NoData";

const RecentOrder = () => {
  const entrees = useSelector(
    (state: RootState) => state.entreeStock.allEntree
  );

  return (
    <>
      {entrees.length > 0 ? (
        <div className="recent_order">
          <div className="content">
            <h4>Entrées récentes</h4>
            <ul>
              {entrees
                .slice()
                .sort((a: any, b: any) => {
                  let dataA: any = new Date(a.date).getTime();
                  let dateB: any = new Date(b.date).getTime();
                  return dataA - dateB;
                })
                .reverse()
                .slice(0, 7)
                .map((item: entreeType, index: number) => (
                  <li key={index}>
                    <div className="item">
                      <div className="left">
                        <span>Commande {item.nature_designation}</span>
                        <span>
                          Quantite: {item.quantite}-{" "}
                          {moment(item.date).format("Do MMMM, YYYY")}
                        </span>
                      </div>
                      <Link
                        to={`/orders-suppliers/${item.entree_id}`}
                        state={item}
                      >
                        {" "}
                        <div className="plus_btn">
                          <ArrowForwardIos className="ico" />
                        </div>
                      </Link>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <Link to="/orders-suppliers/list">
            <div className="footer">
              <span>Toutes les commandes</span>
              <ArrowForwardIosOutlined className="ico" />
            </div>
          </Link>
        </div>
      ) : (
        <>
          <div className="recent_order">
            <div className="content">
              <h4>Entrées récentes</h4>
              <NoData />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecentOrder;
