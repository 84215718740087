import React, { useState } from "react";
import "./systeme.scss";
// import { ObjectType } from '../../../pages/notifications/Notifications';
import { notificationData } from "../../../data/data";
import { IconButton } from "@mui/material";
import { Add, ArrowDropDown, ArrowDropUpRounded } from "@mui/icons-material";
import moment from "moment";
import "moment/locale/fr";

const Systeme = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [accordion, setAccordion] = useState<number>(-1);

  // const [data, setData] = useState<ObjectType>()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccordion = (value: number) => {
    if (value === accordion) {
      setAccordion(-1);
      return;
    }
    setAccordion(value);
  };

  return (
    <div className="systeme_notification">
      {notificationData.map((item, index) => {
        return (
          <div
            className="box_rqt"
            key={index}
            onClick={() => handleAccordion(index)}
          >
            <div className="rqt">
              <div className="left">
                <span className="text">{item.question}</span>
                <span className="moment">{moment(item.date).fromNow()}</span>
              </div>

              <div className="icons">
                <div className={item.lecture ? "" : "unread"}></div>
                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {accordion === index ? (
                      <ArrowDropUpRounded className="icon" />
                    ) : (
                      <ArrowDropDown className="icon" />
                    )}
                  </IconButton>
                </div>
              </div>
            </div>
            <div
              className={
                accordion === index ? "response animation" : "inactive"
              }
            >
              <p className="text">{item.answer}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Systeme;
