import React from "react";
import "./cardthree.scss";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Skeleton } from "@mui/material";

type PropsType = {
  isLoading: boolean;
};

const CardThree = (props: PropsType) => {
  const { allOrder } = useSelector((state: RootState) => state.order);
  return (
    <>
      {props.isLoading ? (
        <div className="card_three">
          <div className="card_info">
            <Skeleton height={80} width={80} variant="rounded" />
          </div>
          <Skeleton width={50} height={50} variant="rounded" />
        </div>
      ) : (
        <div className="card_three">
          <Link to="/orders/list">
            <div className="card_info">
              <span>Commande</span>
              <h1>{allOrder.length}</h1>
              <span>Tous</span>
            </div>
          </Link>
          <Link to="/orders/list">
            <div className="card_icon">
              <div className="wraper">
                <ViewInArOutlinedIcon className="ico" />
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default CardThree;
