import React from "react";
import "./clientall.scss";
import SearchBar from "../../search/Search";
import List from "./List";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { Business, PersonOutline } from "@mui/icons-material";

const ClientAll = () => {
  // const [data, setData] = React.useState<ClientType[]>([]);

  const [searchValue, setSearchValue] = React.useState<string>("");

  const { allClient } = useSelector((state: RootState) => state.client);

  let entreprise: number = 0;
  let individu: number = 0;

  if (allClient) {
    for (let item of allClient) {
      if (!!item.is_legal_person) {
        entreprise++;
      }
    }
    individu = allClient.length - entreprise;
  }

  return (
    <div className="table_client_all">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        <div className="btns">
          <div className="status pending">
            <PersonOutline className="ico" />
            <span>Induvidus {individu}</span>
          </div>
          <div className="status validate">
            <Business className="ico" />
            <span>Entreprises {entreprise}</span>
          </div>
        </div>
      </div>
      <List searchValue={searchValue} />
    </div>
  );
};

export default ClientAll;
