import React, { useReducer, useRef } from "react";
import "./supplier.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import ConnexionError from "../../components/errorComp/Connexion";
import { ERROR_MESSAGE } from "../../_service/_const";
import { RotatingLines } from "react-loader-spinner";
// import NoData from "../../components/errorComp/NoData";
import { SupplierType } from "../../types/UserTypes";
import { requestType } from "../../types/GlobalType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import supplier_api from "../../_service/supplier.Caller";
import {
  addAllSupplier,
  // addAlimentSupplier,
  // addBiogazSupplier,
  // addOeufSupplier,
  // addOtherSupplier,
} from "../../redux/supplierSlice/supplierSlice";
// import _ from "lodash";
import { IconButton } from "@mui/material";
import { AccountCircleOutlined, Add } from "@mui/icons-material";
import NewSupplier from "./NewSupplier";
import SuccessAlert from "../../components/alert/AlerteBar";
import { ProtectedSectionForAdmin_Log } from "../../_service/WithPermission";

const Supplier = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("suppliers");

  const [data, setData] = React.useState<SupplierType[]>([]);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const [openAddNew, setOpenAddNew] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const suppliers = useSelector((state: RootState) => state.supplier);
  const navigate = useNavigate();
  const [successCreate, setSuccessCreate] = React.useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const [successUpdateSupplier, setSuccessUpdateSupplier] =
    React.useState<Boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // let oeufSuppliers: any = [];
  // let alimentSuppliers: any = [];
  // let biogazSuppliers: any = [];

  // if (suppliers.allSupplier.length > 0) {
  //   alimentSuppliers = _.filter(suppliers.allSupplier, { cat_produit_id: 1 });
  //   biogazSuppliers = _.filter(suppliers.allSupplier, { cat_produit_id: 2 });
  //   oeufSuppliers = _.filter(suppliers.allSupplier, { cat_produit_id: 3 });
  // }

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await supplier_api.getAll(token);
        dispatch(addAllSupplier(response.data.data));
        setData((prev) => [...prev, response.data.data]);

        // for (let item of response.data.data) {
        //   if (item.services_id === 2) {
        //     dispatch(addOeufSupplier(item));
        //   } else if (item.services_id === 3) {
        //     dispatch(addAlimentSupplier(item));
        //   } else if (item.services_id === 9) {
        //     dispatch(addBiogazSupplier(item));
        //   } else {
        //     dispatch(addOtherSupplier(item));
        //   }
        // }

        setState({
          errorMessage: "",
          isLoading: false,
          succussMessage: "success",
        });
      } catch (error: any) {
        // console.log("This is error concurrent____sup");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnInsert]);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        <span>Chargement...</span>
      </div>
    );
  }

  // if (state.succussMessage === "success" && data.length <= 0) return <NoData />;

  return (
    <div className="supplier_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff", cursor: "inherit" }}
            // onClick={() => navigate(-1)}
          >
            <AccountCircleOutlined className="ico" />
          </IconButton>
          <h4>
            Fournisseurs
            {/* {urlRoute[2] === "fns-pss" && CATEGORY_NATURE.poussin}
            {urlRoute[2] === "fns-bgz" && CATEGORY_NATURE.biogaz}
            {urlRoute[2] === "fns-alm" && CATEGORY_NATURE.aliment}
            {urlRoute[2] === "fns-cf" && CATEGORY_NATURE.chambre_froide} */}
          </h4>
        </div>
        <div className="right">
          <ProtectedSectionForAdmin_Log>
            <div className="btn_commande" onClick={() => setOpenAddNew(true)}>
              <Add className="ico" />
              <span>Nouveau Fournisseur</span>
            </div>
            <span className="ico_sm" onClick={() => setOpenAddNew(true)}>
              <Add className="ico_sm_ico" />
            </span>
          </ProtectedSectionForAdmin_Log>
          <button ref={buttonRef}>
            <VerticalAlignBottomOutlinedIcon className="ico" />
            <span>Exporter XLSX</span>
          </button>
          {/* <button ref={buttonRef} className="ico_sm">
            <VerticalAlignBottomOutlinedIcon className="ico_sm_ico" />
          </button> */}
        </div>
      </div>

      <div className="options">
        <Link to="/suppliers">
          <div
            className={urlRoute[2] === "suppliers" ? "option_active" : "option"}
          >
            <span>Tous</span>
            <span className="count">{suppliers?.allSupplier.length}</span>
            <div className="underline1"></div>
          </div>
        </Link>
        {/* <Link to="/suppliers/fns-pss">
          <div
            className={urlRoute[2] === "fns-pss" ? "option_active" : "option"}
          >
            <span>Oeufs</span>
            <span className="count">{oeufSuppliers?.length}</span>
            <div className="underline2"></div>
          </div>
        </Link>
        <Link to="/suppliers/fns-bgz">
          <div
            className={urlRoute[2] === "fns-bgz" ? "option_active" : "option"}
          >
            <span>Biogaz</span>
            <span className="count">{biogazSuppliers?.length}</span>
            <div className="underline3"></div>
          </div>
        </Link>
        <Link to="/suppliers/fns-alm">
          <div
            className={urlRoute[2] === "fns-alm" ? "option_active" : "option"}
          >
            <span>Aliments</span>
            <span className="count">{alimentSuppliers?.length}</span>
            <div className="underline4"></div>
          </div>
        </Link> */}
      </div>

      {successCreate && (
        <SuccessAlert
          setOpen={setSuccessCreate}
          label={"Opération effectuée avec success"}
          theme={"success"}
        />
      )}
      {openAddNew && (
        <NewSupplier
          setOpenAdd={setOpenAddNew}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessCreate={setSuccessCreate}
        />
      )}

      <div className="container">
        <Outlet
          context={[buttonRef, successUpdateSupplier, setSuccessUpdateSupplier]}
        />
      </div>
    </div>
  );
};

export default Supplier;
