import React, { useEffect } from 'react'
import './alertebar.scss'
import Close from '@mui/icons-material/Close'
import DoneOutlined from '@mui/icons-material/DoneOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

type Props = {
    setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
    label: string;
    theme: "success" | "fail" | "info" | "warn"
}
const SuccessAlert = (props: Props) => {

    useEffect(() => {
        const timeout = setTimeout(() => {
            props.setOpen(false)
        }, 4000);

        return () => {
            clearInterval(timeout)
        }
    })

    return (
        <div className="alert">
            <div className={props.theme}>
                <div className="icon_container">
                    {props.theme === "success" && <DoneOutlined className='ico' />}
                    {props.theme === "info" && <HelpOutlineOutlinedIcon className='ico' />}
                    {props.theme === "warn" && <WarningAmberOutlinedIcon className='ico' />}
                    {props.theme === "fail" && <ErrorOutlineOutlinedIcon className='ico' />}
                </div>
                <p>{props.label}</p>
                <span className='close'>
                    <Close className='ico' onClick={() => props.setOpen(false)} />
                </span>
            </div>
        </div>
    )
}

export default SuccessAlert

