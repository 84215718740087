import React from "react";
import "./configuration.scss";
import { Outlet, Link, useLocation } from "react-router-dom";
import {
  AdminPanelSettings,
  Inventory,
  Person2Rounded,
  Settings,
  VerticalAlignBottom,
} from "@mui/icons-material";
import {
  ProtectedSectionFor,
  ProtectedSectionForAdmin_Log,
  ProtectedSectionForAdmin_Log_Fin,
} from "../../_service/WithPermission";

const Configuration = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("configuration");

  return (
    <div className="configuration">
      <div className="header">
        <div className="left">
          <Settings className="ico" />
          <ProtectedSectionForAdmin_Log message="Catalogue">
            <span className="title">Configuration</span>
          </ProtectedSectionForAdmin_Log>
        </div>
        <div className="right">
          <button>
            <VerticalAlignBottom className="ico" />
            <span>Exporter XLSX</span>
          </button>
          <button className="btn_sm">
            <VerticalAlignBottom className="ico" />
          </button>
        </div>
      </div>
      <div className="options">
        <Link to="/configuration">
          <div
            className={
              urlRoute[2] === "configuration" ? "option_active" : "option"
            }
          >
            <Person2Rounded className="ico" />
            <span>Agents mobiles</span>
            <span className="span_sm">Agents</span>
          </div>
        </Link>

        <ProtectedSectionFor roleRequired="Admin">
          <Link to="/configuration/administrateur">
            <div
              className={
                urlRoute[2] === "administrateur" ? "option_active" : "option"
              }
            >
              <AdminPanelSettings className="ico" />
              <span>Administrateurs</span>
              <span className="span_sm">Admins</span>
            </div>
          </Link>
        </ProtectedSectionFor>

        <Link to="/configuration/category">
          <div
            className={urlRoute[2] === "category" ? "option_active" : "option"}
          >
            <Inventory className="ico" />
            <span>Produit & Catégorisation</span>
            <span className="span_sm">Catégorisation</span>
          </div>
        </Link>
        {/* <Link to="/configuration/sortie">
          <div
            className={urlRoute[2] === "sortie" ? "option_active" : "option"}
          >
            <Repartition className="ico" />
            <span>Sortie stock</span>
            <span className="span_sm">Sortie</span>
          </div>
        </Link> */}
      </div>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

export default Configuration;
