import React from "react";
import "./singleordersupplier.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { IconButton } from "@mui/material";
import { Add, ArrowBackIosNew, MapOutlined } from "@mui/icons-material";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import { Link, useNavigate } from "react-router-dom";

const SingleOrderSupplier = () => {
  const navigate = useNavigate();

  return (
    <div className="single_order_supplier_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNew className="ico" />
          </IconButton>
          <h3>Détail commande fournisseur</h3>
        </div>
        <div className="right">
          <Link to="/orders-suppliers">
            <div className="btn_commande">
              <Add className="ico" />
              <span>Nouvelle commande</span>
            </div>
          </Link>
        </div>
      </div>

      <div className="container">
        <div className="left">
          <div className="top">
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Id Commande</span>
                <div className="second_info">
                  <span>Nag-Id-001</span>
                  {/* <span>Witesyavwirwa</span> */}
                </div>
              </div>
            </div>

            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Nature Commande</span>
                <div className="second_info">
                  <span>Poussin</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Catégorie & Type</span>
                <div className="second_info">
                  <span>Hybride, Sasso</span>
                </div>
              </div>
            </div>

            <div className="body">
              <div className="content">
                <div className="box">
                  <div className="box_icon">
                    <FiberManualRecordOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Quantité</span>
                    <div className="second_info">
                      <span>20</span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <FiberManualRecordOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Prix unitaire</span>
                    <div className="second_info">
                      <span>$20</span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <FiberManualRecordOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Cout total</span>
                    <div className="second_info">
                      <span>$20</span>
                    </div>
                  </div>
                </div>
                <div className="box">
                  <div className="box_icon">
                    <FiberManualRecordOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Montant payé</span>
                    <div className="second_info">
                      <span>$20</span>
                    </div>
                  </div>
                </div>

                <div className="box reste">
                  <div className="box_icon">
                    <FiberManualRecordOutlinedIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Rest à payer</span>
                    <div className="second_info">
                      <span>$20</span>
                    </div>
                  </div>
                </div>
                <div className="box user">
                  <div className="box_icon">
                    <PersonOutlineIcon className="ico2" />
                  </div>
                  <div className="box_info">
                    <span className="main_info">Fournisseur</span>
                    <div className="second_info">
                      <span>Kakule Witesyavwirwa / +243990119611</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <span className="title"> Historique des commandes </span>
          <ul>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SingleOrderSupplier;
