import React from "react";
import "./listbox.scss";
import { Box, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
import { RootState } from "../../../redux/store";
import NoData from "../../errorComp/NoData";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import { STATUS_ORDER_ID } from "../../../_service/_const";
import { Attachment } from "@mui/icons-material";

type PropsType = {
  isLoading: boolean;
};
const ListBox = (props: PropsType) => {
  // const [triPeriod, setTriePeriod] = React.useState<string>("Semaine en cours");
  // const [yearPeriod, setYearPeriod] = React.useState<string | null>()

  const { allOrder } = useSelector((state: RootState) => state.order);

  const sortedData = _.orderBy(allOrder, ["date"], ["desc"]);

  return (
    <>
      {props.isLoading ? (
        <div className="list_sale">
          <div className="header">
            <Skeleton width={200} />
            <Skeleton width={50} />
          </div>

          <Box sx={{ width: "100%" }} className="table_list">
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
            <Skeleton sx={{ width: "100%" }} height={40} />
          </Box>
        </div>
      ) : (
        <div className="list_sale">
          <div className="header">
            <span className="title">Commandes récentes</span>
            <Link to="/orders/list">
              <span className="plus">Voir détail</span>
            </Link>
          </div>

          <Box sx={{ width: "100%" }} className="table_list">
            <ul className="responsive-table">
              <li className="table-header">
                <div className="col col-4">Id</div>
                <div className="col col-2">Client/Entreprise </div>
                <div className="col col-3">Date</div>
                <div className="col col-3">Produit</div>
                <div className="col col-4">Qnté</div>
                <div className="col col-4">Montant</div>
                <div className="col col-4">Status</div>
              </li>

              {sortedData.slice(0, 5).map((value, index: number) => (
                <Link to={`/orders/${value.nom}`} state={value}>
                  <li className="table-row" key={index}>
                    <div className="col col-4" data-label="Id">
                      {`0${index + 1}`}
                    </div>
                    <div className="col col-2 Ese" data-label="Client">
                      {!value.is_legal_person ? (
                        `${value.prenom} ${value.nom}`
                      ) : (
                        <>
                          {value.nom_entreprise}
                          <span className="container-Ese">
                            <span className="Ese">e</span>
                          </span>
                        </>
                      )}
                    </div>
                    <div className="col col-3" data-label="Date">
                      {moment(value.date).format("DD-MM-YYYY")}
                    </div>
                    <div className="col col-3" data-label="Produit">
                      {value.nature_designation}
                    </div>

                    <div className="col col-4" data-label="Quantité">
                      {value.quantite}
                    </div>
                    <div className="col col-4" data-label="Total">
                      {value.prixtotal} {value.devise}
                    </div>
                    <div className="col col-4" data-label="Status pending">
                      {value.statusCmd_id === STATUS_ORDER_ID.ETABLI && (
                        <span className="status pending ">Etablie</span>
                      )}
                      {value.statusCmd_id === STATUS_ORDER_ID.RESERVE && (
                        <span className="status reserved ">
                          Réservé<em></em>
                        </span>
                      )}
                      {value.statusCmd_id ===
                        STATUS_ORDER_ID.ETABLIE_AVEC_PAIEMENT && (
                        <span className="status preuve ">
                          {" Etablie"} <Attachment className="ico" />
                        </span>
                      )}
                      {value.statusCmd_id === STATUS_ORDER_ID.REGLE && (
                        <span className="status validate ">Réglée</span>
                      )}
                      {value.statusCmd_id === STATUS_ORDER_ID.ANNULE && (
                        <span className="status canceled ">Annulée</span>
                      )}
                      {value.statusCmd_id ===
                        STATUS_ORDER_ID.ETABLIE_AVEC_DETTE && (
                        <span className="status dette ">Dette</span>
                      )}
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          </Box>
        </div>
      )}

      {allOrder.length === 0 && !props.isLoading && <NoData />}
    </>
  );
};

export default ListBox;
