import React, { useState } from "react";
import "./messageUpdate.scss";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NotificationType, requestType } from "../../types/GlobalType";
import { ERROR_MESSAGE } from "../../_service/_const";
import { notification_api } from "../../_service/notification.Call";
import ErrorBar from "../errorComp/ErrorBar";
import { MessageOutlined } from "@mui/icons-material";

type Props = {
  setOpenUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  data: NotificationType | undefined;
  setSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReload: () => void;
};
const MessageUpdate = (props: Props) => {
  const [messageData, setMessageData] = useState<NotificationType>({
    titre: props.data?.titre,
    description: props.data?.description,
  });

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });
    if (messageData.titre === "" || messageData.description === "") {
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter tous les champs",
      }));
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        titre: messageData.titre,
        description: messageData.description,
      };
      setState({ isLoading: true, errorMessage: null });
      notification_api
        .update(props.data?.id, data, token)
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
            });
            setMessageData({ titre: "", description: "" });
            props.setSuccess(true);
            props.setOpenUpdate(false);
            props.forceReload();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  return (
    <div className="message_dialog">
      <div className="dialog">
        <div className="header">
          <div className="header-left">
            <MessageOutlined className="ico" />
            {" Modification notification"}
          </div>
          {!state.isLoading && (
            <div className="Right_send">
              <button onClick={() => props.setOpenUpdate(false)}>
                Annuler
              </button>
              <button onClick={handleSubmit}>Modifier</button>
            </div>
          )}
          {state.isLoading && (
            <div className="Right_loading">
              <button disabled>Annuler</button>
              <button>
                <CircularProgress size={12} className="circular" />
              </button>
            </div>
          )}
        </div>

        <div className="body">
          {state.errorMessage && <ErrorBar title={state.errorMessage} />}
          <input
            type="text"
            placeholder="Objet"
            value={messageData.titre}
            onChange={(e) =>
              setMessageData((prev) => ({ ...prev, titre: e.target.value }))
            }
          />
          <textarea
            placeholder="Message..."
            cols={30}
            rows={10}
            value={messageData.description}
            onChange={(e) =>
              setMessageData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          ></textarea>
        </div>
      </div>
    </div>
  );
};

export default MessageUpdate;
