import { AddBoxOutlined } from "@mui/icons-material";
import "./nodata.scss";

const NoData = () => {
  return (
    <div className="nodata">
      <AddBoxOutlined sx={{ fontSize: 30, color: "#888" }} />
      <span>Aucune donnée disponible</span>
    </div>
  );
};

export default NoData;
