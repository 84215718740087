import React, { useState } from "react";
import "./requete.scss";
import { IconButton, Tooltip } from "@mui/material";
import {
  Add,
  ArrowDropDown,
  ArrowDropUpOutlined,
  DeleteSweepOutlined,
  HdrAutoOutlined,
  ModeEditOutline,
  PermIdentity,
} from "@mui/icons-material";
import Delete from "../../dialog/Delete";
import EditDialog from "./EditDialog";
import SuccessAlert from "../../alert/AlerteBar";
import { APP_CONST, ERROR_MESSAGE } from "../../../_service/_const";
import { RequeteType, requestType } from "../../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import { requete_api } from "../../../_service/notification.Call";
import NoData from "../../errorComp/NoData";
import ConnexionError from "../../errorComp/Connexion";
import RequeteNew from "./RequeteNew";
import { RotatingLines } from "react-loader-spinner";
import _, { capitalize } from "lodash";
import moment from "moment";

const Requete = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openDelete, setOpenDelete] = React.useState<Boolean>(false);
  const [notifyId, setNotifyId] = React.useState<string | number>();
  const [openAddDial, setOpenAddDial] = React.useState<Boolean>(false);
  const [editDial, setOpenEditDial] = React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  const [accordion, setAccordion] = useState<number>(-1);

  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
    succussMessage: null,
  });
  const [reducerReloadValue, forceReload] = React.useReducer((x) => x + 1, 0);
  const [successAdd, setSuccesAdd] = React.useState<Boolean>(false);
  const [successUpdate, setSuccessUpdate] = React.useState<Boolean>(false);
  const [successDelete, setSuccessDelete] = React.useState<Boolean>(false);

  const [data, setData] = useState<RequeteType[]>([]);
  const [singleData, setSingleData] = useState<RequeteType>();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAccordion = (value: number) => {
    if (value === accordion) {
      setAccordion(-1);
      return;
    }
    setAccordion(value);
  };

  const handleDelete = (id: string | number | undefined) => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      requete_api
        .delete(id, token)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setLoadingDeleteBtn(false);
            setOpenDelete(false);
            setSuccessDelete(true);
            forceReload();
          }
        })
        .catch((error: any) => {
          setLoadingDeleteBtn(false);
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const fetchAll = () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
        succussMessage: "",
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      requete_api
        .getAll(token)
        .then((response) => {
          // console.log(response);
          setState((prev) => ({
            ...prev,
            isLoading: false,
            succussMessage: "success",
          }));
          setData(response.data.data);
        })
        .catch((error) => {
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  let orderedData: RequeteType[] = [];

  if (data.length > 0) {
    orderedData = _.orderBy(data, ["created_at"], ["desc"]);
  }

  React.useEffect(() => {
    fetchAll();
  }, [reducerReloadValue]);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          // color="#777"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          // wrapperClass=""
          // wrapperStyle={{}}
        />
        <span>Chargement...</span>
      </div>
    );
  }

  if (state.succussMessage === "success" && data.length <= 0) return <NoData />;

  return (
    <div className="requete_notification">
      <div className="header">
        <div
          className="btn_commande"
          onClick={() => {
            setOpenAddDial(true);
          }}
        >
          <Add className="ico" />
          <span>Nouvelle requête</span>
        </div>
      </div>
      {orderedData.map((item, index) => {
        return (
          <div
            className="box_rqt"
            key={index}
            onClick={() => handleAccordion(index)}
          >
            <div className="rqt">
              <div className="left">
                <p className="text_xl">
                  {capitalize(item.question?.slice(0, 150))}
                </p>
                <p className="text_lg">
                  {capitalize(item.question?.slice(0, 100))}
                </p>
                <p className="text_md">
                  {capitalize(item.question?.slice(0, 50))}
                </p>
                <p className="text_sm">
                  {capitalize(item.question?.slice(0, 30))}
                </p>
              </div>

              <div className="icons">
                {item.expediteur !== APP_CONST.APP_NAME ? (
                  <div className="number">
                    <span>new</span>
                  </div>
                ) : null}

                {item.expediteur !== APP_CONST.APP_NAME ? (
                  <IconButton>
                    <PermIdentity />
                  </IconButton>
                ) : (
                  <IconButton>
                    <HdrAutoOutlined />
                  </IconButton>
                )}

                <div>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? "long-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    {accordion === index ? (
                      <ArrowDropUpOutlined className="icon" />
                    ) : (
                      <>
                        <Tooltip title={"détail"}>
                          <ArrowDropDown className="icon" />
                        </Tooltip>
                      </>
                    )}
                  </IconButton>
                </div>
              </div>
            </div>
            <div
              className={
                accordion === index ? "response animation" : "inactive"
              }
            >
              <p className="text">{item.reponse}</p>

              <div className="btns_">
                <div className="date_Creation">
                  {moment(item.date).format("llll")}
                </div>
                <div
                  className="editer"
                  onClick={() => {
                    setSingleData(item);
                    setOpenEditDial(true);
                  }}
                >
                  <ModeEditOutline className="ico" />
                  Modifier
                </div>
                <div
                  className="supprimer"
                  onClick={() => {
                    setNotifyId(item?.id);
                    setOpenDelete(true);
                  }}
                >
                  <DeleteSweepOutlined className="ico" />
                  {"Supprimer"}
                </div>
              </div>
            </div>
          </div>
        );
      })}

      {editDial && (
        <EditDialog
          setOpenEditDial={setOpenEditDial}
          data={singleData}
          setSuccess={setSuccessUpdate}
          forceReload={forceReload}
        />
      )}
      {openAddDial && (
        <RequeteNew
          setOpenDial={setOpenAddDial}
          setSuccesAdd={setSuccesAdd}
          forceReload={forceReload}
        />
      )}

      {openDelete && (
        <Delete
          setOpen={setOpenDelete}
          loadingDeleteBtn={loadingDeleteBtn}
          id={notifyId}
          label={"Voulez-vous vraiment supprimer cette requête?"}
          handleDelete={handleDelete}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
      {successAdd && (
        <SuccessAlert
          setOpen={setSuccesAdd}
          label={"Requête ajoutée avec success"}
          theme={"success"}
        />
      )}
      {successUpdate && (
        <SuccessAlert
          setOpen={setSuccessUpdate}
          label={"Modification effectuée avec succès"}
          theme={"success"}
        />
      )}
      {successDelete && (
        <SuccessAlert
          setOpen={setSuccessDelete}
          label={"Requête supprimée avec succès"}
          theme={"success"}
        />
      )}
    </div>
  );
};

export default Requete;
