import React, { useEffect } from "react";
import "./autreEntreelist.scss";
import { Box } from "@mui/material";
import SearchBar from "../../search/Search";
import SingleOtherEntree from "../dialog/SingleOtherEntree";
import { useSelector } from "react-redux";
import { entreeType } from "../../../types/GlobalType";
import { RootState } from "../../../redux/store";
import { MOTIF_SORTIE } from "../../../_service/_const";
import MyLoadingv3 from "../../loader/Loader_v3";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import exportFromJSON from "export-from-json";
import _ from "lodash";
import NoData from "../../errorComp/NoData";

const AutreEntreeList = () => {
  const isLoading: boolean = useOutletContext();

  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [data, setData] = React.useState<entreeType | any>();
  const [searchValue, setSearchValue] = React.useState<string>("");

  const { allEntree } = useSelector((state: RootState) => state.entreeStock);
  let sortedData = _.orderBy(allEntree, ["date"], ["desc"]);

  let entreeFournisseur: entreeType[] | any[] = [];
  let entreeAutre: entreeType[] | any[] = [];

  if (allEntree.length > 0) {
    for (let item of sortedData) {
      item.motif_designation === MOTIF_SORTIE.sortie_incubation
        ? entreeAutre.push(item)
        : entreeFournisseur.push(item);
    }
  }
  const contextBtnRef: any = useOutletContext();
  const contextBtnRef_ok = contextBtnRef[0];
  const isLoading_ok: boolean = contextBtnRef[1];

  const handleClick = () => {
    const fileName = "NAGRITECH_ENTREES_FOURNISSEURS_TABLE";
    const exportType = "xls";
    const fields = [
      "id",
      "nom",
      "postnom",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
      "quantite",
    ];
    exportFromJSON({ data: entreeAutre, fileName, fields, exportType });
  };
  useEffect(() => {
    const button = contextBtnRef_ok.current;
    // console.log(button);
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);

  if (entreeAutre.length <= 0) {
    return (
      <>
        <div className="list_autre_sortie">
          <div className="search">
            <SearchBar
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              label={"Recherche produit"}
            />
          </div>
          <NoData />
        </div>
      </>
    );
  }
  return (
    <>
      <MyLoadingv3 isLoading={isLoading_ok} />

      <div className="list_autre_entree">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche produit"}
          />
        </div>
        <div className="header"></div>
        <Box sx={{ width: "100%" }} className="table_list">
          <ul className="responsive-table">
            <li className="table-header">
              <div className="col col-3">Id</div>
              <div className="col col-2">Produit </div>
              <div className="col col-3">Date</div>
              {/* <div className="col col-4">Création</div> */}
              <div className="col col-4">Quantité</div>
              {/* <div className="col col-4">Prix total</div> */}
              <div className="col col-4">Motif</div>
            </li>

            {entreeAutre
              .filter(
                (item: entreeType) =>
                  item.nature_designation
                    .toLowerCase()
                    .includes(searchValue.toLowerCase()) ||
                  item?.date?.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((value: entreeType, index: number) => (
                <li
                  className="table-row"
                  key={index}
                  onClick={() => {
                    setData(value);
                    setOpenDetail(true);
                  }}
                >
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-2" data-label="Produit">
                    {`${value.nature_designation}`}
                  </div>
                  <div className="col col-3" data-label="Date">
                    <span>{moment(value.date).format("ll")}</span>
                  </div>
                  <div className="col col-4" data-label="Quantité">
                    {value.quantite}
                  </div>
                  {/* <div className="col col-4" data-label="Total">
                    {value.prixunitaire * value.quantite} {value.devise}
                  </div> */}
                  <div className="col col-4" data-label="Motif">
                    {value.motif_designation ===
                      MOTIF_SORTIE.sortie_incubation &&
                      MOTIF_SORTIE.produit_incubation}
                  </div>
                </li>
              ))}
          </ul>
        </Box>

        {openDetail && (
          <SingleOtherEntree setOpen={setOpenDetail} data={data} />
        )}
      </div>
    </>
  );
};

export default AutreEntreeList;
