import React from "react";
import "./singleotherentree.scss";
import { Close, LibraryBooks } from "@mui/icons-material";
import { MapOutlined } from "@mui/icons-material";
import FiberManualRecordOutlinedIcon from "@mui/icons-material/FiberManualRecordOutlined";
import { entreeType } from "../../../types/GlobalType";
import moment from "moment";
import { MOTIF_SORTIE } from "../../../_service/_const";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: entreeType;
};
const SingleOtherEntree = (props: Props) => {
  return (
    <div className="single_other_dialog">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <LibraryBooks sx={{ fontSize: "17px" }} />
            <h4>Détail entrée</h4>
          </div>
          <div className="right_side_title">
            <Close className="ico" onClick={() => props.setOpen(false)} />
          </div>
        </div>

        <div className="body">
          <div className="user_info">
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Id Commande</span>
                <div className="second_info">
                  <span>Nag-Id-0{props.data.entree_id}</span>
                  {/* <span>Witesyavwirwa</span> */}
                </div>
              </div>
            </div>

            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Date</span>
                <div className="second_info">
                  <span>{moment(props.data.date).format("llll")}</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Fournisseur</span>
                <div className="second_info">
                  <span>{`${props.data.nom} ${props.data.prenom}`}</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Télephone</span>
                <div className="second_info">
                  <span>{props.data.telephone}</span>
                </div>
              </div>
            </div>
            {/* <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Agent</span>
                <div className="second_info">
                  <span>{"Admin"}</span>
                </div>
              </div>
            </div> */}
          </div>

          <div className="achat_info">
            <div className="box nature">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Nature commande</span>
                <div className="second_info">
                  <span>{props.data.nature_designation}</span>
                </div>
              </div>
            </div>
            <div className="box nature">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Catégorie & Type</span>
                <div className="second_info">
                  <span>
                    {props.data.categorie ? `${props.data.categorie} / ` : ""}{" "}
                    {props.data.type}
                  </span>
                </div>
              </div>
            </div>
            <div className="box nature">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Mode entrée</span>
                <div className="second_info">
                  <span>
                    {" "}
                    <span>
                      {props.data.motif_designation ===
                        MOTIF_SORTIE.sortie_incubation &&
                        MOTIF_SORTIE.produit_incubation}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Quantité</span>
                <div className="second_info">
                  <span>{props.data.quantite}</span>
                </div>
              </div>
            </div>
            {/* <div className="box">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Prix unitaire</span>
                <div className="second_info">
                  <span>
                    {props.data.prixunitaire} {props.data.devise}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Cout total</span>
                <div className="second_info">
                  <span>
                    {" "}
                    {props.data.prixunitaire * props.data.quantite}{" "}
                    {props.data.devise}
                  </span>
                </div>
              </div>
            </div> */}
            {/* <div className="box">
              <div className="box_icon">
                <FiberManualRecordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Désignation d'achat</span>
                <div className="second_info">
                  <span>{props.data.motif_designation}</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className="footer"></div>
      </div>
    </div>
  );
};

export default SingleOtherEntree;
