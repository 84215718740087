import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { natureType } from "../../types/GlobalType";

interface NatureInitialState{
  allNature: natureType[],
}

const initialState:NatureInitialState = {
  allNature: [],

};
const natureSlice = createSlice({
  name: "nature",
  initialState,
  reducers: {
    addAllNature: (state, action:PayloadAction<natureType []>) => {
      state.allNature=Object.assign([], action.payload)
    },
  },
});

export const {addAllNature} = natureSlice.actions;
export default natureSlice.reducer;
