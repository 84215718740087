import React from "react";
import "./warningdialog.scss";
import { Close, ErrorOutline, Reply, Warning } from "@mui/icons-material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import { Link } from "react-router-dom";

type PropsType = {
  title: string;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
};
const WarningDialog = ({ title, setOpen }: PropsType) => {
  return (
    <div className="warning_cmd_dialog">
      <div className="dialog">
        <div className="body">
          <ErrorOutline className="ico" sx={{ strokeWidth: 1 }} />
          <span style={{ textAlign: "center" }}> {title} </span>
          <div className="btn" onClick={() => setOpen(false)}>
            <button>Retourner</button>
          </div>
        </div>
        <Link to="/store">
          <p>Consulter le stock</p>
        </Link>
      </div>
    </div>
  );
};

export default WarningDialog;
