import React from "react";
import CardSore from "../cardSynthesis/CardStore";
import eggLogo from "../../../assets/categories/egg.svg";
import gasLogo from "../../../assets/categories/gas.svg";
import chicken_2Logo from "../../../assets/categories/chicken.svg";
import incubator from "../../../assets/categories/incubator.svg";
import chickLogo from "../../../assets/categories/chik_1.svg";
import chickenLogo from "../../../assets/categories/chicken_1.svg";
import { Box, Grid, Skeleton } from "@mui/material";
import { DinnerDiningOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { STATUS_INCUBATION_ID } from "../../../_service/_const";

type PropsType = {
  isLoading: boolean;
};

const CardSynthese = (props: PropsType) => {
  const current_store = useSelector((state: RootState) => state.stock);
  let quantiteItem = {
    aliment: 0,
    biogaz: 0,
    oeuf: 0,
    poussin: 0,
    poule: 0,
    poulet: 0,
    incubation: 0,
  };

  if (current_store.alimentStore.length > 0) {
    quantiteItem.aliment = current_store.alimentStore.reduce(
      (acc, current_value) => {
        return acc + current_value.quantite;
      },
      0
    );
  }

  if (current_store.biogazStore.length > 0) {
    quantiteItem.biogaz = current_store.biogazStore.reduce(
      (acc, current_value) => {
        return acc + current_value.quantite;
      },
      0
    );
  }
  if (current_store.oeufStore.length > 0) {
    quantiteItem.oeuf = current_store.oeufStore.reduce((acc, current_value) => {
      return acc + current_value.quantite;
    }, 0);
  }
  if (current_store.poussinStore.length > 0) {
    quantiteItem.poussin = current_store.poussinStore.reduce(
      (acc, current_value) => {
        return acc + current_value.quantite;
      },
      0
    );
  }
  if (current_store.pouleStore.length > 0) {
    quantiteItem.poule = current_store.pouleStore.reduce(
      (acc, current_value) => {
        return acc + current_value.quantite;
      },
      0
    );
  }
  if (current_store.pouletStore.length > 0) {
    quantiteItem.poulet = current_store.pouletStore.reduce(
      (acc, current_value) => {
        return acc + current_value.quantite;
      },
      0
    );
  }
  if (current_store.incubation.length > 0) {
    quantiteItem.incubation = current_store.incubation.reduce(
      (acc, current_value) => {
        let sum: number = 0;
        if (current_value.status_inc_id === STATUS_INCUBATION_ID.ENCOURS) {
          sum = acc + current_value.quantite;
        }
        return sum;
      },
      0
    );
  }
  return (
    <Box>
      {props.isLoading ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={0.5}>
              <Skeleton variant="rounded" height={60} width={170} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#416a0035"
                icon={<DinnerDiningOutlined sx={{ color: "#416a00" }} />}
                title="Aliment"
                count={quantiteItem.aliment}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                // color="success"
                color="#15a01f3a"
                icon={<img src={gasLogo} alt="chiken" height={30} />}
                title="Biogaz"
                count={quantiteItem.biogaz}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#b684113a"
                icon={<img src={eggLogo} alt="chiken" height={30} />}
                title="Oeuf"
                count={quantiteItem.oeuf}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#a599133a"
                icon={<img src={chickLogo} alt="chiken" height={30} />}
                title="Poussin"
                count={quantiteItem.poussin}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#a038153a"
                icon={<img src={chickenLogo} alt="chiken" height={30} />}
                title="Poule"
                count={quantiteItem.poule}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#6e88703c"
                icon={<img src={chicken_2Logo} alt="chiken" height={30} />}
                title="Poulet"
                count={quantiteItem.poulet}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box mb={1.5}>
              <CardSore
                color="#6e88703c"
                icon={<img src={incubator} alt="chiken" height={30} />}
                title="Incubateur"
                count={quantiteItem.incubation}
                // percentage={{
                //   color: "success",
                //   label: "Détail",
                // }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CardSynthese;
