import React, { useState } from "react";
import "./incubationall.scss";
import SearchBar from "../../search/Search";
import List from "./List";
import DialogList from "../_incubationDialog/DialogList";
import { incubationType } from "../../../types/GlobalType";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  STATUS_INCUBATION,
  STATUS_INCUBATION_ID,
} from "../../../_service/_const";
import { Autorenew, DoneOutline, IosShare } from "@mui/icons-material";

const IncubationAll = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sortedData, setSortedData] = useState<incubationType[] | any>([]);
  const [statusIncubation, setStatusIncubation] = useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const { allIncubation } = useSelector((state: RootState) => state.incubation);

  let incEncours: number = 0;
  let incTermine: number = 0;
  let incSortie: number = 0;
  let incAnnule: number = 0;
  let incBientot: number = 0;

  let incEncoursArr: incubationType[] | any = [];
  let incTermineArr: incubationType[] | any = [];
  let incSortieArr: incubationType[] | any = [];
  let incAnnuleArr: incubationType[] | any = [];
  let incBientotArr: incubationType[] | any = [];

  if (allIncubation.length > 0) {
    allIncubation.forEach((element) => {
      if (element.status_inc_id === STATUS_INCUBATION_ID.ENCOURS) {
        incEncours++;
        incEncoursArr.push(element);
      } else if (element.status_inc_id === STATUS_INCUBATION_ID.BIENTOT) {
        incBientot++;
        incBientotArr.push(element);
      } else if (element.status_inc_id === STATUS_INCUBATION_ID.TERMINE) {
        incTermine++;
        incTermineArr.push(element);
      } else if (element.status_inc_id === STATUS_INCUBATION_ID.SORTIE) {
        incSortie++;
        incSortieArr.push(element);
      } else if (element.status_inc_id === STATUS_INCUBATION_ID.ANNULE) {
        incAnnule++;
        incAnnuleArr.push(element);
      }

      // switch (element.status_inc_id) {
      //   case STATUS_INCUBATION_ID.ENCOURS:
      //     incEncours++;
      //     incEncoursArr.push(element);
      //     break;
      //   case STATUS_INCUBATION_ID.BIENTOT:
      //     incBientot++;
      //     incBientotArr.push(element);
      //     break;
      //   case STATUS_INCUBATION_ID.TERMINE:
      //     incTermine++;
      //     incTermineArr.push(element);
      //     break;
      //   case STATUS_INCUBATION_ID.SORTIE:
      //     incSortie++;
      //     incSortieArr.push(element);
      //     break;
      //   case STATUS_INCUBATION_ID.ANNULE:
      //     incAnnule++;
      //     incAnnuleArr.push(element);
      //     break;
      //   default:
      //     break;
      // }
    });
  }

  // console.log(allIncubation, "-----Alllllllllll");
  // console.log(incTermineArr, "-----INC termine");
  // console.log(incSortieArr, "-----INC sortie");

  const handleOpenDialog = (arr: incubationType[], status: string) => {
    setSortedData(arr);
    setStatusIncubation(status);
    setOpen(true);
  };

  return (
    <div className="table_incubation_all">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        <div className="btns">
          <div
            className="status pending"
            onClick={() =>
              handleOpenDialog(incEncoursArr, STATUS_INCUBATION.ENCOURS)
            }
          >
            <Autorenew className="ico" />
            <span>En cours {incEncours}</span>
          </div>
          <div
            className="status reserved"
            onClick={() =>
              handleOpenDialog(incBientotArr, STATUS_INCUBATION.BIENTOT)
            }
          >
            <Autorenew className="ico" />
            <span>Bientôt {incBientot}</span>
          </div>
          <div
            className="status validate"
            onClick={() =>
              handleOpenDialog(incTermineArr, STATUS_INCUBATION.TERMINE)
            }
          >
            <DoneOutline className="ico" />
            <span>Terminées {incTermine}</span>
          </div>
          <div
            className="status validate"
            onClick={() =>
              handleOpenDialog(incSortieArr, STATUS_INCUBATION.SORTIE)
            }
          >
            <IosShare className="ico" />
            <span>Sorties {incSortie}</span>
          </div>
        </div>
      </div>
      {open && (
        <DialogList
          incubation={sortedData}
          status={statusIncubation}
          setOpen={setOpen}
        />
      )}
      <List searchValue={searchValue} />
    </div>
  );
};

export default IncubationAll;
