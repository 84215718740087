import React, { useEffect, useState } from 'react'
import './searchUser.scss'
import Search from '@mui/icons-material/Search'
import { UserType } from '../../types/UserTypes';
import { userData } from '../../data/data';
// import { CircularProgress } from '@mui/material';

type Props = {
  setClientData: React.Dispatch<React.SetStateAction<UserType>>
}

const SearchBar_User = ({ setClientData }: Props) => {

  const [keyWord, setKeyWord] = useState<string | undefined>(undefined);
  const [data, setData] = useState<UserType[] | []>([])

  const handleData = (e: UserType) => {
    setClientData(e)
  }
  useEffect(() => {

    setData(userData)
    // const time = setTimeout(() => {
    //   setData(userData)
    // }, 3000);
    // return () => {
    //   clearTimeout(time)
    // }
  }, [])

  const dataFiltered = (keyWord) && data.filter(items => items.nom?.toLocaleLowerCase().includes(keyWord.toLowerCase()))

  return (
    <div className='conatiner_seachUser'>
      <div className='Searchbar'>
        <Search className="icon" />
        <input placeholder="Recherche" type="text" value={keyWord} onChange={(e) => setKeyWord(e.target.value)} />
      </div>
      <div className={!!keyWord && keyWord?.length > 0 ? "contentView" : "contentHide"}>
        <ul>
          {dataFiltered &&
            dataFiltered.slice(0, 10).map((value, index) => (
              <li key={index}>
                <div className='user_info' onClick={() => {handleData(value); setKeyWord("")}}>
                  <span>{value.nom} {value.postnom}</span>
                  <span>{value.telephone}</span>
                </div>
              </li>
            ))
          }
          {dataFiltered?.length === 0 &&
            <li>
              <div className='user_info'>
                <span>Ce client n'existe pas</span>
                <span></span>
              </div>
            </li>
          }

          {/* <li>
            <div className='user_info'>
              <span>Recherche...</span>
              <CircularProgress color="inherit" size={20} />
            </div>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default SearchBar_User
