import React, { useState } from "react";
import "./reserverdialog.scss";
import SearchBarUser from "../../search/SearchUser";
import { UserType } from "../../../types/UserTypes";

type reserveType = {
  libele?: string;
  delai?: string;
  detail?: string;
};
type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const ReserverDialog = (props: Props) => {
  const [resData, setResData] = useState<reserveType>({
    libele: "",
    delai: "",
    detail: "",
  });

  // console.log(resData.delai)
  const [clientData, setClientData] = useState<UserType>({
    nom: "",
    prenom: "",
    postnom: "",
    email: "",
    sexe: "",
    pays: "",
    ville: "",
    commune: "",
    quartier: "",
    avenue: "",
    service: "",
    telephone: "",
  });

  return (
    <div className="reserver_cf_">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <span>Réservation chambre froide</span>
          </div>
          <div className="search">
            <SearchBarUser setClientData={setClientData} />
          </div>
          {/* <Close className='ico'/> */}
        </div>

        <div className="body">
          <div className="item produit_info">
            <span>Produit</span>
          </div>
          <div className="item libele">
            <label>Nom du produit</label>
            <input
              placeholder="Nom du produit..."
              type="text"
              value={resData.libele}
              onChange={(e) => setResData({ libele: e.target.value })}
            />
          </div>
          <div className="item deadline">
            <label>Délai</label>
            <input
              type="date"
              value={resData.delai}
              onChange={(e) => setResData({ delai: e.target.value })}
            />
          </div>
          <div className="item detail">
            <label>Détail</label>
            <textarea
              placeholder="Détail du produit..."
              rows={5}
              value={resData.delai}
              onChange={(e) => setResData({ detail: e.target.value })}
            />
          </div>
          <div className="item user_info">
            <span>Client</span>
          </div>
          <div className="item">
            <label>Nom</label>
            <input
              placeholder="Nom..."
              type="text"
              value={clientData?.nom}
              onChange={(e) => setClientData({ nom: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Postnom</label>
            <input
              placeholder="Postnom..."
              type="text"
              value={clientData?.postnom}
              onChange={(e) => setClientData({ postnom: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Prénom</label>
            <input
              placeholder="Prénon..."
              type="text"
              value={clientData?.prenom}
              onChange={(e) => setClientData({ prenom: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Genre</label>
            <select
              value={clientData?.sexe}
              onChange={(e) => setClientData({ sexe: e.target.value })}
            >
              <option value="masculin">Masculin</option>
              <option value="feminin">Feminin</option>
            </select>
          </div>
          <div className="item">
            <label>Adresse mail</label>
            <input
              placeholder="Email..."
              type="text"
              value={clientData?.email}
              onChange={(e) => setClientData({ email: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Téléphone</label>
            <input
              placeholder="Téléphone..."
              type="text"
              value={clientData?.telephone}
              onChange={(e) => setClientData({ telephone: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Pays</label>
            <input
              placeholder="Pays..."
              type="text"
              value={clientData?.pays}
              onChange={(e) => setClientData({ pays: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Ville</label>
            <input
              placeholder="Ville..."
              type="text"
              value={clientData?.ville}
              onChange={(e) => setClientData({ ville: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Commune</label>
            <input
              placeholder="Commune..."
              type="text"
              value={clientData?.commune}
              onChange={(e) => setClientData({ commune: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Quartier</label>
            <input
              placeholder="Quartier..."
              type="text"
              value={clientData?.quartier}
              onChange={(e) => setClientData({ quartier: e.target.value })}
            />
          </div>
          <div className="item">
            <label>Avenue</label>
            <input
              placeholder="Avenue..."
              type="text"
              value={clientData?.avenue}
              onChange={(e) => setClientData({ avenue: e.target.value })}
            />
          </div>
          <div className="item">
            {/* <label>Avenue</label> */}
            <input
              placeholder="Avenue..."
              type="hidden"
              value={"chambre froide"}
            />
          </div>
        </div>
        <div className="btn">
          <button
            onClick={() => {
              props.setOpen(false);
            }}
          >
            Annuler
          </button>
          <button>Réserver</button>
        </div>
      </div>
    </div>
  );
};

export default ReserverDialog;
