import React from "react";
import "./attachedFile.scss";
import { ArrowRight, Close } from "@mui/icons-material";
import { URL_BACKEND_APP } from "../../../_service/_const";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: string | undefined;
  img: string | undefined;
};
const AttachedFile = (props: Props) => {
  let img_name = props.img?.split("/").pop();
  let img_url = `${URL_BACKEND_APP}/public/img/notification/${img_name}`;
  // let img_url = `${process.env.REACT_APP_API_URL}/public/img/notification/${img_name}`;

  return (
    <div className="dialog_attached_File">
      <div className="header_t">
        <div className="header_title">
          <span className="title_h">Image d'attache</span>
          <ArrowRight className="ico" />
          <span className="name">{props.data}</span>
        </div>
        <Close className="ico" onClick={() => props.setOpen(false)} />
      </div>

      <div className="dialog">
        <img
          src={img_url}
          alt="preuve_paiement_img"
          width="100%"
          height="100%"
          style={{ objectFit: "contain" }}
        />
      </div>
    </div>
  );
};

export default AttachedFile;
