import axios from "axios";
import { URL_BACKEND_APP } from "./_const";

// const API_CALLER_CLIENT = axios.create({
//   baseURL: URL_BACKEND_APP+"/commande_clients",
// });
// const API_CALLER_SUPPLIER = axios.create({
//   baseURL: URL_BACKEND_APP+"/commande_fournisseurs",
// });

const config = (token) => ({
  headers: {
    Authorization: "Bearer " + token,
  },
});

const store = {
  // getOne: (id, token) => {
  //   return API_CALLER_CLIENT.get(`/readById.php?id=${id}`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },

  getAllByService: (token) => {
    let url1 = URL_BACKEND_APP + "/stock_aliment/read.php";
    let url2 = URL_BACKEND_APP + "/stock_biogaz/read.php";
    let url3 = URL_BACKEND_APP + "/stock_oeuf/read.php";
    let url4 = URL_BACKEND_APP + "/stock_poussin/read.php";
    let url5 = URL_BACKEND_APP + "/stock_poule/read.php";
    let url6 = URL_BACKEND_APP + "/stock_poulet/read.php";
    let url7 = URL_BACKEND_APP + "/incubation/read.php";

    let req1 = axios.get(url1, config(token));
    let req2 = axios.get(url2, config(token));
    let req3 = axios.get(url3, config(token));
    let req4 = axios.get(url4, config(token));
    let req5 = axios.get(url5, config(token));
    let req6 = axios.get(url6, config(token));
    let req7 = axios.get(url7, config(token));

    return axios.all([req1, req2, req3, req4, req5, req6, req7]);
  },

  // getAll: (token) => {
  //   return API_CALLER_CLIENT.get(`/read.php`, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },

  // update: (id, data, token) => {
  //   return API_CALLER_CLIENT.put(`/update.php?id=${id}`, data, {
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  // },

  // delete: (id) => {
  //   return API_CALLER_CLIENT.delete(`/delete.php?id=${id}`);
  // },
};

export const store_api = { store };
