import React, { useReducer, useState } from "react";
import "./profil.scss";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MapOutlined from "@mui/icons-material/MapOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { Avatar, Skeleton } from "@mui/material";
import EditProfilDialog from "../../components/profil/EditProfilDialog";
import { AdminType } from "../../types/UserTypes";
// import { adminData } from "../../data/data";
import admin_api from "../../_service/admin.Caller";
import { requestType } from "../../types/GlobalType";
import { useNavigate } from "react-router-dom";
import { ERROR_MESSAGE } from "../../_service/_const";
import { LockOpen } from "@mui/icons-material";
import UpdatePswd from "../../components/profil/UpdatePswd";
import COUNTRY_CODES_EN from "../../_service/_countryCode";

const Profil = () => {
  const [openEdit, setOpenEdit] = useState<Boolean>(false);
  const [openPsw, setOpenPsw] = useState<boolean>(false);
  // const [singleData, setSingleData] = useState<AdminType>();
  const [data, setadminData] = useState<AdminType>();

  const [email, setEmail] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  let pays: string = !!data?.pays ? data.pays : "CD";

  const [reducerValueOnUpdate, forceReloadOnUpdate] = useReducer(
    (x) => x + 1,
    0
  );

  const navigate = useNavigate();

  // console.log(data);

  const fetchUserInfo = () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;
      const id = userLS.user.id;

      setEmail(userLS.user.email);
      setRole(userLS.user.role === "isAdmin" ? "Administrateur" : "Agent");

      admin_api
        .getOne(id, token)
        .then((response) => {
          setState((prev) => ({ ...prev, isLoading: false }));
          setadminData(response.data.data);
        })
        .catch((error) => {
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, [reducerValueOnUpdate]);

  return (
    <>
      {state.isLoading ? (
        <div className="profil">
          <div className="header">
            <Avatar className="ico" />
            <Skeleton width={250} height={30} variant="rectangular" />
            <Skeleton width={150} height={20} variant="rounded" />
            <Skeleton width={150} height={20} variant="rounded" />
          </div>
          <div className="body_sk">
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <Skeleton width={300} height={20} variant="rectangular" />
                <Skeleton width={300} height={10} variant="rectangular" />
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <LocationOnOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <Skeleton width={300} height={20} variant="rectangular" />
                <Skeleton width={300} height={10} variant="rectangular" />
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <PhoneIphoneOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <Skeleton width={300} height={20} variant="rectangular" />
                <Skeleton width={300} height={10} variant="rectangular" />
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <PasswordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <Skeleton width={300} height={20} variant="rectangular" />
                <Skeleton width={300} height={10} variant="rectangular" />
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <SupervisorAccountOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <Skeleton width={300} height={20} variant="rectangular" />
                <Skeleton width={300} height={10} variant="rectangular" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="profil">
          <div className="header">
            <Avatar className="ico" />
            <span className="username">
              {`${data?.nom} ${data?.postnom} ${data?.prenom}`}
            </span>

            <span className="email">{email}</span>

            <button
              onClick={() => {
                setOpenEdit(true);
              }}
            >
              Modifier
            </button>
          </div>
          <div className="body">
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Pays</span>
                <div className="second_info">
                  <span>
                    <span>
                      {COUNTRY_CODES_EN[pays]
                        ? COUNTRY_CODES_EN[pays].primary
                        : data?.pays}
                      / {data?.ville}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <LocationOnOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Adresse</span>
                <div className="second_info">
                  <span>
                    {data?.ville}, {data?.quartier} / Avenue {data?.avenue}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <PhoneIphoneOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Téléphone</span>
                <div className="second_info">
                  <span>{data?.telephone}</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <PasswordOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Mot de passe</span>
                <div className="second_info">
                  <span>
                    ••••••••••••••••
                    <LockOpen
                      className="ico"
                      onClick={() => setOpenPsw(true)}
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <SupervisorAccountOutlinedIcon className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Rôle</span>
                <div className="second_info">
                  <span>{role}</span>
                </div>
              </div>
            </div>
          </div>
          {openEdit && (
            <EditProfilDialog
              setOpenEdit={setOpenEdit}
              data={data}
              forceReloadOnUpdate={forceReloadOnUpdate}
            />
          )}
          {openPsw && (
            <UpdatePswd
              setOpen={setOpenPsw}
              data={data}
              forceReloadOnUpdate={forceReloadOnUpdate}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Profil;
