import React from "react";
import "./successdialog.scss";
import { Close } from "@mui/icons-material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

type PropsType = {
  title: string;
};
const SuccessDialog = ({ title }: PropsType) => {
  return (
    <div className="success_cmd_dialog">
      <div className="dialog">
        <div className="header">{/* <Close className='ico'/> */}</div>
        <div className="body">
          <div>
            <div>
              <DoneOutlinedIcon className="ico" />
            </div>
          </div>
          <span> {title} </span>
        </div>
      </div>
    </div>
  );
};

export default SuccessDialog;
