import React from "react";
import "./supplieraliment.scss";
import SearchBar from "../../search/Search";
import List from "./List";

const SupplierAliment = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");

  return (
    <div className="table_supplier_aliment">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        {/* <div className="btns">
          <span className="status validate">Active 10</span>
          <span className="status pending">Non active 100</span>
        </div> */}
      </div>
      <List searchValue={searchValue} />
    </div>
  );
};

export default SupplierAliment;
