import React, { useState } from "react";
import "./orderbiogaz.scss";
import SearchBar from "../../search/Search";
import List from "./List";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { STATUS_ORDER, STATUS_ORDER_ID } from "../../../_service/_const";
import { orderSpecType } from "../../../types/GlobalType";
import DialogList from "../_orderDialog/DialogList";
import {
  AccessTimeOutlined,
  AttachFile,
  Autorenew,
  CancelOutlined,
  DoneOutline,
  FileDownloadRounded,
} from "@mui/icons-material";

const OrderBiogaz = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [sortedData, setSortedData] = useState<orderSpecType[] | any>([]);
  const [statusOrder, setStatusOrder] = useState<string>("");
  const [open, setOpen] = React.useState<boolean>(false);
  const { biogazOrder } = useSelector((state: RootState) => state.order);

  let cmdEtablie: number = 0;
  let cmdReserve: number = 0;
  let cmdEtablieAvecPreuve: number = 0;
  let cmdRegle: number = 0;
  let cmdDette: number = 0;
  let cmdAnnule: number = 0;

  let cmdEtablieArr: orderSpecType[] | any = [];
  let cmdReserveArr: orderSpecType[] | any = [];
  let cmdEtablieAvecPreuveArr: orderSpecType[] | any = [];
  let cmdRegleArr: orderSpecType[] | any = [];
  let cmdDetteArr: orderSpecType[] | any = [];
  let cmdAnnuleArr: orderSpecType[] | any = [];

  if (biogazOrder) {
    for (let item of biogazOrder) {
      if (item.statusCmd_id === STATUS_ORDER_ID.ETABLI) {
        cmdEtablieArr.push(item);
        cmdEtablie++;
      }
      if (item.statusCmd_id === STATUS_ORDER_ID.RESERVE) {
        cmdReserveArr.push(item);
        cmdReserve++;
      }
      if (item.statusCmd_id === STATUS_ORDER_ID.ETABLIE_AVEC_PAIEMENT) {
        cmdEtablieAvecPreuveArr.push(item);
        cmdEtablieAvecPreuve++;
      }
      if (item.statusCmd_id === STATUS_ORDER_ID.REGLE) {
        cmdRegleArr.push(item);
        cmdRegle++;
      }
      if (item.statusCmd_id === STATUS_ORDER_ID.ETABLIE_AVEC_DETTE) {
        cmdDetteArr.push(item);
        cmdDette++;
      }
      if (item.statusCmd_id === STATUS_ORDER_ID.ANNULE) {
        cmdAnnuleArr.push(item);
        cmdAnnule++;
      }
    }
  }

  const handleOpenDialog = (arr: orderSpecType, status: string) => {
    setSortedData(arr);
    setStatusOrder(status);
    setOpen(true);
  };

  return (
    <div className="orderall">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>

        <div className="btns">
          <div
            className="status pending"
            onClick={() => handleOpenDialog(cmdEtablieArr, STATUS_ORDER.ETABLI)}
          >
            <Autorenew className="ico" />
            <span>Etablie {cmdEtablie}</span>
          </div>

          <div
            className="status pending preuve"
            onClick={() =>
              handleOpenDialog(
                cmdEtablieAvecPreuveArr,
                STATUS_ORDER.ETABLIE_AVEC_PAIEMENT
              )
            }
          >
            <AttachFile className="ico" />
            <span>Etablie {cmdEtablieAvecPreuve}</span>
          </div>

          <div
            className="status reserved"
            onClick={() =>
              handleOpenDialog(cmdReserveArr, STATUS_ORDER.RESERVE)
            }
          >
            <FileDownloadRounded className="ico" />
            <span>Réservée {cmdReserve}</span>
          </div>

          <div
            className="status validate"
            onClick={() => handleOpenDialog(cmdRegleArr, STATUS_ORDER.REGLE)}
          >
            <DoneOutline className="ico" />
            <span>Réglée {cmdRegle}</span>
          </div>
          <div
            className="status dette"
            onClick={() =>
              handleOpenDialog(cmdDetteArr, STATUS_ORDER.ETABLIE_AVEC_DETTE)
            }
          >
            <AccessTimeOutlined className="ico" />
            <span>Dette {cmdDette}</span>
          </div>
          <div
            className="status canceled"
            onClick={() => handleOpenDialog(cmdAnnuleArr, STATUS_ORDER.ANNULE)}
          >
            <CancelOutlined className="ico" />
            <span>Annulée {cmdAnnule}</span>
          </div>
        </div>
      </div>
      {open && (
        <DialogList
          orders={sortedData}
          status={statusOrder}
          setOpen={setOpen}
        />
      )}
      <List searchValue={searchValue} />
    </div>
  );
};

export default OrderBiogaz;
