import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { useEffect } from "react";
import exportFromJSON from "export-from-json";
import { incubationType } from "../../../types/GlobalType";
import moment from "moment";
import _ from "lodash";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const { allIncubation } = useSelector((state: RootState) => state.incubation);

  let sortedData = _.orderBy(allIncubation, ["dateEntree"], ["desc"]);

  // console.log(allIncubation);
  // console.log(sortedData);

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT
  const contextBtnRef: any = useOutletContext();
  const handleClick = () => {
    const fileName = "NAGRITECH_Incubation";
    const exportType = "xls";
    const fields = [
      "id",
      "nom",
      "postnom",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avanue",
    ];
    exportFromJSON({ data: allIncubation, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef.current;
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);
  // ----------------------------------------------

  if (allIncubation.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-3">Nature</div>
            <div className="col col-3">Qte </div>
            <div className="col col-3">Date entrée</div>
            <div className="col col-3">Date sortie</div>
            <div className="col col-3">Durée</div>
            <div className="col col-4">Status</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-3">Nature</div>
          <div className="col col-3">Qte </div>
          <div className="col col-3">Date entrée</div>
          <div className="col col-3">Date sortie</div>
          <div className="col col-3">Durée</div>
          <div className="col col-4">Status</div>
        </li>

        {sortedData
          .filter(
            (item: incubationType) =>
              item?.nature_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.status_inc_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.dateEntree
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map(
            (value: incubationType, index: number) =>
              // <Link to={`/values/${value.nom}`} state={value}>
              {
                let now = moment();
                let dateOne = moment(value.dateEntree);
                let dateTwo = moment(now);
                let dayDiff: number = dateTwo.diff(dateOne, "day");

                if (dayDiff > 21) {
                  dayDiff = 21;
                }
                return (
                  <li className="table-row" key={index}>
                    <div className="col col-3" data-label="Id">
                      {`0${index + 1}`}
                    </div>
                    <div className="col col-3" data-label="Nature">
                      {value.nature_designation}
                    </div>
                    <div className="col col-3" data-label="Quantité">
                      {value.quantite}
                    </div>
                    <div className="col col-3" data-label="Date entrée">
                      {moment(value.dateEntree).format("L")}
                    </div>
                    <div className="col col-3" data-label="Date sortie">
                      {value.dateSortie
                        ? moment(value.dateSortie).format("L")
                        : "--"}
                    </div>
                    <div className="col col-3" data-label="Durée effectuée">
                      {dayDiff}/21 jrs
                    </div>
                    <div className="col col-4" data-label="Status">
                      {value.status_inc_designation}
                    </div>
                  </li>
                );
              }

            // </Link>
          )}
      </ul>
    </Box>
  );
}
