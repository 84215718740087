import React, { ChangeEvent, useRef, useState } from "react";
import "./messageNew.scss";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { NotificationType, requestType } from "../../types/GlobalType";
import { ERROR_MESSAGE, URL_BACKEND_APP } from "../../_service/_const";
import { notification_api } from "../../_service/notification.Call";
import { MessageOutlined } from "@mui/icons-material";
import ErrorBar from "../errorComp/ErrorBar";
import axios from "axios";
import MyLoadingv3 from "../loader/Loader_v3";

type Props = {
  setOpenNew: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReload: () => void;
};
const MessageNew = (props: Props) => {
  const [messageData, setMessageData] = useState<NotificationType>({
    titre: "",
    description: "",
  });

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [file, setfile] = useState<File | null>(null);

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setfile(files[0]);
    }
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });
    if (messageData.titre === "" || messageData.description === "") {
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter tous les champs",
      }));
    }

    createWithoutFile();
  };

  const createWithoutFile = () => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        titre: messageData.titre,
        description: messageData.description,
      };
      setState({ isLoading: true, errorMessage: null });

      notification_api
        .create(data, token)
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
            });
            props.setSuccess(true);
            props.setOpenNew(false);
            props.forceReload();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <div className="message_dialog">
      {<MyLoadingv3 isLoading={state.isLoading} />}
      <div className="dialog">
        <div className="header">
          <div className="header-left">
            <MessageOutlined className="ico" />
            {" Nouvelle notification"}
          </div>

          <div className="Right_send">
            <button className="cancel" onClick={() => props.setOpenNew(false)}>
              Annuler
            </button>
            <button className="submit" onClick={handleSubmit}>
              Envoyer
            </button>
          </div>
        </div>
        <div className="body">
          {state.errorMessage && <ErrorBar title={state.errorMessage} />}
          <input
            type="text"
            placeholder="Objet"
            value={messageData.titre}
            onChange={(e) =>
              setMessageData((prev) => ({ ...prev, titre: e.target.value }))
            }
          />
          <textarea
            placeholder="Message..."
            cols={30}
            rows={10}
            value={messageData.description}
            onChange={(e) =>
              setMessageData((prev) => ({
                ...prev,
                description: e.target.value,
              }))
            }
          ></textarea>
        </div>
        {/* {!state.isLoading && (
          <div className="upload">
            <input
              type="file"
              onChange={handleChangeFile}
              accept="image/png, image/jpeg"
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default MessageNew;
