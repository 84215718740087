import React from "react";
import "./ordersupplierlist.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowBackIosNew } from "@mui/icons-material";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";

const OrderSupplierList = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("orders");

  return (
    <div className="order_supplier_list_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNew className="ico" />
          </IconButton>
          <h4>
            Commandes fournisseurs
            {/* {urlRoute[3] === "cmd-f" && " oeuf"}
            {urlRoute[3] === "cmd-bgz" && " biogaz"}
            {urlRoute[3] === "cmd-alm" && " aliment bétail"} */}
          </h4>
        </div>
        <div className="right">
          <button>
            <VerticalAlignBottomOutlinedIcon className="ico" />
            <span>Exporter XLSX</span>
          </button>
        </div>
      </div>

      <div className="options">
        <Link to="/orders-suppliers/list">
          <div
            className={urlRoute[3] === "orders" ? "option_active" : "option"}
          >
            <span>Tous</span>
            <span className="count">100</span>
            <div className="underline1"></div>
          </div>
        </Link>
        <Link to="/orders-suppliers/list/cmd-of">
          <div
            className={urlRoute[3] === "cmd-pss" ? "option_active" : "option"}
          >
            <span>Oeuf</span>
            <span className="count">12</span>
            <div className="underline2"></div>
          </div>
        </Link>
        <Link to="/orders-suppliers/list/cmd-bgz">
          <div
            className={urlRoute[3] === "cmd-bgz" ? "option_active" : "option"}
          >
            <span>Biogaz</span>
            <span className="count">12</span>
            <div className="underline3"></div>
          </div>
        </Link>
        <Link to="/orders-suppliers/list/cmd-alm">
          <div
            className={urlRoute[3] === "cmd-alm" ? "option_active" : "option"}
          >
            <span>Aliments</span>
            <span className="count">12</span>
            <div className="underline4"></div>
          </div>
        </Link>
      </div>
      <div className="container">
        <Outlet />
      </div>
    </div>
  );
};

export default OrderSupplierList;
