import React from "react";
import "./notifications.scss";
import { Link, Outlet, useLocation } from "react-router-dom";
import { HelpOutline, SummarizeOutlined } from "@mui/icons-material";
import SuccessAlert from "../../components/alert/AlerteBar";
import { ProtectedSectionForAdmin_Log } from "../../_service/WithPermission";

// const names = [
//   "Semaine en cours",
//   "30 Derniers jours",
//   "Tous",
//   "Année spécifique",
// ];
// const years = ["2024", "2022", "2021"];

export type ObjectType = {
  id?: string | number;
  question?: string;
  answer?: string;
};

const Notifications = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("rapports");
  const [openSuccessUpdateRappport, setOpenSuccessUpdateRappport] =
    React.useState<Boolean>(false);

  // const [triPeriod, setTriePeriod] = React.useState<string>("Semaine en cours");
  // const [yearPeriod, setYearPeriod] = React.useState<string | null>()

  return (
    <>
      <div className="notifications">
        <div className="header">
          <div className="left">
            <h4>
              {urlRoute[2] === "rapports" && "Rapports"}
              {urlRoute[2] === "systemes" && "Notification système"}
              {urlRoute[2] === "requetes" && "Requêtes"}
            </h4>
          </div>
        </div>

        <div className="options">
          <div className="left">
            <Link to="/rapports">
              <div
                className={
                  urlRoute[2] === "rapports" ? "option_active" : "option"
                }
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SummarizeOutlined style={{ fontSize: 15 }} />
                  <span>Rapports</span>
                </div>

                {/* <span className="count">100</span> */}
                <div className="underline1"></div>
              </div>
            </Link>
            {/* <Link to="/rapports/systemes">
            <div
              className={
                urlRoute[2] === "systemes" ? "option_active" : "option"
              }
            >
              <span>Alerte</span>
              <span className="count">72</span>
              <div className="underline2"></div>
            </div>
          </Link> */}

            <ProtectedSectionForAdmin_Log>
              <Link to="/rapports/requetes">
                <div
                  className={
                    urlRoute[2] === "requetes" ? "option_active" : "option"
                  }
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <HelpOutline style={{ fontSize: 15 }} />
                    <span>Requêtes</span>
                  </div>
                  <div className="underline3"></div>
                </div>
              </Link>
            </ProtectedSectionForAdmin_Log>
          </div>
          {/* <div className="right">
          {triPeriod === names[names.length - 1].toLocaleLowerCase() && (
            <div className={urlRoute[2] === "requetes" ? "hidden" : ""}>
              <select>
                {years.map((value, index) => (
                  <option key={index} value={value.toLowerCase()}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className={urlRoute[2] === "requetes" ? "hidden" : "select"}>
            <select
              onChange={(e) => setTriePeriod(e.target.value)}
              defaultValue={names[0]}
            >
              {names.map((value, index) => (
                <option key={index} value={value.toLowerCase()}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        </div>
        <div className="container">
          <Outlet context={setOpenSuccessUpdateRappport} />
        </div>
      </div>

      {openSuccessUpdateRappport && (
        <SuccessAlert
          setOpen={setOpenSuccessUpdateRappport}
          label={"Traitement effectué avec success"}
          theme={"success"}
        />
      )}
    </>
  );
};

export default Notifications;
