import React from "react";
import "./cardrecent.scss";
import { ArrowForwardIos, ArrowForwardIosOutlined } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import SingleSale from "../sorties/dialog/SingleSale";
import { ERROR_MESSAGE, MOTIF_SORTIE } from "../../_service/_const";
import {
  addAlimentSortie,
  addAllSortie,
  addBiogazSortie,
  addOeufSortie,
  addPouleSortie,
  addPouletSortie,
  addPoussinSortie,
} from "../../redux/sortieStockSlice.ts/sortieStockSlice";
import { sortie_stock_api } from "../../_service/sortieStock.Caller";
import { useDispatch, useSelector } from "react-redux";
import { requestType, sortieType } from "../../types/GlobalType";
import { RootState } from "../../redux/store";
import _ from "lodash";
import { Skeleton } from "@mui/material";
import moment from "moment";
import SkeletonRight from "../loader/SkeletonRight";

const CardRecent = () => {
  const [openDetail, setOpenDetail] = React.useState<boolean>(false);
  const [data, setData] = React.useState<sortieType | any>();
  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();

  const {
    alimentSortie,
    biogazSortie,
    oeufSortie,
    poussinSortie,
    pouleSortie,
    pouletSortie,
  } = useSelector((state: RootState) => state.sortieStock);

  let allSortie: sortieType[] = [];

  if (alimentSortie.length > 0) {
    alimentSortie.forEach((value) => allSortie.push(value));
  }
  if (biogazSortie.length > 0) {
    biogazSortie.forEach((value) => allSortie.push(value));
  }
  if (oeufSortie.length > 0) {
    oeufSortie.forEach((value) => allSortie.push(value));
  }
  if (poussinSortie.length > 0) {
    poussinSortie.forEach((value) => allSortie.push(value));
  }
  if (pouleSortie.length > 0) {
    pouleSortie.forEach((value) => allSortie.push(value));
  }
  if (pouletSortie.length > 0) {
    pouletSortie.forEach((value) => allSortie.push(value));
  }

  // dispatch(addAllSortie(allSortie));

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let response = await sortie_stock_api.sortie.getAllByService(token);

        const [
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
        ] = [
          response[0],
          response[1],
          response[2],
          response[3],
          response[4],
          response[5],
        ];

        let arrOb = {
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
        };

        // console.log(arrOb);

        dispatch(addAlimentSortie(dataAliment.data.data));
        dispatch(addBiogazSortie(dataBio.data.data));
        dispatch(addOeufSortie(dataOeuf.data.data));
        dispatch(addPoussinSortie(dataPoussin.data.data));
        dispatch(addPouleSortie(dataPoule.data.data));
        dispatch(addPouletSortie(dataPoulet.data.data));

        let mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
          ...dataPoule.data.data,
          ...dataPoulet.data.data,
        ];
        dispatch(addAllSortie(mergedData));

        // console.log(mergedData);

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  let recentVente: sortieType[] | any[] = [];
  if (allSortie.length > 0) {
    recentVente = _.filter(allSortie, {
      motif_designation: MOTIF_SORTIE.vente_comptant,
    });
  }
  React.useEffect(() => {
    fetchAll();
  }, []);

  const skeletonRow = <Skeleton width={150} height={40} />;
  const skeletonSubRow = <Skeleton width={130} height={20} />;
  const skeletonBox = <Skeleton width={50} height={40} />;

  const SK: any[] = new Array(8).fill(
    <li>
      <div className="item">
        <div className="left">
          <span>{skeletonRow}</span>
          <span>{skeletonSubRow}</span>
        </div>
        <div className="plus_btn">
          {/* <ArrowForwardIos className="ico" /> */}
          {skeletonBox}
        </div>
      </div>
    </li>
  );
  return (
    <>
      <div className="card_recent_store">
        {!state.isLoading && (
          <>
            <ul>
              {recentVente
                .slice(0, 6)
                .map((item: sortieType, index: number) => (
                  <li
                    key={index}
                    onClick={() => {
                      setData(item);
                      setOpenDetail(true);
                    }}
                  >
                    <div className="item">
                      <div className="left">
                        <span className="title">
                          Vente {item.nature_designation}
                        </span>
                        <div className="item_detail">
                          <span> Quantite: {item.quantite}</span>&nbsp;&nbsp;
                          {"-"}
                          <span className="date">
                            {moment(item.date).format("LL")}
                          </span>
                        </div>
                      </div>
                      <div
                        className="plus_btn"
                        onClick={() => setOpenDetail(true)}
                      >
                        <ArrowForwardIos className="ico" />
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
            <Link to="/sortie">
              <div className="footer">
                <span>Toutes les ventes</span>
                <ArrowForwardIosOutlined className="ico" />
              </div>
            </Link>
          </>
        )}
        {state.isLoading && (
          <ul>
            <SkeletonRight />
          </ul>
        )}
        {/* {state.isLoading && <ul>{SK.map((item) => item)}</ul>} */}
      </div>
      {openDetail && <SingleSale setOpen={setOpenDetail} data={data} />}
    </>
  );
};

export default CardRecent;
export const MemorizedCardRecent = React.memo(CardRecent);
