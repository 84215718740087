import { Box } from "@mui/material";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import "./list.scss";
import { Link, useOutletContext } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { ClientType } from "../../../types/UserTypes";
import exportFromJSON from "export-from-json";
import { useEffect } from "react";
import _, { capitalize } from "lodash";

interface Props {
  searchValue: string;
}
export default function List(props: Props) {
  const clients = useSelector((state: RootState) => state.client.allClient);

  let orderedData = _.orderBy(clients, ["created_at"], ["desc"]);

  //HANDLE BUTTONREF TO EXPORT DATA N EXCEL FORMAT

  const contextBtnRef: any = useOutletContext();
  // console.log(contextBtnRef);
  const handleClick = () => {
    const fileName = "NAGRITECH_CLIENTS_TABLE";
    const exportType = "xls";
    const fields = [
      "nom",
      "postnom",
      "nom_entreprise",
      "titre",
      "sexe",
      "telephone",
      "email",
      "pays",
      "ville",
      "commune",
      "quartier",
      "avenue",
    ];
    exportFromJSON({ data: clients, fileName, fields, exportType });
  };

  useEffect(() => {
    const button = contextBtnRef.current;
    if (button) {
      button.addEventListener("click", handleClick);
    }
    return () => {
      if (button) {
        button.removeEventListener("click", handleClick);
      }
    };
  }, []);

  if (clients.length < 1) {
    return (
      <Box sx={{ width: "100%" }} className="table_list">
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Id</div>
            <div className="col col-2">Client / Entreprise </div>
            <div className="col col-3">Contact</div>
            {/* <div className="col col-4">Création</div> */}
            <div className="col col-4">Genre</div>
            <div className="col col-4">Status</div>
          </li>
          <li className="table-row">
            <NoData />
          </li>
        </ul>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%" }} className="table_list">
      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-3">Id</div>
          <div className="col col-2">Client / Entreprise </div>
          <div className="col col-3">Contact</div>
          {/* <div className="col col-4">Création</div> */}
          <div className="col col-4">Genre</div>
          <div className="col col-4">Ville</div>
        </li>

        {orderedData
          .filter(
            (item: ClientType) =>
              item.nom
                .toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item.postnom
                .toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.prenom
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.nom_entreprise
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.ville
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase()) ||
              item?.telephone
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
          )
          .map((client, index) => (
            <Link to={`/clients/${client.nom}`} state={client} key={index}>
              <li className="table-row">
                <div className="col col-3" data-label="Id">
                  {`0${index + 1}`}
                </div>
                <div className="col col-2 Ese" data-label="Client">
                  {!client.is_legal_person ? (
                    `${client.nom.toUpperCase()} ${client.prenom?.toUpperCase()}`
                  ) : (
                    <>
                      {client.nom_entreprise?.toUpperCase()}
                      <span className="container-Ese">
                        {" "}
                        <span className="Ese">e</span>
                      </span>
                    </>
                  )}
                </div>
                <div className="col col-3" data-label="Contact">
                  {client.telephone}
                </div>
                {/* <div className="col col-4" data-label="Service">
              {client.created_at}
            </div> */}
                <div className="col col-4" data-label="Genre">
                  {client.is_legal_person ? <>--</> : client.sexe}
                </div>
                <div className="col col-4" data-label="Ville">
                  {capitalize(client.ville)}
                </div>
              </li>
            </Link>
          ))}
      </ul>
    </Box>
  );
}
