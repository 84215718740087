import { configureStore } from "@reduxjs/toolkit";
import clientSlice from "./clientSlice/clientSlice";
import supplierSlice from "./supplierSlice/supplierSlice";
import orderSlice from "./orderSlice/orderSlice";
import stockSlice from "./stockSlice/stockSlice";
import entreeStockSlice from "./entreeStockSlice.ts/entreeStockSlice";
import sortieStockSlice from "./sortieStockSlice.ts/sortieStockSlice";
import natureSlice from "./natureSlice/natureSlice";
import incubationSlice from "./incubationSlice/incubationSlice";
import rapportSlice from "./rapportSlice/rapportSlice";

export const store = configureStore({
  reducer:{
    client:clientSlice,
    supplier:supplierSlice,
    order:orderSlice,
    stock:stockSlice,
    entreeStock: entreeStockSlice,
    sortieStock: sortieStockSlice,
    nature:natureSlice,
    incubation:incubationSlice,
    rapport:rapportSlice
  }
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>