export const APP_CONST = {
  APP_NAME: "NagriTech",
  defaultPassword: "1234",
};
// export const URL_BACKEND_APP = "https://api-nagritech-v1.ehula.tech";
// export const URL_BACKEND_APP = "http://nagritech_v2.ehula.tech";
// export const URL_BACKEND_APP = "http://api-nagritech-v1-test.50webs.org/";
export const URL_BACKEND_APP = "https://api-nagritech-v1.50webs.org";
export const ERROR_MESSAGE = {
  networkError: "Network Error",
  unknownError: "Une erreur inconnue s'est produit, réessayer",
};

export const SUCCESS_MESSAGE = {
  success: "success",
};

export const CATEGORY_NATURE = {
  aliment: "aliment",
  biogaz: "biogaz",
  oeuf: "oeuf",
  poussin: "poussin",
  poule: "poule",
  poulet: "poulet",
  // chambre_froide: "chambre froid",
};

export const CATEGOTY_ID = {
  ALIMENT: 1,
  BIOGAZ: 2,
  OEUF: 3,
  POUSSIN: 4,
  POULE: 5,
  POULET: 6,
};
export const STATUS_RAPPORT = {
  ETABLI: "ETABLI",
  A_REVISER: "A REVISER",
  ANNULE: "ANNULE",
  VALIDE: "VALIDE",
};
export const STATUS_RAPPORT_ID = {
  ETABLI: 1,
  A_REVISER: 2,
  ANNULE: 3,
  VALIDE: 4,
};
export const ETAT_RAPPORT = {
  TRES_BON: "TRES BON",
  BON: "BON",
  MAUVAIS: "MAUVAIS",
  TRES_MAUVAIS: "TRES MAUVAIS",
  MORT: "MORT",
};
export const ETAT_RAPPORT_ID = {
  TRES_BON: 1,
  BON: 2,
  MAUVAIS: 3,
  TRES_MAUVAIS: 4,
  MORT: 5,
};
export const STATUS_INCUBATION = {
  ENCOURS: "ENCOURS...",
  BIENTOT: "BIENTOT",
  TERMINE: "TERMINE",
  SORTIE: "SORTIE",
  ANNULE: "ANNULE",
};
export const STATUS_INCUBATION_ID = {
  ENCOURS: 1,
  BIENTOT: 2,
  TERMINE: 3,
  SORTIE: 4,
  ANNULE: 5,
};
export const STATUS_ORDER_ID = {
  ETABLI: 1,
  ETABLIE_AVEC_PAIEMENT: 2,
  RESERVE: 3,
  REGLE: 4,
  ANNULE: 5,
  ETABLIE_AVEC_DETTE: 6,
};
export const STATUS_ORDER = {
  ETABLI: "établie",
  ETABLIE_AVEC_PAIEMENT: "établie avec preuve",
  RESERVE: "réservée",
  REGLE: "réglée",
  ANNULE: "annulée",
  ETABLIE_AVEC_DETTE: "établie avec dette",
};
export const SERVICE_ID = {
  ADMINISTRATION: 1,
  LOGISTIQUE: 2,
  FINANCE: 3,
  AGENT: 4,
};
export const SERVICE = {
  ADMINISTRATION: "Administration",
  LOGISTIQUE: "Logistique",
  FINANCE: "Finance",
  AGENT: "Agent",
};
export const USER_ROLE = {
  ADMINISTRATION: "Admin",
  LOGISTIQUE: "Log",
  FINANCE: "Financier",
};

export const ORDER_STATUS_ = {
  one: {
    value: 1,
    designatoin: "établie",
  },
  two: {
    value: 2,
    designatoin: "réservée",
  },
  four: {
    value: 4,
    designatoin: "réglée",
  },
  five: {
    value: 5,
    designatoin: "annulée",
  },
  six: {
    value: 6,
    designatoin: "établie avec preuve paiement",
  },
};

export const MOTIF_SORTIE = {
  vente_comptant: "VENTE AU COMPTANT",
  vente_credit: "VENTE A CREDIT",
  // on enleve ca car liée au status %partiellement reglee%
  achat_comptant: "ACHAT AU COMPTANT",
  achat_credit: "ACHAT A CREDIT",
  sortie_mauvais_etat: "SORTIE POUR MAUVAIS ETAT",
  sortie_tres_mauvais_etat: "SORTIE POUR TRES MAUVAIS ETAT",
  sortie_mort: "SORTIE POUR ETAT MORT",
  sortie_incubation: "SORTIE POUR INCUBATION",
  produit_incubation: "PRODUIT DE L'INCUBATION",
};
