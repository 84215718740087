import React from "react";
import "./delete.scss";
import Close from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";

type Props = {
  label: string;
  id: string | number | undefined;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  handleDelete: (id: string | number | undefined) => void;
  loadingDeleteBtn: boolean;
  setLoadingDeleteBtn: React.Dispatch<React.SetStateAction<boolean>>;
};
const Delete = (props: Props) => {
  return (
    <div className="delete_dial_box">
      <div className="dialog">
        <div className="header">
          <span>Suppression</span>
          <Close className="ico" onClick={() => props.setOpen(false)} />
        </div>
        <div className="body">
          <div className="content">
            <p>{props.label} </p>
          </div>
          <div className="del_Btns">
            {!props.loadingDeleteBtn && (
              <>
                <button onClick={() => props.setOpen(false)}>Annuler</button>
                <button
                  onClick={() => {
                    props.setLoadingDeleteBtn(true);
                    props.handleDelete(props.id);
                    // props.setOpen(false);
                  }}
                >
                  Suprimer
                </button>
              </>
            )}
            {props.loadingDeleteBtn && (
              <>
                <button disabled>Annuler</button>
                <button disabled>
                  <CircularProgress size={15} />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delete;
