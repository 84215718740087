// import { Box } from "@mui/material";
import "./dialogList.scss";
import { Link } from "react-router-dom";
import NoData from "../../errorComp/NoData";
import { OrderType, orderSpecType } from "../../../types/GlobalType";
// import { ORDER_STATUS_ } from "../../../_service/_const";
import { Close } from "@mui/icons-material";
import moment from "moment";

interface Props {
  // searchValue: string;
  orders: orderSpecType[] | any;
  status: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function List(props: Props) {
  const { orders } = props;
  if (orders.length < 1) {
    return (
      <div className="dialog_list_">
        <div className="content">
          <div className="titleHeader">
            <span>Commande {props.status}</span>
            <div className="closeBtn" onClick={() => props.setOpen(false)}>
              <Close className="ico" />
            </div>
          </div>
          <ul className="responsive-table">
            <li className="table-header">
              <div className="col col-3">Num</div>
              <div className="col col-3">Nature</div>
              <div className="col col-4">Qté</div>
              <div className="col col-2">Client</div>
              <div className="col col-4">création</div>
              <div className="col col-4">livraison</div>
            </li>
            <li className="table-row body">
              <NoData />
            </li>
          </ul>
        </div>
      </div>
    );
  }
  return (
    <div className="dialog_list_">
      <div className="content">
        <div className="titleHeader">
          <span>Commande {props.status}</span>
          <div className="closeBtn" onClick={() => props.setOpen(false)}>
            <Close className="ico" />
          </div>
        </div>
        <ul className="responsive-table">
          <li className="table-header">
            <div className="col col-3">Num</div>
            <div className="col col-3">Nature</div>
            <div className="col col-4">Qté</div>
            <div className="col col-2">Client</div>
            <div className="col col-4">création</div>
            <div className="col col-4">livraison</div>
          </li>

          {orders
            // .filter((item: OrderType) =>
            //   item?.nom?.toLowerCase().includes(props.searchValue.toLowerCase())
            // )
            .map((order: OrderType, index: number) => (
              <Link to={`/orders/${order.nom}`} state={order}>
                <li className="table-row body" key={index}>
                  <div className="col col-3" data-label="Id">
                    {`0${index + 1}`}
                  </div>
                  <div className="col col-3" data-label="Nature">
                    {order.nature_designation}
                  </div>
                  <div className="col col-4" data-label="Quantité">
                    {order.quantite}
                  </div>
                  <div className="col col-2 Ese" data-label="Client">
                    {!order.is_legal_person ? (
                      `${order.nom} ${order.prenom}`
                    ) : (
                      <>
                        {order.nom_entreprise}
                        <span className="container-Ese">
                          <span className="Ese">e</span>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="col col-4" data-label="Date commande">
                    {moment(order.created_at?.split(" ")[0]).format("LL")}
                  </div>
                  <div className="col col-4" data-label="Date livraison">
                    {moment(order.created_at?.split(" ")[0]).format("LL")}
                  </div>
                </li>
              </Link>
            ))}
        </ul>
      </div>
    </div>
  );
}

{
  /* <Dialog fullWidth maxWidth="md" sx={{ height: "500px", top: "2rem" }}>
  <DialogTitle
    sx={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItem: "center",
    }}
  >
    <StyledIconTitle>
      <ChromeReaderModeRounded color="primary" sx={{ fontSize: 18 }} />
      <Typography
        variant="h6"
        color="primary"
        sx={{ fontWeight: "500", fontSize: 15 }}
      >
        Liste des agents
      </Typography>
    </StyledIconTitle>
  </DialogTitle>
  <Divider />
  <DialogContent>
    <Tableau userData={userData} />
  </DialogContent>
  <DialogActions
    sx={{ display: "flex", flexDirection: "row", justifyContent: "end" }}
  >
    {/* <Button variant='text' startIcon={<PrintRounded color='primary' />} onClick={handleClickPrint} color='primary'> Imprimer </Button> */
}

//     <Button variant="text" onClick={() => setOpen3(false)} color="warning">
//       Quitter
//     </Button>
//   </DialogActions>
// </Dialog>; */}
