import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./circularchart.scss";
import { LinearProgress, Skeleton } from "@mui/material";
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
} from "@mui/icons-material";
import moment from "moment";
import { useSelector } from "react-redux";
import { STATUS_ORDER_ID } from "../../../_service/_const";
import { calculPercentage } from "../../../_utils/fonctions";
import { OrderType } from "../../../types/GlobalType";
import { RootState } from "../../../redux/store";

type PropsType = {
  isLoading: boolean;
};

const CircularChart = (props: PropsType) => {
  const calculatePreviousMonth = (currMonth: number) => {
    if (currMonth === 0) return 11;
    return currMonth - 1;
  };

  const { allOrder } = useSelector((state: RootState) => state.order);
  var now = moment();
  var currentYear = moment(now).year();

  //ollorders of current year
  let ordersOfCurrentYear: OrderType[] = [];
  if (allOrder.length > 0) {
    allOrder.forEach((value: OrderType) => {
      if (value.date?.includes(currentYear.toString()))
        ordersOfCurrentYear.push(value);
    });
  }

  //-----------------------------------------------
  //quantity of current  command()
  let order_QtyOfCurrentMonth = 0;
  let order_QtyOfLastMonth = 0;

  //quantity of command sold(commande reglées)
  let orderSold_QtyOfCurrentMonth = 0;
  let orderSold_QtyOfLastMonth = 0;

  if (ordersOfCurrentYear.length > 0) {
    var currentMonth = moment(now).month(); //[0-11]
    var lastMonth = calculatePreviousMonth(currentMonth);

    for (let item of ordersOfCurrentYear) {
      let month = moment(item.date).month();
      switch (month) {
        case currentMonth:
          order_QtyOfCurrentMonth++;
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE)
            orderSold_QtyOfCurrentMonth++;
          break;
        case lastMonth:
          order_QtyOfLastMonth++;
          if (item.statusCmd_id === STATUS_ORDER_ID.REGLE)
            orderSold_QtyOfLastMonth++;
          break;
        default:
          break;
      }
    }
  }

  //-----------------------------------------------
  //calculate percentage progression
  const percentageCurrentMonth = calculPercentage(
    order_QtyOfCurrentMonth,
    orderSold_QtyOfCurrentMonth
  );
  const percentageLastMonth = calculPercentage(
    order_QtyOfLastMonth,
    orderSold_QtyOfLastMonth
  );

  let percentageProgress: number = percentageCurrentMonth - percentageLastMonth;
  let progression; // progression label

  if (percentageProgress > 0) {
    progression = "positive";
  } else if (percentageProgress === 0) {
    progression = "null";
  } else {
    progression = "negative";
  }

  const percentageProgressToPositive =
    percentageProgress < 0 ? percentageProgress * -1 : percentageProgress;

  const state: any = {
    series: [percentageProgressToPositive],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 225,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
            dropShadow: {
              enabled: true,
              top: 3,
              left: 0,
              blur: 4,
              opacity: 0.24,
            },
          },
          track: {
            background: "#fff",
            strokeWidth: "67%",
            margin: 0, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: -3,
              left: 0,
              blur: 4,
              opacity: 0.35,
            },
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "13px",
            },
            value: {
              formatter: function (val: string) {
                return parseInt(val);
              },
              color: "#111",
              fontSize: "36px",
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#ABE5A1"],
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      stroke: {
        lineCap: "round",
      },
      labels: ["Pourcentage"],
    },
  };

  return (
    <>
      {props.isLoading ? (
        <div className="chartCircularContainer_sk">
          <div className="chartHeader">
            <span className="title">
              <Skeleton width={80} />
            </span>
          </div>
          <Skeleton width={150} height={50} sx={{ margin: 0 }} />
          <Skeleton width={150} height={50} />
          <span className="title">
            <Skeleton width={80} />
          </span>
          <Skeleton width={150} height={50} />
        </div>
      ) : (
        <div className="chartCircularContainer">
          <div className="chartHeader">
            <span className="title">Proportion vente & cmd</span>
          </div>
          <div className="box1">
            <div className="header">
              <div className="left">
                <span className="s_tt">Mois actuel</span>
                {/* <span className="s_tt">Vente/commande</span> */}
              </div>
              <div className="count">
                {orderSold_QtyOfCurrentMonth}/{order_QtyOfCurrentMonth}
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              value={percentageCurrentMonth}
              sx={{ height: 5, borderRadius: 1 }}
            />
            <div className="footer">
              <span className="progress">Progression</span>{" "}
              <span className="max_progresson">100%</span>
            </div>
          </div>
          <div className="box1">
            <div className="header">
              <div className="left">
                {/* <span className="tt">total vente</span> */}
                <span className="s_tt">Mois dernier</span>
              </div>
              <div className="count">
                {orderSold_QtyOfLastMonth}/{order_QtyOfLastMonth}
              </div>
            </div>
            <LinearProgress
              variant="determinate"
              value={percentageLastMonth}
              sx={{ height: 5, borderRadius: 1 }}
            />
            <div className="footer">
              <span className="progress">Progression</span>
              <span className="max_progresson">100%</span>
            </div>
          </div>
          <div className="progression">
            <div className="progress">
              <span className="croiss">
                Progression Mois Actuel ({`${percentageProgressToPositive} `}%)
              </span>
              {progression === "positive" && (
                <KeyboardDoubleArrowUp className="ico_up" />
              )}
              {progression === "negative" && (
                <KeyboardDoubleArrowDown className="ico_down" />
              )}
            </div>
            <ReactApexChart
              options={state.options}
              series={state.series}
              type="radialBar"
              height={350}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CircularChart;
