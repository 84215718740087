// export const userServices = {
//   getConnectedUserInfo: () => {
//     let user = localStorage.getItem("user");
//     return JSON.parse(user);
//   },

//   getUserType: () => {
//     let user = localStorage.getItem("user");
//     let type = JSON.parse(user).user.type;
//     return type;
//   },
// };

import React from "react";
import { Navigate } from "react-router-dom";
import { USER_ROLE } from "./_const";

type Props = {
  roleRequired?: "Admin" | "Log" | "Financier" | "Agent" | string;
  children: React.ReactNode;
  message?: string;
};

export const userRole = () => {
  let user: any = null;
  const _user = localStorage.getItem("user") || null;
  if (_user) {
    user = JSON.parse(_user).user;
  }
  if (user) {
    return user.type;
  } else {
    return null;
  }
};

export const ProtectedRouteFor_ = (props: Props) => {
  const { roleRequired, children } = props;
  const role = userRole();
  return <>{roleRequired === role ? children : <Navigate to="/forbiden" />}</>;
};

export const ProtectedRouteFor_Admin_Log = (props: Props) => {
  const { children } = props;
  const role = userRole();
  if (role === USER_ROLE.ADMINISTRATION || role === USER_ROLE.LOGISTIQUE)
    return <>{children}</>;
  else return <Navigate to="/forbiden" />;
};
export const ProtectedRouteFor_Admin_Log_Fin = (props: Props) => {
  const { children } = props;
  const role = userRole();
  if (
    role === USER_ROLE.ADMINISTRATION ||
    role === USER_ROLE.LOGISTIQUE ||
    role === USER_ROLE.FINANCE
  )
    return <>{children}</>;
  else return <Navigate to="/forbiden" />;
};

export const ProtectedSectionFor = (props: Props) => {
  const { roleRequired, children, message } = props;
  const role = userRole();
  return (
    <>
      {roleRequired === role ? (
        children
      ) : (
        <>{message ? <span>{message}</span> : null}</>
      )}
    </>
  );
};

export const ProtectedSectionForAdmin_Log = (props: Props) => {
  const { children, message } = props;
  const role = userRole();

  return (
    <>
      {role === USER_ROLE.ADMINISTRATION || role === USER_ROLE.LOGISTIQUE ? (
        children
      ) : (
        <>{message ? <span>{message}</span> : null}</>
      )}
    </>
  );
};
export const ProtectedSectionForAdmin_Log_Fin = (props: Props) => {
  const { children, message } = props;
  const role = userRole();

  return (
    <>
      {role === USER_ROLE.ADMINISTRATION ||
      role === USER_ROLE.LOGISTIQUE ||
      role === USER_ROLE.FINANCE ? (
        children
      ) : (
        <>{message ? <span>{message}</span> : null}</>
      )}
    </>
  );
};
