import React from "react";
import "./orderaliment.scss";
import SearchBar from "../../search/Search";
import List from "./List";

const OrderSupplierAliment = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");

  return (
    <div className="orderaliment">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>
        <div className="btns">
          <span className="status canceled">Annulée 10</span>
          <span className="status pending">Attente 100</span>
          <span className="status validate">Delivré 10</span>
        </div>
      </div>
      <List />
    </div>
  );
};

export default OrderSupplierAliment;
