import React from "react";
import "./connexionbar.scss";
import { ErrorOutline } from "@mui/icons-material";
export default function ConnexionBar() {
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <div className="connexionBar">
      <span className="sp1">
        <ErrorOutline className="ico" />
        Erreur de connexion; veuillez vous connecter à internet
      </span>
      <span className="sp2" onClick={handleReload}>
        Actualiser
      </span>
    </div>
  );
}
