import React, { useCallback, useEffect, useState } from "react";
import "./ordersupplier.scss";
import {
  AccountCircleOutlined,
  AddShoppingCartOutlined,
  ArrowBackIosNew,
  PhoneAndroidOutlined,
} from "@mui/icons-material";
import {
  TextField,
  IconButton,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import RecentOrder from "../../components/ordersupplier/RecentOrder";
import OrderCardDialog from "../../components/ordersupplier/OrderCardDialog";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CATEGOTY_ID, ERROR_MESSAGE } from "../../_service/_const";
import ConnexionBar from "../../components/errorComp/connexionBar";
import { natureType, requestType } from "../../types/GlobalType";
import SuccessDialog from "../../components/dialog/SuccessDialog";
import nature_api from "../../_service/nature.Caller";
import { entree_stock_api } from "../../_service/entreeStock.Caller";
import _ from "lodash";

// const options = ["Commande Client", "commande fournisseur"];

// const ITEM_HEIGHT = 30;

const OrderSupplier = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [orderNatureItem, setorderNatureItem] = React.useState<string | any>();
  const [orderNatureItemParse, setorderNatureItemParse] = React.useState<
    natureType | any
  >();

  const [allNatures, setAllNatures] = React.useState<natureType[]>([]);
  const [loading_allNature, setLoading_allNature] = useState<boolean>(true);
  const [openOrder, setopenOrder] = React.useState<boolean>(false);
  const [quantity, setQuantity] = React.useState<number>(1);
  // const [montant, setMontant] = React.useState<number>(0);
  const [natureId, setNatureId] = React.useState<number>(0); //example of natures("Poulet vif, bombon 6kg, etc")
  const [categorieId, setCategorieId] = React.useState<number>(0); //we have 6categories(oeuf, poussin, poule, poulet, biogaz,aliment)
  const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
  const [loadingBtn, setLoadingBtn] = React.useState<boolean>(false);

  const location = useLocation();

  const handleChangeOrderNatureItem = (e: any) => {
    setorderNatureItem(e.target.value);
    setorderNatureItemParse(JSON.parse(e.target.value));
    setNatureId(JSON.parse(e.target.value).id);
    setCategorieId(JSON.parse(e.target.value).cat_produit_id);
    // if (!!orderNatureItem) setorderNatureItemParse(JSON.parse(orderNatureItem));
  };

  let orderedData = _.orderBy(allNatures, ["designation"], ["asc"]);

  const handleSubmit = async () => {
    if (localStorage.getItem("user")) {
      setLoadingBtn(true);
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const data = {
        natures_idNature: natureId,
        fournisseur_id: location.state?.fournisseur_id,
        quantite: quantity,
        designation_lot: "Bloc 020305 ",
      };

      try {
        let response;
        switch (categorieId) {
          case CATEGOTY_ID.ALIMENT:
            response = await entree_stock_api.entree.createAliment(data, token);
            break;
          case CATEGOTY_ID.BIOGAZ:
            response = await entree_stock_api.entree.createBiogaz(data, token);
            break;
          case CATEGOTY_ID.OEUF:
            response = await entree_stock_api.entree.createOeuf(data, token);
            break;
          case CATEGOTY_ID.POUSSIN:
            response = await entree_stock_api.entree.createPoussin(data, token);
            break;
          case CATEGOTY_ID.POULE:
            response = await entree_stock_api.entree.createPoule(data, token);
            break;
          case CATEGOTY_ID.POULET:
            response = await entree_stock_api.entree.createPoulet(data, token);
            break;
          default:
            break;
        }

        // console.log(response);

        setLoadingBtn(false);

        setState({
          isLoading: false,
          errorMessage: null,
        });

        setOpenSuccess(true);
        setTimeout(() => {
          setOpenSuccess(false);
          navigate(-1);
        }, 3000);
      } catch (error: any) {
        // console.log(error);
        setLoadingBtn(false);
        // console.log(error);
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const fetchAllNature = useCallback(async () => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await nature_api.getAll(token);
        setAllNatures(response.data.data);

        setLoading_allNature(false);
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setLoading_allNature(false);
        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }, []);

  const noAction = () => null;

  useEffect(() => {
    fetchAllNature();
  }, []);

  return (
    <div className="order_page_supplier">
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNew className="ico" />
          </IconButton>
          <span className="title">Nouvelle Entree stock</span>
          <Link to="/orders/list">
            <span className="cmd_btn">Liste</span>
          </Link>
        </div>
      </div>
      <div className="container">
        <div className="left">
          <div className="commande">
            <h4> Identification du fournisseur</h4>
            {/* <div className="search">
              <SearchUserOrder />
            </div> */}
            <div className="form_user">
              {loading_allNature ? (
                <div className="user_input">
                  <div className="item">
                    <Skeleton width={200} height={40} />
                  </div>
                  <div className="item">
                    <Skeleton width={200} height={40} />
                  </div>
                </div>
              ) : (
                <div className="user_input">
                  <div className="item">
                    <div className="label">
                      <AccountCircleOutlined className="ico" />
                      <div className="info">
                        <span className="headerrr">Identité</span>
                        <span>{location.state.nom}</span>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">
                      <PhoneAndroidOutlined className="ico" />
                      <div className="info">
                        <span className="headerrr">Contact</span>
                        <span>{location.state?.telephone}</span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="total_reste">
              <span className="label_">Selectionner le produit</span>
            </div> */}

            {loading_allNature ? (
              <div className="header_com">
                <Skeleton width={200} height={40} />
                <Skeleton width={100} height={40} />
              </div>
            ) : (
              <div className="header_com">
                <h4> Description du produit</h4>
                <select
                  defaultValue={undefined}
                  value={orderNatureItem}
                  onChange={handleChangeOrderNatureItem}
                >
                  <option value="0">Selectionner Produit</option>
                  {orderedData.map((item: natureType | any, index: number) => (
                    <option key={index} value={JSON.stringify(item)}>
                      {item.designation}
                    </option>
                  ))}
                </select>
              </div>
            )}

            <div className="form_commande">
              {loading_allNature ? (
                // <div className="poussin">
                <Skeleton height={250} style={{ marginTop: "-40px" }} />
              ) : (
                // </div>
                <div className="poussin">
                  <div className="type_category">
                    <div className="item">
                      <span className="label">Type:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? orderNatureItemParse?.type
                          : "------"}
                      </span>
                    </div>
                    <div className="item">
                      <span className="label">Catégorie:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? orderNatureItemParse?.categorie
                          : "------"}
                      </span>
                    </div>
                  </div>
                  <div className="type_category">
                    <div className="item">
                      <span className="label">Unité de Vente:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? orderNatureItemParse?.mode
                          : "------"}
                      </span>
                    </div>
                    {/* <div className="item">
                      <span className="label">Prix Unitaire:</span>
                      <span className="value">
                        {orderNatureItemParse
                          ? `${orderNatureItemParse?.prixunitaire} ${orderNatureItemParse.devise}`
                          : "------"}
                      </span>
                    </div> */}
                  </div>
                  <div className="quantite_prixunitaire">
                    <TextField
                      label="Quantité"
                      id="outlined-size-small"
                      size="small"
                      inputProps={{
                        type: "number",
                        min: 1,
                      }}
                      value={quantity}
                      onChange={(e) => setQuantity(+e.target.value)}
                      fullWidth
                      sx={{ backgroundColor: "#fff" }}
                    />
                    {/* <TextField
                      label="Montant"
                      type="number"
                      id="outlined-size-small"
                      size="small"
                      value={montant}
                      onChange={(e) => setMontant(+e.target.value)}
                      fullWidth
                    /> */}
                  </div>
                </div>
              )}

              {!loadingBtn &&
                (loading_allNature ? (
                  <Skeleton height={60} style={{ marginTop: "-40px" }} />
                ) : (
                  <button
                    onClick={
                      categorieId !== 0 && categorieId !== undefined
                        ? handleSubmit
                        : noAction
                    }
                    disabled={
                      categorieId === 0 || categorieId === undefined
                        ? true
                        : false
                    }
                    className={
                      categorieId === 0 || categorieId === undefined
                        ? "disabled"
                        : "activated"
                    }
                  >
                    <span>Enregister l'entrée</span>
                    <AddShoppingCartOutlined className="ico" />
                  </button>
                ))}

              {loadingBtn && (
                <button className="activated">
                  <span>
                    <CircularProgress size={19} sx={{ color: "#fff" }} />
                  </span>
                  {/* <AddShoppingCartOutlined className="ico" /> */}
                </button>
              )}
            </div>
          </div>
        </div>
        <RecentOrder />
      </div>

      {/* {<SuccessDialog />} */}
      {openSuccess && <SuccessDialog title="Commande effectuée avec succes" />}

      {openOrder && <OrderCardDialog setOpen={setopenOrder} />}
    </div>
  );
};

export default OrderSupplier;
