import React, { useRef } from "react";
import "./incubation.scss";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import { ERROR_MESSAGE } from "../../_service/_const";
import { RotatingLines } from "react-loader-spinner";
import { requestType } from "../../types/GlobalType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";

import _ from "lodash";
import { IconButton } from "@mui/material";
import {} from "@mui/icons-material";
import incubator from "../../assets/categories/incubator.svg";
import incubation_api from "../../_service/incubation.Caller";
import { addallIncubation } from "../../redux/incubationSlice/incubationSlice";
import ConnexionError from "../../components/errorComp/Connexion";

const Incubation = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("incubation");

  const { allIncubation } = useSelector((state: RootState) => state.incubation);

  // const [data, setData] = React.useState<incubationType[]>([]);

  //state is loading start here with true as value cause there is a
  //settimeout before the fetch function to be executed
  const [state, setState] = React.useState<requestType>({
    isLoading: true,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchAllIncubation = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await incubation_api.getAll(token);
        dispatch(addallIncubation(response.data.data));
        // setData((prev) => [...prev, response.data.data]);

        setState({
          errorMessage: "",
          isLoading: false,
          succussMessage: "success",
        });
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      fetchAllIncubation();
    }, 2000);
  }, []);

  // const [data, setData] = React.useState<SupplierType[]>([]);
  // const [state, setState] = React.useState<requestType>({
  //   isLoading: false,
  //   errorMessage: null,
  // });
  // const [openAddNew, setOpenAddNew] = React.useState<boolean>(false);
  // const dispatch = useDispatch();
  // const suppliers = useSelector((state: RootState) => state.supplier);
  // const navigate = useNavigate();
  // const [successCreate, setSuccessCreate] = React.useState<Boolean>(false);
  // const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
  //   (x) => x + 1,
  //   0
  // );

  const buttonRef = useRef<HTMLButtonElement>(null);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
        />
        <span>Chargement...</span>
      </div>
    );
  }

  return (
    <div className="incubation_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff", cursor: "inherit" }}
            // onClick={() => navigate(-1)}
          >
            <img
              src={incubator}
              alt="chiken"
              height={15}
              style={{ color: "#777" }}
            />
            {/* <EggAltOutlined className="ico" /> */}
          </IconButton>
          <h4>
            Incubation
            {/* {urlRoute[2] === "fns-pss" && CATEGORY_NATURE.poussin}
            {urlRoute[2] === "fns-bgz" && CATEGORY_NATURE.biogaz}
            {urlRoute[2] === "fns-alm" && CATEGORY_NATURE.aliment}
            {urlRoute[2] === "fns-cf" && CATEGORY_NATURE.chambre_froide} */}
          </h4>
        </div>
        <div className="right">
          {/* <div className="btn_commande" onClick={() => setOpenAddNew(true)}>
            <Add className="ico" />
            <span>Nouvelle Incubation</span>
          </div> */}
          <button ref={buttonRef}>
            <VerticalAlignBottomOutlinedIcon className="ico" />
            <span>Exporter XLSX</span>
          </button>
        </div>
      </div>

      <div className="options">
        {/* <Link> */}
        <div
          className={urlRoute[2] === "incubation" ? "option_active" : "option"}
        >
          <span>Total lot</span>
          <span className="count">{allIncubation.length}</span>
          <div className="underline1"></div>
        </div>
        {/* </Link> */}
      </div>
      {/* 
      {successCreate && (
        <SuccessAlert
          setOpen={setSuccessCreate}
          label={"Opération effectuée avec success"}
          theme={"success"}
        />
      )} */}
      {/* {openAddNew && (
        <NewSupplier
          setOpenAdd={setOpenAddNew}
          forceUpdateOnInsert={forceUpdateOnInsert}
          setSuccessCreate={setSuccessCreate}
        />
      )} */}
      <div className="container">
        <Outlet context={buttonRef} />
      </div>
    </div>
  );
};

export default Incubation;
