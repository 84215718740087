import Search from "@mui/icons-material/Search";
import "./sortie.scss";
import React, { useReducer, useState } from "react";
import { Alert, CircularProgress, TextField } from "@mui/material";
import MotifSortieTable from "../../table/MotifSortieTable";
import {
  AddBoxRounded,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import motif_sortie_api from "../../../_service/motifSortie.Caller";
import SuccessAlert from "../../alert/AlerteBar";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../../types/GlobalType";
import { ERROR_MESSAGE } from "../../../_service/_const";

const Sortie = () => {
  const [openForm, setOpenForm] = React.useState<boolean>(false);
  const [motif, setmotif] = React.useState<string>("");
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [successCreate, setSuccessCreate] = React.useState<Boolean>(false);
  const [reducerValueOnInsert, forceUpdateOnInsert] = useReducer(
    (x) => x + 1,
    0
  );

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  if (state.errorMessage) {
    setTimeout(() => {
      setState({ isLoading: false, errorMessage: null });
    }, 5000);
  }

  const handleSubmit = () => {
    setState({ isLoading: false, errorMessage: null });

    if (motif.trim() == "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter les champs vides",
      }));

    setState({ isLoading: true, errorMessage: null, succussMessage: "" });

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      motif_sortie_api
        .create({ designation: motif }, token)
        .then((response) => {
          setState({
            isLoading: false,
            errorMessage: null,
          });
          if (response) {
            setSuccessCreate(true);
            setmotif("");
            setOpenForm(false);
            forceUpdateOnInsert();
          }
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: ERROR_MESSAGE.networkError,
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <div className="sortie_param">
      <div className="title">
        <span>Gestion motifs des sorties stock</span>
        <div className="header">
          {!openForm && (
            <AddBoxRounded className="ico" onClick={() => setOpenForm(true)} />
          )}
          {openForm && (
            <RemoveCircleOutlineOutlined
              className="ico"
              onClick={() => setOpenForm(false)}
            />
          )}
          <div className="Searchbar">
            <Search className="icon" />
            <input
              placeholder="Recherche"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="list">
          {/* <span>Liste catégories</span> */}
          <MotifSortieTable
            reducerValueOnInsert={reducerValueOnInsert}
            searchValue={searchValue}
          />
        </div>
        {openForm && (
          <div className="add">
            <span>Nouveau motif de sortie</span>
            <div className="form">
              <TextField
                label="Motif sortie"
                id="outlined-size-small"
                size="small"
                fullWidth
                value={motif}
                onChange={(e) => setmotif(e.target.value)}
              />

              {!state.isLoading && (
                <button onClick={handleSubmit}>Enregistrer</button>
              )}

              {state.isLoading && (
                <button className="loadingBtn">
                  <CircularProgress size={20} className="circular" />
                </button>
              )}
              {state.errorMessage && (
                <Alert severity="warning">
                  Le champs ne doit pas être vide
                </Alert>
              )}
            </div>
          </div>
        )}
      </div>

      {successCreate && (
        <SuccessAlert
          setOpen={setSuccessCreate}
          label={"Opération effectuée avec success"}
          theme={"success"}
        />
      )}
    </div>
  );
};

export default Sortie;
