import React, { useEffect, useState } from "react";
import "./rapport_ok.scss";
// import RapportTable from '../table/RapportTable';
import SearchBar from "../../search/Search";
import List from "./List";
import { RapportType, requestType } from "../../../types/GlobalType";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { rapport_api } from "../../../_service/notification.Call";
import { ERROR_MESSAGE, STATUS_RAPPORT_ID } from "../../../_service/_const";
import NoData from "../../errorComp/NoData";
import { RotatingLines } from "react-loader-spinner";
import ConnexionError from "../../errorComp/Connexion";
import {
  Autorenew,
  CancelOutlined,
  DoneAllOutlined,
  RateReviewOutlined,
} from "@mui/icons-material";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addallRapport,
  addRapportEtablieCount,
} from "../../../redux/rapportSlice/rapportSlice";
import { RootState } from "../../../redux/store";
// import { Check, Clear, MoreHorizOutlined } from "@mui/icons-material";

const rapportStatus = [
  "Tous les rapports",
  "Rapports en attente",
  "Rapports Validés",
  "Rapports annulés",
];
// const names = [
// "Semaine en cours",
// "30 Derniers jours",
// "Tous",
// "Année spécifique",
// ];
// const years = ["2024", "2022", "2021"];

function Rapport() {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("notifications");
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [reducerValueOnUpdate, forceReloadOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );

  const setOpenSuccessUpdateRappport: React.Dispatch<
    React.SetStateAction<Boolean>
  > = useOutletContext();

  // const [triPeriod, setTriePeriod] = React.useState<string>(rapportStatus[0]);

  const [data, setData] = React.useState<RapportType[]>([]);
  // const [singleData, setSingleData] = React.useState<RapportType>();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const { allRapport } = useSelector((state: RootState) => state.rapport);

  const navigate = useNavigate();

  // console.log(data);

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        let [data1, data2, data3, data4, data5, data6, data7] =
          await rapport_api.getAll(token);

        //creation d'une objet du type axiosResponse pour servir a creer une reponse
        //structuree EMPTY  dans le cas de reponse avec propriete data = [] ou ""(string)
        let axios_Fake_Objet = {
          config: null,
          data: { data: [], message: "Situation Rapport is Empty" },
          status: 205,
          statusText: "OK",
        };
        if (data1.data.data === undefined)
          data1 = Object.create(axios_Fake_Objet);

        if (data2.data.data === undefined)
          data2 = Object.create(axios_Fake_Objet);

        if (data3.data.data === undefined)
          data3 = Object.create(axios_Fake_Objet);

        if (data4.data.data === undefined)
          data4 = Object.create(axios_Fake_Objet);

        if (data5.data.data === undefined)
          data5 = Object.create(axios_Fake_Objet);

        if (data6.data.data === undefined)
          data6 = Object.create(axios_Fake_Objet);

        if (data7.data.data === undefined)
          data7 = Object.create(axios_Fake_Objet);

        const mergedData = [
          ...data1.data.data,
          ...data2.data.data,
          ...data3.data.data,
          ...data4.data.data,
          ...data5.data.data,
          ...data6.data.data,
          ...data7.data.data,
        ];

        setData(mergedData);
        dispatch(addallRapport(mergedData));
        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent---Rapport");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  let rapportEstablishedNum: number = 0;
  if (allRapport.length > 0) {
    for (let item of data) {
      if (item.status_rapport_id === STATUS_RAPPORT_ID.ETABLI) {
        rapportEstablishedNum++;
      }
    }
    dispatch(addRapportEtablieCount(allRapport));
  }

  React.useEffect(() => {
    fetchAll();
  }, [reducerValueOnUpdate]);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          // color="#777"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          // wrapperClass=""
          // wrapperStyle={{}}
        />
        <span>Chargement...</span>
      </div>
    );
  }

  if (state.succussMessage === "success" && data.length <= 0) return <NoData />;

  let rapportEstablished: number = 0;
  let rapportToReview: number = 0;
  let rapportCanceled: number = 0;
  let rapportValidate: number = 0;

  let sortedData: RapportType[] = [];
  if (data.length > 0) {
    //SortBydate
    sortedData = _.orderBy(data, ["date"], ["asc"]);
    for (let item of data) {
      switch (item.status_rapport_id) {
        case STATUS_RAPPORT_ID.ETABLI:
          rapportEstablished++;
          break;
        case STATUS_RAPPORT_ID.A_REVISER:
          rapportToReview++;
          break;
        case STATUS_RAPPORT_ID.ANNULE:
          rapportCanceled++;
          break;
        case STATUS_RAPPORT_ID.VALIDE:
          rapportValidate++;
          break;
        default:
          break;
      }
    }
  }

  return (
    <div className="table_client_all">
      <div className="header">
        <div className="search">
          <SearchBar
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            label={"Recherche"}
          />
        </div>

        <div className="right_">
          {/* ----------------------------------------------------
                      TRI BYA SPECIFIC YEAR
---------------------------------------------------- */}
          {/* {triPeriod === names[names.length - 1].toLocaleLowerCase() && (
            <div className={urlRoute[2] === "requetes" ? "hidden" : ""}>
              <select>
                {years.map((value, index) => (
                  <option key={index} value={value.toLowerCase()}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          )} */}

          {/* <div className={urlRoute[2] === "requetes" ? "hidden" : "select"}>
            <select
              onChange={(e) => setTriePeriod(e.target.value)}
              defaultValue={names[0]}
            >
              {names.map((value, index) => (
                <option key={index} value={value.toLowerCase()}>
                  {value}
                </option>
              ))}
            </select>
          </div> */}
        </div>

        {/* ----------------------------------------------------
                      FOR MOBILE
---------------------------------------------------- */}

        {/* <div className="btns sm">
          <span className="status pending">
            <MoreHorizOutlined className="ico" />
          </span>
          <span className="status validate">
            <Check className="ico" />
          </span>
          <span className="status canceled">
            <Clear className="ico" />
          </span>
        </div> */}

        {/* --------------------------------------------------------- */}
        <div className="btns">
          <div className="status pending">
            <Autorenew className="ico" />
            <span>Etablie {rapportEstablished}</span>
          </div>
          <div className="status reviewing">
            <RateReviewOutlined className="ico" />
            <span>A réviser {rapportToReview}</span>
          </div>
          <div className="status canceled">
            <CancelOutlined className="ico" />
            <span>Annulé {rapportCanceled}</span>
          </div>
          <div className="status validate">
            <DoneAllOutlined className="ico" />
            <span>valide {rapportValidate}</span>
          </div>
        </div>
      </div>
      <List
        data={sortedData}
        searchValue={searchValue}
        forceReloadOnUpdate={forceReloadOnUpdate}
        setOpenSuccess={setOpenSuccessUpdateRappport}
      />
    </div>
  );
}

export default Rapport;
