import * as React from "react";
import "./motifsortietable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Delete from "../dialog/Delete";
import AlertBar from "../alert/AlerteBar";
import { MotifType } from "../../types/CategoryType";
import EditMotifDialog from "../configuration/sortie/EditMotifDialog";
import { requestType } from "../../types/GlobalType";
import motif_sortie_api from "../../_service/motifSortie.Caller";
import { ERROR_MESSAGE } from "../../_service/_const";
import { RotatingLines } from "react-loader-spinner";
import NoData from "../errorComp/NoData";
import ConnexionError from "../errorComp/Connexion";
import { capitalizeFirstLetter } from "../../_utils/string_utils";
import { useNavigate } from "react-router-dom";

type Props = {
  reducerValueOnInsert: number;
  searchValue: string;
};

export default function MotifSortieTable(props: Props) {
  const [data, setData] = React.useState<MotifType[] | null>([]);
  const [singleData, setsingleData] = React.useState<MotifType | undefined>();
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [categoryId, setcategoryId] = React.useState<
    string | number | undefined
  >("");
  const [openDelete, setOpenDelete] = React.useState<Boolean>(false);
  const [openEdit, setOpenEdit] = React.useState<Boolean>(false);
  const [loadingDeleteBtn, setLoadingDeleteBtn] =
    React.useState<boolean>(false);
  // const [loadingUpdateBtn, setLoadingUpdateBtn] =
  //   React.useState<boolean>(false);
  const [reducerValueOnDelete, forceUpdateOnDelete] = React.useReducer(
    (x) => x + 1,
    0
  );
  const [reducerValueOnUpdate, forceUpdateOnUpdate] = React.useReducer(
    (x) => x + 1,
    0
  );

  const [successDelete, setSuccessDelete] = React.useState<Boolean>(false);
  const [errorDelete, seterrorDelete] = React.useState<Boolean>(false);
  const [successUpdate, setSuccessUpdate] = React.useState<Boolean>(false);
  const [otherError, setotherError] = React.useState<Boolean>(false);

  const navigate = useNavigate();

  // console.log("Motif sortie");
  // console.log(data);

  const handleDelete = (id: string | number | undefined) => {
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      motif_sortie_api
        .delete(id, token)
        .then((response) => {
          // console.log(response);
          if (response.status === 200) {
            setLoadingDeleteBtn(false);
            setOpenDelete(false);
            setSuccessDelete(true);
            forceUpdateOnDelete();
          }
        })
        .catch((error: any) => {
          setLoadingDeleteBtn(false);
          // console.log(error);
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  const fetchSortieInfo = () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      motif_sortie_api
        .getAll(token)
        .then((response) => {
          // console.log(response);
          // console.log("Success Fetch Motif sortie");

          setState({ errorMessage: "", isLoading: false });
          setData(response.data.data);
        })
        .catch((error) => {
          setState({
            isLoading: false,
            errorMessage: "erreur de chargement, réessayer",
          });

          if (error.message === ERROR_MESSAGE.networkError) {
            return setState({
              isLoading: false,
              errorMessage: ERROR_MESSAGE.networkError,
            });
          }
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchSortieInfo();
  }, [reducerValueOnDelete, reducerValueOnUpdate, props.reducerValueOnInsert]);

  const LoadingComp = (
    <div className="loader">
      {/* <Circles
        height="40"
        width="40"
        color="#4fa94d"
        ariaLabel="circles-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      /> */}
      <RotatingLines
        visible={true}
        width="40"
        // color="#777"
        strokeWidth="3"
        animationDuration="0.75"
        ariaLabel="rotating-lines-loading"
        // wrapperClass=""
        // wrapperStyle={{}}
      />
      <span>chargement...</span>
    </div>
  );

  const tableData = (
    <TableContainer component="div" className="user_table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Désignation</TableCell>
            {/* <TableCell align="center">Action</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data
            ?.filter((object) =>
              object.motif_designation
                ?.toLowerCase()
                .includes(props.searchValue.toLowerCase())
            )
            ?.map((row, index) => (
              <TableRow
                key={row.motif_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
                component="tr"
              >
                <TableCell component="th" scope="row">
                  {`0${index + 1}`}
                </TableCell>
                <TableCell align="left">
                  {capitalizeFirstLetter(row.motif_designation)}
                </TableCell>
                {/* <TableCell align="center" className="btn">
                  <span
                    className="editer"
                    onClick={() => {
                      setsingleData(row);
                      setOpenEdit(true);
                    }}
                  >
                    Edit
                  </span>
                  <span
                    className="supprimer"
                    onClick={() => {
                      setcategoryId(row.motif_id);
                      setOpenDelete(true);
                    }}
                  >
                    Supprimer
                  </span>
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {successDelete && (
        <AlertBar
          setOpen={setSuccessDelete}
          label={"Supression effectuée avec success"}
          theme={"success"}
        />
      )}

      {errorDelete && (
        <AlertBar
          setOpen={seterrorDelete}
          label={"Interdépendance: Suppression impossible"}
          theme={"warn"}
        />
      )}

      {successUpdate && (
        <AlertBar
          setOpen={setSuccessUpdate}
          label={"Modification effectuée avec succès"}
          theme={"success"}
        />
      )}

      {otherError && (
        <AlertBar
          setOpen={setotherError}
          label={"Une erreur est survenue, réessayer plus tard"}
          theme={"fail"}
        />
      )}
      {openDelete && (
        <Delete
          setOpen={setOpenDelete}
          id={categoryId}
          label={"Voulez-vous vraiment supprimer cet motif de sortie?"}
          handleDelete={handleDelete}
          loadingDeleteBtn={loadingDeleteBtn}
          setLoadingDeleteBtn={setLoadingDeleteBtn}
        />
      )}
      {openEdit && (
        <EditMotifDialog
          setOpen={setOpenEdit}
          data={singleData}
          setSuccessUpdate={setSuccessUpdate}
          forceUpdateOnUpdate={forceUpdateOnUpdate}
        />
      )}
    </TableContainer>
  );

  const tableEmpty = (
    <TableContainer component={"div"} className="user_table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="center">Désignation</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableCell colSpan={7} align="center">
            <NoData />
          </TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      {state.isLoading && LoadingComp}

      {state.errorMessage === ERROR_MESSAGE.networkError && (
        <ConnexionError
          title={"Connectez-vous à internet"}
          subTitle={"Vous êtes hors connexion"}
        />
      )}

      {state.errorMessage === ERROR_MESSAGE.unknownError && (
        <ConnexionError
          title={"Une erreur inconnue s'est produite"}
          subTitle={"Réessayez plus tard"}
        />
      )}

      {!state.isLoading && data && data.length > 0 && tableData}

      {state.errorMessage !== ERROR_MESSAGE.networkError &&
        !state.isLoading &&
        state.errorMessage !== ERROR_MESSAGE.unknownError &&
        data &&
        data.length === 0 &&
        tableEmpty}
    </>
  );
}
