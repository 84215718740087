import React, { useRef } from "react";
import "./client.scss";
import { ClientType } from "../../types/UserTypes";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import VerticalAlignBottomOutlinedIcon from "@mui/icons-material/VerticalAlignBottomOutlined";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { requestType } from "../../types/GlobalType";
import client_api from "../../_service/client.Caller";
import { ERROR_MESSAGE } from "../../_service/_const";
import { RotatingLines } from "react-loader-spinner";
import ConnexionError from "../../components/errorComp/Connexion";
import NoData from "../../components/errorComp/NoData";
import * as _ from "lodash";
import { addAllClient } from "../../redux/clientSlice/clientSlice";
import { IconButton } from "@mui/material";
import { PeopleOutlineOutlined } from "@mui/icons-material";

const Client = () => {
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("clients");

  const [data, setData] = React.useState<ClientType[]>([]);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);

  const clients = useSelector((state: RootState) => state.client);
  const navigate = useNavigate();

  let physicClients: any = [];
  let moralClients: any = [];

  if (clients.allClient.length > 0) {
    physicClients = _.filter(clients.allClient, { is_legal_person: 0 });
    moralClients = _.filter(clients.allClient, { is_legal_person: 1 });
  }

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await client_api.getAll(token);
        //remove client where id=0, it's a admin client used for particular request in the backend
        let ClientExeptNagriClient: ClientType[] = _.filter(
          response.data.data,
          function (o) {
            return o.client_id !== 0;
          }
        );
        dispatch(addAllClient(ClientExeptNagriClient));
        setData(ClientExeptNagriClient);

        setState({
          errorMessage: "",
          isLoading: false,
          succussMessage: "success",
        });
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  if (state.errorMessage === ERROR_MESSAGE.networkError) {
    return (
      <ConnexionError
        title={"Connectez-vous à internet"}
        subTitle={"Vous êtes hors connexion"}
      />
    );
  }

  if (state.isLoading) {
    return (
      <div className="loader">
        <RotatingLines
          visible={true}
          width="40"
          // color="#777"
          strokeWidth="3"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          // wrapperClass=""
          // wrapperStyle={{}}
        />
        <span>Chargement...</span>
      </div>
    );
  }

  if (state.succussMessage === "success" && data.length <= 0) return <NoData />;

  return (
    <div className="client_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff", cursor: "inherit" }}
            // onClick={() => navigate(-1)}
          >
            <PeopleOutlineOutlined className="ico" />
          </IconButton>
          <h4>
            Clients
            {/* {urlRoute[2] === "cls-pss" && " poussin"}
            {urlRoute[2] === "cls-bgz" && " biogaz"}
            {urlRoute[2] === "cls-alm" && " aliment bétail"}
            {urlRoute[2] === "cls-cf" && " chambre froide"} */}
          </h4>
        </div>
        <div className="right">
          <button ref={buttonRef}>
            <VerticalAlignBottomOutlinedIcon className="ico" />
            <span>Exporter XLSX</span>
          </button>
        </div>
      </div>

      <div className="options">
        <Link to="/clients">
          <div
            className={urlRoute[2] === "clients" ? "option_active" : "option"}
          >
            <span>Tous</span>
            <span className="count">{clients?.allClient.length}</span>
            <div className="underline1"></div>
          </div>
        </Link>

        <Link to="/clients/cls-physic" className="link_sm">
          <div
            className={
              urlRoute[2] === "cls-physic" ? "option_active" : "option"
            }
          >
            <span>Individus</span>
            <span className="count">{physicClients?.length}</span>
            <div className="underlinePhysic"></div>
          </div>
        </Link>
        <Link to="/clients/cls-moral" className="link_sm">
          <div
            className={urlRoute[2] === "cls-moral" ? "option_active" : "option"}
          >
            <span>Entreprises</span>
            <span className="count">{moralClients?.length}</span>
            <div className="underlineMoral"></div>
          </div>
        </Link>
        {/* <Link to="/clients/cls-pss">
          <div
            className={urlRoute[2] === "cls-pss" ? "option_active" : "option"}
          >
            <span>Poussins</span>
            <span className="count">{poussinClients?.length}</span>
            <div className="underline2"></div>
          </div>
        </Link>
        <Link to="/clients/cls-bgz">
          <div
            className={urlRoute[2] === "cls-bgz" ? "option_active" : "option"}
          >
            <span>Biogaz</span>
            <span className="count">{biogazClients?.length}</span>
            <div className="underline3"></div>
          </div>
        </Link>
        <Link to="/clients/cls-alm">
          <div
            className={urlRoute[2] === "cls-alm" ? "option_active" : "option"}
          >
            <span>Aliments</span>
            <span className="count">{alimentClients?.length}</span>
            <div className="underline4"></div>
          </div>
        </Link> */}
        {/* <Link to="/clients/cls-cf">
          <div
            className={urlRoute[2] === "cls-cf" ? "option_active" : "option"}
          >
            <span>Chambre froide</span>
            <span className="count">{otherClients?.length}</span>
            <div className="underline5"></div>
          </div>
        </Link> */}
      </div>
      <div className="container">
        <Outlet context={buttonRef} />
      </div>

      {/* {true && <SuccessDialog/>} */}
    </div>
  );
};

export default Client;
