import React, { useState } from "react";
import "./newSupplier.scss";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../types/GlobalType";
import { CATEGORY_NATURE, ERROR_MESSAGE } from "../../_service/_const";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import { capitalize } from "lodash";
import supplier_api from "../../_service/supplier.Caller";
import { Person2Sharp } from "@mui/icons-material";
import MyLoadingv3 from "../../components/loader/Loader_v3";

type Props = {
  setOpenAdd: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccessCreate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceUpdateOnInsert: () => void;
};

type SupplierType = {
  nom_entreprise: string;
  annee_existence?: string;
  titre: string;
  nom: string;
  postnom: string;
  prenom?: string;
  sexe: string;
  pays?: string;
  ville: string;
  commune: string;
  quartier?: string;
  avenue?: string;
  email?: string;
  telephone?: string;
  cat_produit_id?: string;
};

const NewSupplier = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const [error, setError] = useState<boolean>(false);
  //phone number state
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [existanceYear, setExistanceYear] = useState("Annee");
  const [validdNumber, setValidNumber] = useState<boolean>(true);
  const handleChangePhone = (input: string) => {
    setPhoneNumber(input);
    setValidNumber(validatePhoneNumber(input));
  };

  //country state
  const [selectedCountry, setSelectedCountry] = useState<string>("CD");

  let currentYear = +new Date().getFullYear();
  let yearsArray = ["-----", currentYear];

  //create array of 100 previous years
  for (let i = 1; i <= 100; i++) {
    yearsArray.push(currentYear - i);
  }

  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const [data, setData] = React.useState<SupplierType>({
    annee_existence: "",
    nom_entreprise: "",
    titre: "",
    nom: "",
    postnom: "",
    prenom: "",
    sexe: "M",
    email: "",
    ville: "",
    commune: "",
    quartier: "",
    avenue: "",
    cat_produit_id: "1",
  });

  const handleChange = (e: any) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  if (error) {
    setTimeout(() => {
      setError(false);
    }, 4000);
  }

  async function handleSubmitSupplier() {
    setState({ isLoading: false, errorMessage: null });
    const {
      nom_entreprise,
      titre,
      nom,
      email,
      ville,
      commune,
      quartier,
      avenue,
    } = data;

    if (nom_entreprise?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le nom de l'entreprise",
      }));
    if (nom?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le nom",
      }));

    if (email?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter l'adresse mail",
      }));
    if (titre?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter la fonction",
      }));

    if (phoneNumber?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter le numéro de téléphone ",
      }));
    if (ville?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez compléter la ville",
      }));
    if (selectedCountry?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le pays",
      }));
    if (quartier?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner le quartier",
      }));
    if (avenue?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner l'avenue",
      }));
    if (commune?.trim() === "")
      return setState((prev) => ({
        ...prev,
        errorMessage: "Veuillez renseigner la commune",
      }));
    setState({ isLoading: true, errorMessage: null, succussMessage: "" });

    // console.log(data);
    // console.log(`+${phoneNumber}`, selectedCountry);

    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      supplier_api
        .create(
          {
            ...data,
            telephone: `+${phoneNumber}`,
            pays: selectedCountry,
          },
          token
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 205) {
            return setState({
              isLoading: false,
              errorMessage: "Adresse mail invalide",
            });
          }
          if (response.status === 203) {
            return setState({
              isLoading: false,
              errorMessage: response.data.message,
            });
          }
          props.forceUpdateOnInsert();
          props.setSuccessCreate(true);

          setState({ isLoading: false, errorMessage: null });
          props.setOpenAdd(false);

          navigate("/suppliers");
          props.forceUpdateOnInsert();

          setData({
            nom_entreprise: "",
            annee_existence: "",
            titre: "",
            nom: "",
            postnom: "",
            prenom: "",
            sexe: "M",
            email: "",
            pays: "RD Congo",
            ville: "",
            commune: "",
            quartier: "",
            avenue: "",
            cat_produit_id: "1",
          });

          setSelectedCountry("");
          setPhoneNumber("");
        })
        .catch((error: any) => {
          // console.log(error);
          if (error.response) {
            if (error.response.status === 401) {
              return setState({
                isLoading: false,
                errorMessage: "Cette adresse mail existe",
              });
            }
            if (error.response.status === 406) {
              return setState({
                isLoading: false,
                errorMessage: "Numéro de téléphone invalide",
              });
            }
            if (error.response.status === 422) {
              return setState({
                isLoading: false,
                errorMessage: "Adresse email invalide",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: "Vérifier votre connexion internet",
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Erreur inconnue, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  }

  return (
    <div className="NewSupplier">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <div className="left">
              <Person2Sharp className="ico" />
              <span>Nouveau Fournisseur</span>
            </div>
            {state.errorMessage && (
              <p className="alert">{state.errorMessage}</p>
            )}
          </div>
        </div>

        <div className="body">
          {/* PHYSICAL CLIENT DETAIL FORM */}
          <>
            <div className="item">
              <label>Entreprise *</label>
              <input
                placeholder="Entreprise..."
                type="text"
                name="nom_entreprise"
                value={data.nom_entreprise}
                onChange={handleChange}
              />
            </div>

            <div className="item">
              <label>Année d'existance</label>
              <select
                onChange={handleChange}
                name="annee_existence"
                value={data.annee_existence}
              >
                {yearsArray.map((year, index) => {
                  return <option key={index}>{year}</option>;
                })}
              </select>
            </div>

            <div className="item">
              <label>Fonction du livreur</label>
              <input
                placeholder="Titre..."
                type="text"
                name="titre"
                value={data.titre}
                onChange={handleChange}
              />
            </div>

            <div className="item name">
              <label>Nom complet*</label>
              <input
                placeholder="Nom..."
                type="text"
                name="nom"
                value={data.nom}
                onChange={handleChange}
              />
            </div>

            {/* <div className="item">
              <label>Post-nom *</label>
              <input
                placeholder="Post-nom..."
                type="text"
                name="postnom"
                value={data.postnom}
                onChange={handleChange}
              />
            </div>

            <div className="item">
              <label>Prénom *</label>
              <input
                placeholder="Prénon..."
                type="text"
                name="prenom"
                value={data.prenom}
                onChange={handleChange}
              />
            </div> */}

            <div className="item">
              <label>Genre</label>
              <select name="sexe" value={data.sexe} onChange={handleChange}>
                <option value="M">Masculin</option>
                <option value="F">Feminin</option>
              </select>
            </div>

            <div className="item">
              <label>Adresse mail *</label>
              <input
                placeholder="Email..."
                type="text"
                name="email"
                value={data.email}
                onChange={handleChange}
              />
            </div>

            <div className="item">
              <label>Service *</label>
              <select name="cat_produit_id" onChange={handleChange}>
                <option value="1">{capitalize(CATEGORY_NATURE.aliment)}</option>
                <option value="2">{capitalize(CATEGORY_NATURE.biogaz)}</option>
                <option value="3">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                <option value="4">{capitalize(CATEGORY_NATURE.oeuf)}</option>
                <option value="5">{capitalize(CATEGORY_NATURE.poule)}</option>
                <option value="6">{capitalize(CATEGORY_NATURE.poulet)}</option>
              </select>
            </div>

            <div className="item phone">
              <label>Téléphone *</label>
              <PhoneInput
                country={"cd"}
                value={phoneNumber}
                onChange={handleChangePhone}
              />
              {!validdNumber && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  Entrer un numéro valide
                </span>
              )}
            </div>

            <div className="item country">
              <label>Pays</label>
              <ReactFlagsSelect
                selected={selectedCountry}
                onSelect={(code) => setSelectedCountry(code)}
                optionsSize={13}
                selectedSize={13}
                searchPlaceholder="Chercher pays"
                placeholder="Choix du pays"
                searchable
                className="menu-flags"
                selectButtonClassName="menu-flags-button"
              />
            </div>

            {/* <div className="item">
            <label>Pays</label>
            <input
              placeholder="Pays..."
              type="text"
              name="pays"
              value={data.pays}
              onChange={handleChange}
              disabled
            />
          </div> */}
            <div className="item">
              <label>Ville *</label>
              <input
                placeholder="Ville..."
                type="text"
                name="ville"
                value={data.ville}
                onChange={handleChange}
              />
            </div>

            <div className="item">
              <label>Commune *</label>
              <input
                placeholder="Commune..."
                type="text"
                name="commune"
                value={data.commune}
                onChange={handleChange}
              />
            </div>

            <div className="item">
              <label>Quartier *</label>
              <input
                placeholder="Quartier..."
                type="text"
                name="quartier"
                value={data.quartier}
                onChange={handleChange}
              />
            </div>

            <div className="item avenue">
              <label>Avenue *</label>
              <input
                placeholder="Avenue..."
                type="text"
                name="avenue"
                value={data.avenue}
                onChange={handleChange}
              />
            </div>
          </>
        </div>

        <div className="btn">
          <button onClick={() => props.setOpenAdd(false)}>Annuler</button>
          <button onClick={handleSubmitSupplier}>Ajouter</button>

          <MyLoadingv3 isLoading={state.isLoading} />
        </div>
      </div>
    </div>
  );
};

export default NewSupplier;
