import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { incubationType, storeType } from "../../types/GlobalType";

interface OrderInitialState{
  alimentStore: storeType[],
  biogazStore: storeType[],
  oeufStore: storeType[],
  poussinStore: storeType[],
  pouleStore: storeType[],
  pouletStore: storeType[],
  incubation: incubationType[],
}

const initialState:OrderInitialState = {
  alimentStore: [],
  biogazStore: [],
  oeufStore: [],
  poussinStore: [],
  pouleStore: [],
  pouletStore: [],
  incubation: [],
};
const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    addIncubation: (state, action:PayloadAction<incubationType []>) => {
      state.incubation=Object.assign([], action.payload)
    },
    addPoussinStore: (state, action:PayloadAction<storeType [] | any >) => {
      state.poussinStore=Object.assign([], action.payload)
    },
    addBiogazStore: (state, action:PayloadAction<storeType [] | any>) => {
      state.biogazStore=Object.assign([], action.payload)
    },
    addAlimentStore: (state, action:PayloadAction<storeType [] | any>) => {
      state.alimentStore=Object.assign([], action.payload)
    },
    addOeufStore: (state, action:PayloadAction<storeType [] | any>) => {
      state.oeufStore=Object.assign([], action.payload)
    },
    addPouleStore: (state, action:PayloadAction<storeType [] | any>) => {
      state.pouleStore=Object.assign([], action.payload)
    },
    addPouletStore: (state, action:PayloadAction<storeType [] | any>) => {
      state.pouletStore=Object.assign([], action.payload)
    },
  },
});

export const {
  addAlimentStore,
  addBiogazStore,
  addOeufStore,
  addPoussinStore,
  addPouleStore,
  addPouletStore,
  addIncubation,

} = stockSlice.actions;

export default stockSlice.reducer;
