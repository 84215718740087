import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientType } from "../../types/UserTypes";

interface ClientInitialState{
  allClient: ClientType[],
  // alimentClient: ClientType[],
  // biogazClient: ClientType[],
  // poussinClient: ClientType[],
  // oeufClient: ClientType[],
  // otherClient: ClientType[],
}

const initialState:ClientInitialState = {
  allClient: [],
  // alimentClient: [],
  // biogazClient: [],
  // poussinClient: [],
  // oeufClient: [],
  // otherClient: [],
};
const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    addAllClient: (state, action:PayloadAction<ClientType []>) => {
      state.allClient=Object.assign([], action.payload)

    },
  },
});

export const {
  addAllClient,

} = clientSlice.actions;

export default clientSlice.reducer;
