const isValidNumber = (num) => {
  const NumFormat = /^[1-9]\d*$/;

  if (num.match(NumFormat)) {
    return true;
  } else {
    return false;
  }
};

const isNumber = (value) => {
  return !isNaN(+value);
};
const roundedDownTwoDigit = (num) => {
  return Math.floor(num * 1000) / 1000;
};
const roundedOneDigit = (num) => {
  return Math.floor(num * 10) / 10;
};

const sortByDateFn = (a, b) => {
  let dateA = new Date(a.date);
  let dateB = new Date(b.date);

  // Subtract the dates to get a value that is either negative, positive, or zero
  return dateA - dateB;
};

export {
  isValidNumber,
  isNumber,
  sortByDateFn,
  roundedDownTwoDigit,
  roundedOneDigit,
};
