import React, { useRef } from "react";
import "./sortie.scss";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowBackIosNew, VerticalAlignBottom } from "@mui/icons-material";
// import SyntesisSales from "../../components/sorties/sales/SyntesisSales";
// import SyntesisOthers from "../../components/sorties/autres/SyntesisOthers";
import moment from "moment";
import { requestType } from "../../types/GlobalType";
import { useDispatch } from "react-redux";
import { sortie_stock_api } from "../../_service/sortieStock.Caller";
import {
  addAlimentSortie,
  addAllSortie,
  addBiogazSortie,
  addOeufSortie,
  addPoussinSortie,
} from "../../redux/sortieStockSlice.ts/sortieStockSlice";
import { ERROR_MESSAGE } from "../../_service/_const";

const names = ["Tous"];
// const names = ["Tous", "Jour spécifique", "Année spécifique"];
// const names = [
//   "Semaine en cours",
//   "30 Derniers jours",
//   "Jour spécifique",
//   "Année spécifique",
// ];
const years = ["2024", "2023", "2022", "2021"];

const Sortie = () => {
  const navigate = useNavigate();
  let location = useLocation();
  let urlRoute = location.pathname.split("/");
  urlRoute.push("sortie");

  const now = moment().format("YYYY-MM-DD");
  const currentYear = moment().format("YYYY");
  const [triPeriod, setTriePeriod] = React.useState<string>("Tous");
  // const [triPeriod, setTriePeriod] = React.useState<string>("Semaine en cours");
  const [yearPeriod, setYearPeriod] = React.useState<string>(currentYear);
  const [dayPeriod, setDayPeriod] = React.useState<string>(now);

  const buttonRef = useRef<HTMLButtonElement>(null);

  const selectPeriod = {
    triPeriod,
    yearPeriod,
    dayPeriod,
  };

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();

  const fetchAll = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const [dataAliment, dataBio, dataOeuf, dataPoussin] =
          await sortie_stock_api.sortie.getAllByService(token);
        let response = await sortie_stock_api.sortie.getAllByService(token);
        // console.log(response);

        dispatch(addAlimentSortie(dataAliment.data.data));
        dispatch(addBiogazSortie(dataBio.data.data));
        dispatch(addOeufSortie(dataOeuf.data.data));
        dispatch(addPoussinSortie(dataPoussin.data.data));

        let mergedData = [
          ...dataAliment.data.data,
          ...dataBio.data.data,
          ...dataOeuf.data.data,
          ...dataPoussin.data.data,
        ];

        dispatch(addAllSortie(mergedData));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAll();
  }, []);

  return (
    <>
      <div className="sortie_page">
        <div className="header">
          <div className="left">
            <IconButton
              sx={{ backgroundColor: "#fff" }}
              onClick={() => navigate("/store")}
            >
              <ArrowBackIosNew className="ico" />
            </IconButton>
            <h4>
              Sortie stock -{urlRoute[2] === "sortie" && " vente"}
              {urlRoute[2] === "others" && " autre"}
            </h4>
          </div>

          <div className="right">
            <button ref={buttonRef}>
              <VerticalAlignBottom className="ico" />
              <span>Exporter XLSX</span>
            </button>
            <button ref={buttonRef} className="ico_sm">
              <VerticalAlignBottom />
            </button>
          </div>
        </div>
        <div className="container">
          <div className="left">
            <div className="top">
              <div className="btns">
                <Link to="/sortie">
                  <button
                    className={urlRoute[2] === "sortie" ? "btn_active" : ""}
                  >
                    Liste des ventes
                  </button>
                </Link>
                <Link to="/sortie/others">
                  <button
                    className={urlRoute[2] === "others" ? "btn_active" : ""}
                  >
                    Autres sorties
                  </button>
                </Link>
              </div>
              <div className="tri">
                {/* {triPeriod === names[names.length - 1].toLocaleLowerCase() && (
                  <div>
                    <select
                      onChange={(e) => setYearPeriod(e.target.value)}
                      defaultValue={names[0]}
                    >
                      {years.map((value, index) => (
                        <option key={index} value={value.toLowerCase()}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </div>
                )} */}

                {/* {triPeriod === names[names.length - 2].toLocaleLowerCase() && (
                  <div>
                    <input
                      type="date"
                      onChange={(e) => setDayPeriod(e.target.value)}
                      value={dayPeriod}
                    />
                  </div>
                )} */}

                <div>
                  <select
                    onChange={(e) => setTriePeriod(e.target.value)}
                    defaultValue={names[0]}
                  >
                    {names.map((value, index) => (
                      <option key={index} value={value.toLowerCase()}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="content">
              <Outlet context={[buttonRef, state.isLoading, selectPeriod]} />
            </div>
          </div>
          {/* <div className="right">
            {urlRoute[2] === "sortie" && <SyntesisSales data={selectPeriod} />}
            {urlRoute[2] === "others" && <SyntesisOthers data={selectPeriod} />}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Sortie;
