import React from "react";
import "./singleOrder.scss";
import {
  ArrowForwardIosOutlined,
  ArrowRightAlt,
  Close,
  LibraryBooks,
} from "@mui/icons-material";
import { MapOutlined } from "@mui/icons-material";
import { OrderType } from "../../../types/GlobalType";
import moment from "moment";
import { STATUS_ORDER_ID } from "../../../_service/_const";
import { Link } from "react-router-dom";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  data: OrderType | undefined;
};
const SingleOrder = (props: Props) => {
  return (
    <div className="single_entree_dialog">
      <div className="dialog">
        <div className="header">
          <div className="title">
            <LibraryBooks sx={{ fontSize: "17px" }} />
            <h4>
              Détail Commande - Nag-Id-0
              {props?.data?.commandeClients_idCommande}
            </h4>
          </div>
          <div className="right_side_title">
            <Close className="ico" onClick={() => props.setOpen(false)} />
          </div>
        </div>

        <div className="body">
          <div className="user_info">
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Nature Commande</span>
                <div className="second_info">
                  <span>{props?.data?.nature_designation}</span>
                  {/* <span>Witesyavwirwa</span> */}
                </div>
              </div>
            </div>

            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Date</span>
                <div className="second_info">
                  <span>{moment(props.data?.date).format("llll")}</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Client</span>
                <div className="second_info">
                  {!!props.data?.is_legal_person ? (
                    <span>{`${props.data?.nom}`}</span>
                  ) : (
                    <span>{`${props.data?.prenom} ${props.data?.nom} ${props.data?.postnom}`}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <MapOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Télephone</span>
                <div className="second_info">
                  <span>{props.data?.telephone}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="content">
            <div className="box">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Quantité</span>
                <div className="second_info">
                  <span>{props?.data?.quantite}</span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Prix unitaire</span>
                <div className="second_info">
                  <span>
                    {props.data?.prixtotal !== undefined &&
                      props.data.quantite !== undefined &&
                      `${props?.data?.devise} ${
                        +props?.data?.prixtotal / +props?.data?.quantite
                      }`}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Cout total</span>
                <div className="second_info">
                  <span>
                    {props.data?.prixtotal !== undefined &&
                      `${props?.data?.devise} ${+props?.data?.prixtotal}`}
                  </span>
                </div>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Montant payé</span>
                <div className="second_info">
                  {props?.data?.montant === null ? (
                    <span>{`${props?.data?.devise} 0`}</span>
                  ) : (
                    <span>{`${props?.data?.devise} ${props?.data?.montant}`}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="box reste">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Reste à payer</span>
                <div className="second_info">
                  <span>
                    {props.data?.prixtotal !== undefined &&
                      props.data.montant !== undefined &&
                      `${props?.data?.devise} ${
                        props?.data?.prixtotal - +props?.data?.montant
                      }`}
                  </span>
                </div>
              </div>
            </div>
            <div className="box reste">
              <div className="box_icon">
                <ArrowForwardIosOutlined className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Status</span>
                <div className="second_info">
                  <span>
                    {props.data?.statusCmd_id ===
                    STATUS_ORDER_ID.ETABLIE_AVEC_DETTE
                      ? "Réglée avec dette"
                      : props.data?.status_cmd_designation}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <Link to={`/orders/${props?.data?.nom}`} state={props?.data}>
            <button>
              {"Détail"} <ArrowRightAlt />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleOrder;
