import React from "react";
import "./store.scss";
import CardOeuf from "../../components/store/cardDetail/CardOeuf";
import CardPoussin from "../../components/store/cardDetail/CardPoussin";
import CardBiogaz from "../../components/store/cardDetail/CardBiogaz";
import CardAliment from "../../components/store/cardDetail/CardAliment";
// import CardPoulet from "../../components/store/CardPoulet";
import { MemorizedCardRecent } from "../../components/store/CardRecent";
import { Link, useNavigate } from "react-router-dom";
import { requestType } from "../../types/GlobalType";
import { useDispatch } from "react-redux";
import { ERROR_MESSAGE } from "../../_service/_const";

import {
  ArrowCircleDownSharp,
  ArrowCircleUp,
  ViewCozyOutlined,
} from "@mui/icons-material";
import { store_api } from "../../_service/store.Caller";
import {
  addAlimentStore,
  addBiogazStore,
  addIncubation,
  addOeufStore,
  addPouleStore,
  addPouletStore,
  addPoussinStore,
} from "../../redux/stockSlice/stockSlice";
import { IconButton, Skeleton } from "@mui/material";
import nature_api from "../../_service/nature.Caller";
import { addAllNature } from "../../redux/natureSlice/natureSlice";
import CardSynthese from "../../components/store/cardDetail/CardSynthese";
import CardPoulet from "../../components/store/cardDetail/CardPoulet";
import CardPoule from "../../components/store/cardDetail/CardPoule";

const Store = () => {
  const navigate = useNavigate();

  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });
  const [natureState, setNatureState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const dispatch = useDispatch();

  const fetchAllStoreItem = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const [
          dataAliment,
          dataBio,
          dataOeuf,
          dataPoussin,
          dataPoule,
          dataPoulet,
          dataIncubation,
        ] = await store_api.store.getAllByService(token);

        dispatch(addAlimentStore(dataAliment.data.data));
        dispatch(addBiogazStore(dataBio.data.data));
        dispatch(addOeufStore(dataOeuf.data.data));
        dispatch(addPoussinStore(dataPoussin.data.data));
        dispatch(addPouleStore(dataPoule.data.data));
        dispatch(addPouletStore(dataPoulet.data.data));
        dispatch(addIncubation(dataIncubation.data.data));

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent-------");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  const fetchAllNature = async () => {
    if (localStorage.getItem("user")) {
      setNatureState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await nature_api.getAll(token);
        // console.log(response);
        dispatch(addAllNature(response.data.data));

        setNatureState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent-------");
        // console.log(error);
        setNatureState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setNatureState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchAllStoreItem();
    fetchAllNature();
  }, []);

  const SkeletonHeader = (
    <div className="header">
      <div className="left">
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton width={150} height={30} variant="rectangular" />
      </div>
      <div className="right">
        <Skeleton width={100} height={30} variant="rectangular" />
        <Skeleton width={100} height={30} variant="rectangular" />
      </div>
    </div>
  );

  return (
    <>
      <div className="store_page">
        {!state.isLoading ? (
          <div className="header">
            <div className="left">
              <IconButton sx={{ backgroundColor: "#fff", cursor: "inherit" }}>
                <ViewCozyOutlined className="ico" />
              </IconButton>
              <h4>Etat du stock</h4>
            </div>
            <div className="right">
              <Link to="/entree">
                <div className="btn_update">
                  <ArrowCircleDownSharp className="ico" />
                  <span>Entrées stock</span>
                </div>
                <div className="btn_update_sm">
                  <ArrowCircleDownSharp className="ico" />
                  <span>Entrées</span>
                </div>
              </Link>
              <Link to="/sortie">
                <div className="btn_update">
                  <ArrowCircleUp className="ico" />
                  <span>Sorties stock</span>
                </div>
                <div className="btn_update_sm">
                  <ArrowCircleUp className="ico" />
                  <span>Sorties</span>
                </div>
              </Link>
            </div>
          </div>
        ) : (
          SkeletonHeader
        )}

        <div className="container">
          <div className="box_store box1">
            <CardSynthese isLoading={state.isLoading} />
          </div>
          <div className="box_store box2">
            {<h4>Ventes récentes</h4>}
            <MemorizedCardRecent />
          </div>
          <div className="box_store box3">
            {<CardOeuf isLoading={state.isLoading} />}
          </div>
          <div className="box_store box3">
            {<CardPoussin isLoading={state.isLoading} />}
          </div>
          <div className="box_store box4">
            {<CardBiogaz isLoading={state.isLoading} />}
          </div>
          <div className="box_store box5">
            {<CardAliment isLoading={state.isLoading} />}
          </div>
          <div className="box_store box5">
            <CardPoule isLoading={state.isLoading} />
          </div>
          <div className="box_store box6">
            <CardPoulet isLoading={state.isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Store;
