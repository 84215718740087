import {PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SupplierType } from "../../types/UserTypes";

interface SupplierInitialState{
  allSupplier: SupplierType[],
  // oeufSupplier: SupplierType[],
  // biogazSupplier: SupplierType[],
  // alimentSupplier: SupplierType[],
  // otherSupplier: SupplierType[],
}

const initialState :SupplierInitialState = {
  allSupplier: []
};
const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    addAllSupplier: (state, action:PayloadAction<SupplierType []>) => {
      state.allSupplier=Object.assign([], action.payload);
    },
 
  },
});

export const {
  addAllSupplier,

} = supplierSlice.actions;

export default supplierSlice.reducer;
