import React from 'react'
import './singlereservation.scss'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { IconButton } from '@mui/material'
import { ArrowBackIosNew, MapOutlined } from '@mui/icons-material'
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import { Link, useNavigate } from 'react-router-dom';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import Logo from '../../assets/chambrefroide.jpeg'

const SingleReservation = () => {

  const navigate = useNavigate();

  return (
    <>
    <div className='single_reservation_page'>
      <div className="header">
        <div className="left">
          <IconButton sx={{ backgroundColor: '#fff' }} onClick={() => navigate(-1)}>
            <ArrowBackIosNew className='ico' />
          </IconButton>
          <h3>Information sur la réservation</h3>
        </div>
        <div className="right">
          <button className='btn_sortie'>
            <DoneOutlinedIcon className='ico' />
            <span>Valider sortie</span>
          </button>
          <Link to=''>
            <button>
              <SendOutlinedIcon className='ico' />
              <span>Ecrire message</span>
            </button>
          </Link>

        </div>
      </div>

      <div className="container">
        <div className="left">
          <div className="top">
            <div className="avatar">
              <img src={Logo} alt="Founisseur logo" />
              <div className="produit">
                <span className='username'>Identifiant produit</span>
                <span className='info'>Poisons (Tilapia)</span>
                <div className='status'>
                  <span className='info'>status:</span>
                  <span className='sortie'>En cours</span>
                </div>
              </div>
            </div>
            <div className="body">
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Descrition</span>
                  <div className="second_info">
                    <span>
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Voluptatibus neque, iste labore ullam placeat perspiciatis, perferendis nostrum reprehenderit repellat blanditiis veniam qui omnis tempore veritatis quas dolorem enim ad officiis.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Non saepe tempore fugiat pariatur? Aperiam aliquid quo cum ducimus, iure odio facilis excepturi alias porro, iusto ipsa perspiciatis molestiae, rem animi.
                    </span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Délai</span>
                  <div className="second_info">
                    <span>22-02-2024 / 24-02-2024</span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Prix total</span>
                  <div className="second_info">
                    <span>$40</span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Montant & Reste à payer</span>
                  <div className="second_info">
                    <span>$20  |  $20</span>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="box_icon">
                  <SupervisorAccountOutlinedIcon className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Date de sortie</span>
                  <div className="second_info">
                    <span>Fournisseur poussin</span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <PersonOutlineIcon className='ico2' />
                </div>
                <div className="box_info">
                  <span className='main_info'>Client</span>
                  <div className="second_info">
                    <span>Kakule Witesyavwirwa / 0990119611</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="right">
          <span className="title"> Historique réservation  </span>
          <ul>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>
            <li>
              <div>
                <p>Achat poussin</p>
                <time>22 juin 2023</time>
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
    </>
  )
}

export default SingleReservation
