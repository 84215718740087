import { Add } from '@mui/icons-material'
import './reservation.scss'
import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
// import EditUserDialog from '../../components/configuration/user/EditUserDialog';
import ReserverDialog from '../../components/reservation/dialog/ReserverDialog';



const names = [
  'Semaine en cours',
  '30 Derniers jours',
  'Tous',
  'Année spécifique'
];
const years = [
  '2024',
  '2022',
  '2021',
];



const Reservation = () => {

  let location = useLocation();
  let urlRoute = location.pathname.split('/');
  urlRoute.push('reservations')

  const [triPeriod, setTriePeriod] = React.useState<string>('Semaine en cours')
  // const [yearPeriod, setYearPeriod] = React.useState<string | null>()

  const [openDialog, setOpenDialog]=useState<boolean>(false)

  return (
    <div className='reservation_cf'>
      <div className="header">
        <div className="left">
          <span className='title'>
            Réservation
          </span>
        </div>
        <div className="right">
          <button>
            <VerticalAlignBottomOutlinedIcon className='ico' />
            <span>Exporter XLSX</span>
          </button>

          <button className='btn_res' onClick={()=>{setOpenDialog(true)}}>
            <Add className='ico' />
            <span>Réserver</span>
          </button>


        </div>
      </div>


      <div className="options">
        <div className="left">
          <Link to='/reservations'>
            <div className={urlRoute[2] === "reservations" ? "option_active" : "option"}>
              <span>En cours</span>
              <span className='count'>100</span>
              <div className='underline1'></div>
            </div>
          </Link>
          <Link to='/reservations/rvt-srt'>
            <div className={urlRoute[2] === "rvt-srt" ? "option_active" : "option"}>
              <span>Sorties</span>
              <span className='count'>12</span>
              <div className='underline2'></div>
            </div>
          </Link>
        </div>
        <div className="right">

          {triPeriod === names[names.length - 1].toLocaleLowerCase() &&
            <div>
              <select>
                {years.map((value, index) => (
                  <option key={index} value={value.toLowerCase()}>{value}</option>
                ))}
              </select>
            </div>
          }

          <div>
            <select onChange={(e) => setTriePeriod(e.target.value)} defaultValue={names[0]}>
              {names.map((value, index) => (
                <option key={index} value={value.toLowerCase()}>{value}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="container">
        <Outlet />
      </div>
      {openDialog && <ReserverDialog setOpen={setOpenDialog}/>}
    </div>
  )
}

export default Reservation
