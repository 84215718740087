import React, { useState } from "react";
import "./cardtwo.scss";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { requestType } from "../../../types/GlobalType";
import _ from "lodash";
import incubation_api from "../../../_service/incubation.Caller";
import { ERROR_MESSAGE, STATUS_INCUBATION } from "../../../_service/_const";
import { addallIncubation } from "../../../redux/incubationSlice/incubationSlice";
import { Skeleton } from "@mui/material";

const CardTwo = () => {
  const { allIncubation } = useSelector((state: RootState) => state.incubation);

  // const [data, setData] = React.useState<incubationType[]>([]);

  //state is loading start here with true as value cause there is a
  //settimeout before the fetch function to be executed
  const [state, setState] = React.useState<requestType>({
    isLoading: true,
    errorMessage: null,
  });

  let quantity: number = 0;
  if (allIncubation.length > 0) {
    allIncubation.forEach((value) => {
      if (value.status_inc_designation === STATUS_INCUBATION.ENCOURS)
        quantity += value.quantite;
    });
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchAllIncubation = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const response = await incubation_api.getAll(token);
        dispatch(addallIncubation(response.data.data));
        // setData((prev) => [...prev, response.data.data]);

        setState({
          errorMessage: "",
          isLoading: false,
          succussMessage: "success",
        });
      } catch (error: any) {
        // console.log("This is error concurrent");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      fetchAllIncubation();
    }, 2000);
  }, []);

  return (
    <>
      {state.isLoading ? (
        <div className="card_two">
          <div className="card_info">
            <Skeleton height={80} width={80} variant="rounded" />
          </div>
          <Skeleton width={50} height={50} variant="rounded" />
        </div>
      ) : (
        <div className="card_two">
          <Link to="/incubation">
            <div className="card_info">
              <span>Incubation</span>
              <h1>{quantity}</h1>
              <span>En cours</span>
            </div>
          </Link>
          <Link to="/incubation">
            <div className="card_icon">
              <div className="wraper">
                <StackedLineChartOutlinedIcon className="ico" />
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default CardTwo;
