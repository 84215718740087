import React, { useState } from "react";
import "./editSupplierDialog.scss";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CATEGORY_NATURE,
  CATEGOTY_ID,
  ERROR_MESSAGE,
} from "../../_service/_const";
import { requestType } from "../../types/GlobalType";
import ErrorBar from "../../components/errorComp/ErrorBar";
import ConnexionBar from "../../components/errorComp/connexionBar";
import { SupplierType } from "../../types/UserTypes";
import ReactFlagsSelect from "react-flags-select";
import PhoneInput from "react-phone-input-2";
import supplier_api from "../../_service/supplier.Caller";
import { Edit } from "@mui/icons-material";
import MyLoadingv3 from "../../components/loader/Loader_v3";

type Props = {
  data: SupplierType;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReloadOnUpdate: () => void;
};
const EditSupplierDialog = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    nom: props.data?.nom,
    nom_entreprise: props.data?.nom_entreprise,
    titre: props.data?.titre,
    email: props.data?.email,
    sexe: props.data?.sexe,
    ville: props.data?.ville,
    commune: props.data?.commune,
    quartier: props.data?.quartier,
    pays: props.data?.pays,
    avenue: props.data?.avenue,
    services_id: props.data?.cat_produit_id,
  });

  //phone number state
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    props.data?.telephone
  );
  const [validdNumber, setValidNumber] = useState<boolean>(true);
  const handleChangePhone = (input: string) => {
    setPhoneNumber(input);
    setValidNumber(validatePhoneNumber(input));
  };

  //country state
  const [selectedCountry, setSelectedCountry] = useState<string>("CD");

  const validatePhoneNumber = (phoneNumber: any) => {
    const phoneNumberPattern =
      /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleSubmit = () => {
    setErrorMsg("");

    setState({ isLoading: false, errorMessage: null, succussMessage: "" });
    if (
      userData.nom_entreprise === "" ||
      userData.titre === "" ||
      userData.nom === "" ||
      userData.email === "" ||
      phoneNumber === "" ||
      userData.ville === "" ||
      userData.commune === "" ||
      userData.quartier === "" ||
      userData.avenue === ""
    ) {
      return setErrorMsg("veuillez compléter les champs vides");
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const {
        nom_entreprise,
        titre,
        nom,
        email,
        sexe,
        ville,
        commune,
        quartier,
        avenue,
        services_id,
      } = userData;

      setState({ isLoading: true, errorMessage: null, succussMessage: "" });

      supplier_api
        .update(
          props.data?.fournisseur_id,
          {
            nom_entreprise,
            titre,
            nom,
            email,
            sexe,
            ville,
            commune,
            quartier,
            avenue,
            telephone: phoneNumber,
            cat_produit_id: services_id,
            pays: selectedCountry,
          },
          token
        )
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
              succussMessage: "Modification effectuée",
            });
            props.setSuccessUpdate(true);
            props.setOpen(false);
            props.forceReloadOnUpdate();
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: ERROR_MESSAGE.networkError,
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "erreur de chargement, réessayer",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  return (
    <>
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="edit_supplier">
        <div className="dialog">
          <div className="header">
            <div className="title">
              <Edit className="ico" />
              <span>Modifier Fournisseur</span>
            </div>
          </div>
          {!!errorMsg && <ErrorBar title={errorMsg} />}
          <div>
            <div className="body">
              <div className="item">
                <label>Entreprise</label>
                <input
                  placeholder="Nom..."
                  type="text"
                  value={userData.nom_entreprise?.toUpperCase()}
                  name="nom_entreprise"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Nom complet</label>
                <input
                  placeholder="Nom..."
                  type="text"
                  value={userData.nom}
                  name="nom"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Fonction</label>
                <input
                  placeholder="Titre..."
                  type="text"
                  value={userData.titre}
                  name="titre"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Genre</label>
                <select
                  defaultValue={
                    userData?.sexe?.toLocaleLowerCase() === "m" ? "M" : "F"
                  }
                  value={userData.sexe}
                  name="sexe"
                  onChange={handleChange}
                >
                  <option value="M">Masculin</option>
                  <option value="F">Feminin</option>
                </select>
              </div>
              <div className="item">
                <label>Email</label>
                <input
                  placeholder="Email..."
                  type="text"
                  value={userData.email}
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Catégorie</label>
                <select
                  value={userData.services_id}
                  name="services_id"
                  onChange={handleChange}
                >
                  <option value={CATEGOTY_ID.ALIMENT}>
                    {CATEGORY_NATURE.aliment}
                  </option>
                  <option value={CATEGOTY_ID.BIOGAZ}>
                    {CATEGORY_NATURE.biogaz}
                  </option>
                  <option value={CATEGOTY_ID.OEUF}>
                    {CATEGORY_NATURE.oeuf}
                  </option>
                  <option value={CATEGOTY_ID.POUSSIN}>
                    {CATEGORY_NATURE.poussin}
                  </option>
                  <option value={CATEGOTY_ID.POULE}>
                    {CATEGORY_NATURE.poule}
                  </option>
                  <option value={CATEGOTY_ID.POULET}>
                    {CATEGORY_NATURE.poulet}
                  </option>
                </select>
              </div>
            </div>
            <div className="body">
              <div className="item phone">
                <label>Téléphone</label>
                <PhoneInput
                  country={"cd"}
                  value={phoneNumber}
                  onChange={handleChangePhone}
                />
                {!validdNumber && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    Entrer un numéro valide
                  </span>
                )}
              </div>

              <div className="item country">
                <label>Pays</label>
                <ReactFlagsSelect
                  selected={selectedCountry}
                  onSelect={(code) => setSelectedCountry(code)}
                  optionsSize={13}
                  selectedSize={13}
                  searchPlaceholder="Chercher pays"
                  placeholder="Choix du pays"
                  searchable
                  className="menu-flags"
                  selectButtonClassName="menu-flags-button"
                />
              </div>
              <div className="item">
                <label>Ville</label>
                <input
                  placeholder="Ville..."
                  type="text"
                  value={userData.ville}
                  name="ville"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Commune</label>
                <input
                  placeholder="Commune..."
                  type="text"
                  value={userData.commune}
                  name="commune"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Quartier</label>
                <input
                  placeholder="Quartier..."
                  type="text"
                  value={userData.quartier}
                  name="quartier"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Avenue</label>
                <input
                  placeholder="Avenue..."
                  type="text"
                  value={userData.avenue}
                  name="avenue"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="btn">
            <button onClick={() => props.setOpen(false)}>Annuler</button>
            <button onClick={handleSubmit}>Modifier</button>
          </div>
        </div>
      </div>
      <MyLoadingv3 isLoading={state.isLoading} />
    </>
  );
};

export default EditSupplierDialog;
