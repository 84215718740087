import React, { useState } from "react";
import "./updatepsw.scss";
import Close from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import {
  EmailOutlined,
  LocalPostOffice,
  Lock,
  MailOutline,
  PhoneAndroidOutlined,
  VisibilityOff,
  VisibilityOutlined,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../../../types/GlobalType";
import admin_api from "../../../../_service/admin.Caller";
import { ERROR_MESSAGE } from "../../../../_service/_const";
import MyLoading_v3 from "../../../loader/Loader_v3";
import ConnexionBar from "../../../errorComp/connexionBar";
import ErrorBar from "../../../errorComp/ErrorBar";

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  admin_id: string | number | undefined;
  admin_email: string | undefined;
  setOpenSuccess: React.Dispatch<React.SetStateAction<Boolean>>;
};
const UpdatePswd = (props: Props) => {
  const [errorMsg, setError] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [type, setType] = useState<string>("password");
  const [newPass, setNewPass] = useState<string>("");
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  const navigate = useNavigate();

  const handleUpdatePsw = async () => {
    if (localStorage.getItem("user")) {
      if (newPass.trim() === "")
        return setError("Veuillez entrer un mot de passe valide");

      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        await admin_api.updatePassword(
          props.admin_id,
          { password: newPass },
          token
        );

        props.setOpenSuccess(true);
        props.setOpen(false);
        setState({
          isLoading: false,
          errorMessage: null,
        });
      } catch (error: any) {
        setState({
          isLoading: false,
          errorMessage: "Vérifier votre connexion et actualiser",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
          if (error.response.status === 405) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  return (
    <>
      {state.isLoading && <MyLoading_v3 isLoading={true} />}
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="update_pswd">
        <div className="dialog">
          <div className="header_pswd">
            <div className="header_left">
              <Lock />
              {"Modification mot de passe"}
            </div>
            <Close className="ico_close" onClick={() => props.setOpen(false)} />
          </div>
          {!!errorMsg && <ErrorBar title={errorMsg} />}
          <div className="body">
            <div className="label">
              <MailOutline className="ico" />
              <span>{props.admin_email}</span>
            </div>
            <div className="item ">
              <div className="inputContainer">
                <input
                  value={newPass}
                  type={type}
                  placeholder={"Nouveau mot de passe "}
                  onChange={(e) => setNewPass(e.target.value)}
                />
                {visible ? (
                  <VisibilityOutlined
                    className="icoPas"
                    onClick={() => {
                      setType("password");
                      setVisible(false);
                    }}
                  />
                ) : (
                  <VisibilityOff
                    className="icoPas"
                    onClick={() => {
                      setType("text");
                      setVisible(true);
                    }}
                  />
                )}
              </div>
            </div>
            <button onClick={handleUpdatePsw}>Modifier</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePswd;
