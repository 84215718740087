import React, { useState } from "react";
import "./editAdminDialog.scss";
import { UserType } from "../../../types/UserTypes";
import { useNavigate } from "react-router-dom";
import { requestType } from "../../../types/GlobalType";
import { ERROR_MESSAGE, SERVICE, SERVICE_ID } from "../../../_service/_const";
import agent_api from "../../../_service/agent.Caller";
import ErrorBar from "../../errorComp/ErrorBar";
import ConnexionBar from "../../errorComp/connexionBar";
import { Person2Rounded } from "@mui/icons-material";
import { validateEmail } from "../../../_utils/string_utils";
import MyLoadingv3 from "../../loader/Loader_v3";
import admin_api from "../../../_service/admin.Caller";

type Props = {
  data: UserType | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<Boolean>>;
  setSuccessUpdate: React.Dispatch<React.SetStateAction<Boolean>>;
  forceReloadOnUpdate: () => void;
};
const EditAdminDialog = (props: Props) => {
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  // console.log(props.data);

  const [errorMsg, setErrorMsg] = useState("");

  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    nom: props.data?.nom,
    postnom: props.data?.postnom,
    prenom: props.data?.prenom,
    email: props.data?.email,
    sexe: props.data?.sexe,
    ville: props.data?.ville,
    commune: props.data?.commune,
    quartier: props.data?.quartier,
    avenue: props.data?.avenue,
    telephone: props.data?.telephone,
    services_id: props.data?.services_id,
  });

  const handleChange = (e: any) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    setErrorMsg("");
    setState({ isLoading: false, errorMessage: null, succussMessage: "" });

    if (!validateEmail(userData.email))
      return setErrorMsg("Votre adresse mail n'est pas valide");

    if (
      userData.nom === "" ||
      userData.postnom === "" ||
      userData.prenom === "" ||
      userData.email === "" ||
      userData.telephone === "" ||
      userData.ville === "" ||
      userData.commune === "" ||
      userData.quartier === "" ||
      userData.avenue === ""
    ) {
      return setErrorMsg("veuillez compléter les champs vides");
    }
    if (localStorage.getItem("user")) {
      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      const {
        nom,
        postnom,
        prenom,
        email,
        sexe,
        ville,
        commune,
        quartier,
        avenue,
        telephone,
        services_id,
      } = userData;

      setState({ isLoading: true, errorMessage: null, succussMessage: "" });

      admin_api
        .update(
          props.data?.admin_id,
          {
            nom,
            postnom,
            prenom,
            email,
            sexe,
            ville,
            commune,
            quartier,
            avenue,
            telephone,
            services_id,
          },
          token
        )
        .then((response) => {
          if (response) {
            setState({
              isLoading: false,
              succussMessage: "Modification effectuée",
            });
            props.setSuccessUpdate(true);
            props.setOpen(false);
            props.forceReloadOnUpdate();
          }
        })
        .catch((error: any) => {
          if (error.response) {
            if (error.response.status === 401) {
              return setState({
                isLoading: false,
                errorMessage: "Utilisateur avec cette adresse mail existe",
              });
            }
            if (error.response.status === 403) {
              localStorage.clear();
              return navigate("/login");
            }

            if (error.response.status === 422) {
              return setState({
                isLoading: false,
                errorMessage: "Adresse email invalide",
              });
            }
          }

          if (error.message === ERROR_MESSAGE.networkError) {
            return setTimeout(() => {
              setState({
                isLoading: false,
                errorMessage: ERROR_MESSAGE.networkError,
              });
            }, 2000);
          } else {
            setState({
              isLoading: false,
              errorMessage: "Une erreur s'est produite, réessayer plus tard",
            });
          }
        });
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };
  return (
    <>
      {state.errorMessage === ERROR_MESSAGE.networkError && <ConnexionBar />}
      <div className="edit_user">
        <div className="dialog">
          <div className="header">
            <div className="title_">
              <Person2Rounded className="ico" />
              <span>Modifier Admin</span>
            </div>
          </div>
          {!!errorMsg && <ErrorBar title={errorMsg} />}
          {!!state.errorMessage && <ErrorBar title={state.errorMessage} />}
          <div>
            <div className="body">
              <div className="item">
                <label>Nom</label>
                <input
                  placeholder="Nom..."
                  type="text"
                  value={userData.nom}
                  name="nom"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Post-nom</label>
                <input
                  placeholder="Post-nom..."
                  type="text"
                  value={userData.postnom}
                  name="postnom"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Prénom</label>
                <input
                  placeholder="Prénon..."
                  type="text"
                  value={userData.prenom}
                  name="prenom"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Genre</label>
                <select
                  defaultValue={
                    userData?.sexe?.toLocaleLowerCase() === "m" ? "M" : "F"
                  }
                  value={userData.sexe}
                  name="sexe"
                  onChange={handleChange}
                >
                  <option value="M">Masculin</option>
                  <option value="F">Feminin</option>
                </select>
              </div>
              <div className="item disabled">
                <label>Email</label>
                <input
                  placeholder="Email..."
                  type="email"
                  value={userData.email}
                  name="email"
                  onChange={handleChange}
                  disabled
                />
              </div>
              <div className="item disabled">
                <label>Mot de passe</label>
                <input placeholder="*************" type="Text" disabled />
              </div>
            </div>
            <div className="body">
              <div className="item">
                <label>Téléphone</label>
                <input
                  placeholder="Téléphone..."
                  type="text"
                  value={userData.telephone}
                  name="telephone"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Service</label>
                <select
                  // defaultValue={userData?.services_id}
                  value={userData.services_id}
                  name="services_id"
                  onChange={handleChange}
                >
                  <option value={SERVICE_ID.ADMINISTRATION}>
                    {SERVICE.ADMINISTRATION}
                  </option>
                  <option value={SERVICE_ID.LOGISTIQUE}>
                    {SERVICE.LOGISTIQUE}
                  </option>
                  <option value={SERVICE_ID.FINANCE}>{SERVICE.FINANCE}</option>
                  <option value={SERVICE_ID.AGENT}>{SERVICE.AGENT}</option>
                </select>
              </div>
              {/* <div className="item">
              <label>Pays</label>
              <input placeholder="Pays..." type="text" value={props.data?.pays} />
            </div> */}
              <div className="item">
                <label>Ville</label>
                <input
                  placeholder="Ville..."
                  type="text"
                  value={userData.ville}
                  name="ville"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Commune</label>
                <input
                  placeholder="Commune..."
                  type="text"
                  value={userData.commune}
                  name="commune"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Quartier</label>
                <input
                  placeholder="Quartier..."
                  type="text"
                  value={userData.quartier}
                  name="quartier"
                  onChange={handleChange}
                />
              </div>
              <div className="item">
                <label>Avenue</label>
                <input
                  placeholder="Avenue..."
                  type="text"
                  value={userData.avenue}
                  name="avenue"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="btn">
            <button onClick={() => props.setOpen(false)}>Annuler</button>
            <button onClick={handleSubmit}>Modifier</button>

            <MyLoadingv3 isLoading={state.isLoading} />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAdminDialog;
