import React, { useState } from "react";
import "./singleclient.scss";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Avatar, IconButton, Skeleton, Tooltip } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  AccountBalance,
  AccountBalanceWallet,
  Add,
  ArrowBackIosNew,
  BlurOn,
  HistoryOutlined,
  HttpsOutlined,
  MapOutlined,
  PlaylistRemoveOutlined,
  SegmentOutlined,
  // SendOutlined,
  Timelapse,
  Timeline,
  ViewInArOutlined,
} from "@mui/icons-material";
// import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import BtnMessage from "../../components/buttons/BtnMessage";
// import Chat from "../../components/messages/Chat";
import {
  CATEGORY_NATURE,
  ERROR_MESSAGE,
  STATUS_ORDER_ID,
  USER_ROLE,
} from "../../_service/_const";
import { OrderType, requestType } from "../../types/GlobalType";
import { order_api } from "../../_service/order.Caller";
import moment from "moment";
import _, { capitalize, toLower } from "lodash";
import UpdatePswd from "./dialog/UpdatePswd";
import SuccessAlert from "../../components/alert/AlerteBar";
import COUNTRY_CODES_EN from "../../_service/_countryCode";
import SingleOrder from "./dialog/SingleOrder";
import {
  roundedDownTwoDigit,
  roundedOneDigit,
} from "../../_utils/number_utils";
import { ProtectedSectionFor } from "../../_service/WithPermission";
// import BtnAdd from "../../components/buttons/BtnAdd";

interface stateDataType {
  ordersCount: number;
  waitingCount: number;
  paidAmount: number;
  balanceAmount: number;
}

const SingleClient = () => {
  const navigate = useNavigate();
  const location = useLocation();

  let pays = location.state.pays;

  // const [oenChat, setOpenChat] = useState<boolean>(false);
  //open dialog for update pswd
  const [openOrder, setOpenOrder] = useState<boolean>(false);
  const [pushData, setpushData] = useState<OrderType>();
  const [openUpdatePsw, setOpenUpdatePsw] = useState<Boolean>(false);
  const [openSuccessUpdatePsw, setOpenSuccessUpdatePsw] =
    React.useState<Boolean>(false);
  const [orderData, setOrderData] = useState<OrderType[] | []>([]);
  const [state, setState] = React.useState<requestType>({
    isLoading: false,
    errorMessage: null,
  });

  //FOR CLIENT STATE
  //toutes les dettes du clients se calcule seulement en fonction des ses commandes qui sont reglée
  let stateData: stateDataType = {
    ordersCount: 0,
    waitingCount: 0,
    paidAmount: 0,
    balanceAmount: 0,
  };

  if (!!orderData.length) {
    stateData.ordersCount = orderData.length;
    for (let item of orderData) {
      if (
        item.statusCmd_id !== STATUS_ORDER_ID.REGLE &&
        item.statusCmd_id !== STATUS_ORDER_ID.ETABLIE_AVEC_DETTE &&
        item.statusCmd_id !== STATUS_ORDER_ID.ANNULE
      ) {
        stateData.waitingCount++;
      }

      if (item.montant !== undefined) {
        stateData.paidAmount = +stateData.paidAmount + +item.montant;
      }

      if (
        item.statusCmd_id === STATUS_ORDER_ID.ETABLIE_AVEC_DETTE &&
        item.montant !== undefined &&
        item.prixtotal !== undefined
      ) {
        let diff = +item.prixtotal - +item.montant;
        stateData.balanceAmount = +stateData.balanceAmount + +diff;
      }
    }
  }

  const handleOpenDetail = (data: OrderType) => {
    setOpenOrder(true);
    setpushData(data);
  };

  const Skeletons = [...Array(5)].map((_, index) => (
    <li key={index}>
      <div>
        <p>
          <Skeleton height={10} width={200} variant="rectangular" />
        </p>
        <time>
          {" "}
          <Skeleton height={20} width={100} />
        </time>
      </div>
    </li>
  ));

  const fetchOrderByUser = async () => {
    if (localStorage.getItem("user")) {
      setState({
        isLoading: true,
        errorMessage: null,
      });

      const userLS = JSON.parse(localStorage.getItem("user") || '"');
      const token = userLS.token;

      try {
        const { data } = await order_api.clientOrder.getAllByClient(
          location.state.client_id,
          token
        );

        // console.log(data);

        setOrderData(data.data);

        setState({ errorMessage: "", isLoading: false });
      } catch (error: any) {
        // console.log("This is error concurrent-------");
        // console.log(error);
        setState({
          isLoading: false,
          errorMessage: "erreur de chargement, réessayer",
        });

        if (error.message === ERROR_MESSAGE.networkError) {
          return setState({
            isLoading: false,
            errorMessage: ERROR_MESSAGE.networkError,
          });
        }
        if (error.response) {
          if (error.response.status === 403) {
            localStorage.clear();
            return navigate("/login");
          }
        }
      }
    } else {
      localStorage.clear();
      return navigate("/login");
    }
  };

  React.useEffect(() => {
    fetchOrderByUser();
  }, []);

  return (
    <div className="single_client_page">
      <div className="header">
        <div className="left">
          <IconButton
            sx={{ backgroundColor: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNew className="ico" />
          </IconButton>
          <h3>Information sur le client</h3>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to={"/orders"} state={{ oldClientData: location.state }}>
            <div className="right">
              <div className="btn_commande">
                <Add className="ico" />
                <span>Nouvelle commande</span>
              </div>
            </div>
          </Link>
          <ProtectedSectionFor roleRequired={USER_ROLE.ADMINISTRATION}>
            <Tooltip title="Modifier le mot de passe">
              <IconButton
                aria-label="more"
                id="long-button"
                onClick={() => setOpenUpdatePsw(true)}
              >
                <HttpsOutlined sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          </ProtectedSectionFor>
        </div>
        {/* <div className="right" onClick={() => setOpenChat(true)}>
          <BtnMessage />
        </div> */}
      </div>

      <div className="container">
        <div className="left">
          <div className="top">
            <div className="avatar">
              <Avatar className="ico" />
              <span className="username Ese">
                {!location.state.is_legal_person ? (
                  `${capitalize(location.state.nom)} ${capitalize(
                    location.state.prenom
                  )}`
                ) : (
                  <>
                    {capitalize(location.state.nom)}
                    <span className="container-Ese">
                      {" "}
                      <span className="Ese">e</span>
                    </span>
                  </>
                )}
              </span>
              <span className="phone Ese">
                {location.state.is_legal_person === 1 && (
                  <>
                    Hôte : {location.state.nom_entreprise}/
                    {location.state.titre}
                  </>
                )}
              </span>
              <div className="status inactive">
                <span className="info">status compte:</span>
                <span>{location.state.status ? "Actif" : "Inactif"}</span>
              </div>
            </div>
            <div className="box">
              <div className="box_icon">
                <Timelapse className="ico2" />
              </div>
              <div className="box_info">
                <span className="main_info">Date de création</span>
                <div className="second_info">
                  {/* <span>{location.state.nom}</span> */}
                  <span>{moment(location.state.created_at).format("LL")}</span>
                </div>
              </div>
            </div>

            <div className="body">
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Age & Genre</span>
                  <div className="second_info">
                    <span>
                      {location.state.is_legal_person
                        ? "----/"
                        : `  ${location.state.Tranche_age_designation} ans -`}

                      {toLower(location.state.sexe) === "m"
                        ? "Masculin"
                        : "Feminin"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <MapOutlined className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Pays</span>
                  <div className="second_info">
                    <span>
                      {COUNTRY_CODES_EN[pays]
                        ? COUNTRY_CODES_EN[pays].primary
                        : location.state.pays}
                    </span>
                    <span>Ville de {location.state.ville}</span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <LocationOnOutlinedIcon className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Adresse</span>
                  <div className="second_info">
                    <span>Q. {location.state.quartier}</span>
                    <span>
                      C. {location.state.commune} , Av. {location.state.avenue}
                    </span>
                  </div>
                </div>
              </div>
              <div className="box">
                <div className="box_icon">
                  <PhoneIphoneOutlinedIcon className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Téléphone</span>
                  <div className="second_info">
                    <span>{location.state.telephone}</span>
                  </div>
                </div>
              </div>

              <div className="box">
                <div className="box_icon">
                  <SupervisorAccountOutlinedIcon className="ico2" />
                </div>
                <div className="box_info">
                  <span className="main_info">Service</span>
                  <div className="second_info">
                    <span>
                      Client &nbsp;
                      {location.state.services_id === 1 &&
                        CATEGORY_NATURE.aliment}
                      {location.state.services_id === 2 &&
                        CATEGORY_NATURE.biogaz}
                      {location.state.services_id === 3 && CATEGORY_NATURE.oeuf}
                      {location.state.services_id === 4 &&
                        CATEGORY_NATURE.poussin}
                      {location.state.services_id === 5 &&
                        CATEGORY_NATURE.poule}
                      {location.state.services_id === 6 &&
                        CATEGORY_NATURE.poulet}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="state_items">
            {state.isLoading ? (
              <>
                {" "}
                <div className="title">
                  <Skeleton variant="rectangular" width={150} />
                </div>
                <div className="state">
                  {[...Array(4)].map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rounded"
                      sx={{ width: "110px", height: "55px" }}
                    />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="title">
                  <Timeline className="ico" />
                  <span>Etat des commandes</span>
                </div>
                <div className="state">
                  <div className="state_item">
                    <div className="icon ">
                      <ViewInArOutlined className="ico cmd" />
                    </div>
                    <div className="detail">
                      <div className="item_value cmd">
                        {stateData.ordersCount}
                      </div>
                      <div className="item_label">Toutes</div>
                    </div>
                  </div>
                  <div className="state_item">
                    <div className="icon">
                      <HistoryOutlined className="ico waiting" />
                    </div>
                    <div className="detail">
                      <div className="item_value waiting">
                        {stateData.waitingCount}
                      </div>
                      <div className="item_label">En cours</div>
                    </div>
                  </div>
                  <div className="state_item ">
                    <div className="icon">
                      <AccountBalance className="ico payed" />
                    </div>
                    <div className="detail">
                      <div className="item_value payed">
                        {roundedOneDigit(stateData.paidAmount)} $
                      </div>
                      <div className="item_label">Payés</div>
                    </div>
                  </div>
                  <div className="state_item">
                    <div className="icon">
                      <AccountBalanceWallet className="ico balance" />
                    </div>
                    <div className="detail">
                      <div className="item_value balance">
                        {roundedDownTwoDigit(stateData.balanceAmount)} $
                      </div>
                      <div className="item_label">Dette</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="history">
            <div className="title">
              {state.isLoading ? (
                <Skeleton variant="rectangular" width={150} />
              ) : (
                <>
                  <SegmentOutlined className="ico" />
                  <span> Dernières commandes</span>
                </>
              )}
            </div>

            <ul>
              {state.isLoading && Skeletons}
              {!state.isLoading && orderData.length > 0 && (
                <>
                  {orderData
                    .sort((a: any, b: any) => {
                      let dataA: any = new Date(a.date).getTime();
                      let dateB: any = new Date(b.date).getTime();
                      return dataA - dateB;
                    })
                    .reverse()
                    .map((item: OrderType, index: number) => {
                      return (
                        <Link
                          key={index}
                          to={`/orders/${item.nom}`}
                          state={item}
                        >
                          <li
                            key={index}
                            // onClick={() => handleOpenDetail(item)}
                          >
                            <div>
                              <p>
                                <BlurOn className="ico" />
                                {item.nature_designation} - {item.categorie}
                              </p>
                              <time>
                                {moment(item.date).format("dddd Do MMMM, YYYY")}
                              </time>
                              {item.statusCmd_id ===
                              STATUS_ORDER_ID.ETABLIE_AVEC_DETTE ? (
                                <>
                                  <span className="balance">{"Réglée"}</span>
                                  <span className="balance">
                                    Dette:
                                    {item.prixtotal !== undefined &&
                                      item.montant !== undefined &&
                                      ` ${item.prixtotal - +item.montant} ${
                                        item.devise
                                      }`}
                                  </span>
                                </>
                              ) : item.statusCmd_id ===
                                STATUS_ORDER_ID.ANNULE ? (
                                <span className="balance">
                                  {item.status_cmd_designation}
                                </span>
                              ) : item.statusCmd_id ===
                                STATUS_ORDER_ID.ETABLIE_AVEC_PAIEMENT ? (
                                <span className="status">{"ETABLIE"}</span>
                              ) : (
                                <span className="status">
                                  {item.status_cmd_designation}
                                </span>
                              )}

                              {item.statusCmd_id !== STATUS_ORDER_ID.REGLE &&
                              item.statusCmd_id !==
                                STATUS_ORDER_ID.ETABLIE_AVEC_DETTE &&
                              item.statusCmd_id !== STATUS_ORDER_ID.ANNULE ? (
                                <span className="waiting">En cours</span>
                              ) : (
                                ""
                              )}
                            </div>
                          </li>
                        </Link>
                      );
                    })}
                </>
              )}
              {!state.isLoading && orderData.length === 0 && (
                <>
                  <li>
                    <div>
                      <h4>Aucune Commande trouvée</h4>
                    </div>
                  </li>
                  <li>
                    <div className="icons">
                      <PlaylistRemoveOutlined
                        sx={{ fontSize: 80, color: "#777" }}
                      />
                    </div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      {openUpdatePsw && (
        <UpdatePswd
          client_num={location.state.telephone}
          client_id={location.state.client_id}
          setOpen={setOpenUpdatePsw}
          setOpenSuccess={setOpenSuccessUpdatePsw}
        />
      )}
      {/* {oenChat && <Chat setOpen={setOpenChat} data={""} />} */}

      {openSuccessUpdatePsw && (
        <SuccessAlert
          setOpen={setOpenSuccessUpdatePsw}
          label={"Mot de passe réinitialisé avec success"}
          theme={"success"}
        />
      )}

      {openOrder && <SingleOrder data={pushData} setOpen={setOpenOrder} />}
    </div>
  );
};

export default SingleClient;
